import Constants from "../../config/Constants";
import {
  addToSessionStorage,
  clearSessionStorage,
  sendGETRequest,
  sendPOSTRequest,
  sendPUTRequest,
  validateUserToken,
  redirectToPage,
} from "../../utils/common_functions";

export const sendCreateAdUnitFormSubmitRequest = (inputObj) => {
  console.log("sendCreateAdUnitFormSubmitRequest");
  console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_CREATE_AD_UNIT_URL;

  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  dataToPost.history = inputObj.history;
  dataToPost.redirectUrl = inputObj.url;
  dataToPost.loadingHandler = inputObj?.loadingHandler;
  let data = {
    ...dataToPost,
  };
  delete data["userToken"];
  if (inputObj.dataToPost.ad_unit_id != undefined) {
    sendPUTRequest(
      url,
      data,
      headersToPost,
      sendCreateAdUnitFormSubmitResponseSuccessHandler,
      sendCreateAdUnitFormSubmitResponseErrorHandler
    );
  } else {
    sendPOSTRequest(
      url,
      data,
      headersToPost,
      sendCreateAdUnitFormSubmitResponseSuccessHandler,
      sendCreateAdUnitFormSubmitResponseErrorHandler
    );
  }
  // inputObj.history(inputObj.url);
};

function sendCreateAdUnitFormSubmitResponseSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdUnitId")) {
  //    handlersObj.tickerTypes(response);
  // }
  console.log(
    "sendCreateAdUnitFormSubmitResponseSuccessHandler",
    response,
    request
  );
  console.log(response);
  request?.loadingHandler && request?.loadingHandler(false);

  request.history(request.redirectUrl, {
    state: { from: "Add Unit", type: "create", message: response.message },
  });
  // request.history(request.url);
  // request.dataHander(response);
}
function sendRequestAdUnitsErrorHandler() {}

export const sendCreateAdUnitFormSubmitResponseErrorHandler = (
  error,
  request
) => {
  console.log("sendCreateAdUnitFormSubmitResponseErrorHandler", error, request);
  console.log(error);
  request?.loadingHandler && request?.loadingHandler(false);

  // let inputObj = request;
  // inputObj.url = 'AdUnit/create';
  // redirectToPage(request);
  request.history("", {
    state: { from: "Add Unit", type: "error", message: error.message },
  });
};
