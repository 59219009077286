import { createSlice, createReducer, createAction } from "@reduxjs/toolkit";
import Constants from "../../config/Constants";
import {
  sendGETRequestASync,
  sendPUTRequest,
  redirectToPageIfTokenIsValid,
} from "../../utils/common_functions";

export const editAddUnitLinkClick = (inputObj) => {
  const url = "/adunits/edit/" + inputObj.id;
  inputObj.history(url);
};

export const sendRequestToFetchAdUnitDetails = async (inputObj) => {
  console.log("sendRequesttoFetchAdvertiseDetails");
  console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_EDIT_AD_UNIT_URL + "/" + inputObj.id;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let data = {
    ...inputObj,
  };
  delete data["accessToken"];
  delete data["id"];
  delete data["url"];
  delete data["advertiserInfo"];
  const result = sendGETRequestASync(
    url,
    data,
    headersToPost,
    sendRequesttoFetchAdvertiseDetailsSuccessHandler,
    sendRequesttoFetchAdvertiseDetailsErrorHandler
  );
  return result;
};
function sendRequesttoFetchAdvertiseDetailsSuccessHandler(response, request) {
  console.log("sendRequesttoFetchAdvertiseDetailsSuccessHandler");
  console.log(response);
  // request.dataHandler(response);
}
function sendRequesttoFetchAdvertiseDetailsErrorHandler(error, request) {
  request.history('', {
    state: { from: "Add Unit", type: "error", message: error.message },
  });
}

// export const sendUpdateAdvertiserFormSubmitRequest = (inputObj) => {
//   console.log('sendUpdateAdvertiserFormSubmitRequest');
//   console.log(inputObj);

//   const url = Constants.APP_SERVER_OPTION_CREATE_ADVERTISER_URL;
//   const headersToPost = {
//     "Content-type": "application/json;charset=UTF-8",
//     Authorization: "Bearer " + inputObj.accessToken,
//   };
//   let dataToPost = inputObj.dataToPost;
//   let data = {
//     ...dataToPost,
//   };
//   delete data["userToken"];
//   sendPUTRequest(
//       url,
//       data,
//       headersToPost,
//       sendUpdateAdvertiserFormSubmitResponseSuccessHandler,
//       sendUpdateAdvertiserFormSubmitResponseErrorHandler
//   );
//   inputObj.history(inputObj.url);
// };

// function sendUpdateAdvertiserFormSubmitResponseSuccessHandler(response, request) {
//   // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
//   //    handlersObj.tickerTypes(response);
//   // }
//   console.log('sendUpdateAdvertiserFormSubmitResponseSuccessHandler');
//   console.log(response);
//   request.history(request.url);
//   // request.dataHander(response);
// }
// function sendRequestAdvertisersErrorHandler() {}

// export const sendUpdateAdvertiserFormSubmitResponseErrorHandler = (error, request) => {
//   console.log('sendUpdateAdvertiserFormSubmitResponseErrorHandler');
//   console.log(error);

//   // let inputObj = request;
//   // inputObj.url = 'advertiser/create';
//   // redirectToPage(request);
// };
