import React from "react";
import { Formik, Field, Form } from "formik";
import "choices.js/public/assets/styles/choices.min.css";
import { InfoIcon, Search } from "lucide-react";
import AppStore from "../../config/AppStore";
import { sendRequestGetAdvertiserOffers } from "../../view_logics/AdvertiserOffers/AdvertiserOffer";
import Select from "react-select";

const AdvertiserOffersFilter = ({ advertisersList, onSearch }) => {
  const handleChange = async (values, setFieldValue) => {};

  const statusOptions = [
    { value: "", label: "All" },
    { value: "0", label: "Enabled" },
    { value: "1", label: "Disabled" },
  ];

  const searchTypeOptions = [
    { value: "", label: "Search type" },
    { value: "id", label: "By Offer Id" },
    { value: "name", label: "By Name" },
  ];

  return (
    <div className="position-relative">
      <div className="tab-pane active" id="tab-1" role="tabpanel">
        <Formik
          initialValues={{
            advertiserId: "",
            offerStatus: "",
            searchValue: "",
            searchType: "",
          }}
          onSubmit={(values) => onSearch(values)}
          enableReinitialize
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <div className="card bg">
                <div className="card-body">
                  <div className="row">
                    <div className="mb-3 col-md-3">
                      <label className="form-label" htmlFor="advertiserId">
                        Advertiser:
                      </label>
                      <Select
                        id="advertiserId"
                        name="advertiserId"
                        options={advertisersList?.map((advertiser) => ({
                          value: advertiser.id,
                          label: advertiser.name,
                        }))}
                        value={
                          advertisersList
                            ?.filter((adv) => adv.id === values.advertiserId)
                            .map((adv) => ({
                              value: adv.id,
                              label: adv.name,
                            }))[0] || null
                        }
                        onChange={(selectedOption) => {
                          const selectedId = selectedOption
                            ? selectedOption.value
                            : "";
                          setFieldValue("advertiserId", selectedId);
                          handleChange(
                            { ...values, advertiserId: selectedId },
                            setFieldValue
                          );
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable
                      />
                    </div>
                    <div className="mb-3 col-md-2">
                      <label className="form-label" htmlFor="offerStatusType">
                        Status:
                      </label>
                      <Select
                        id="offerStatusType"
                        name="offerStatus"
                        options={statusOptions}
                        value={statusOptions.find(
                          (option) => option.value === values.offerStatus
                        )}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption
                            ? selectedOption.value
                            : "";
                          setFieldValue("offerStatus", selectedValue);
                          handleChange(
                            { ...values, offerStatus: selectedValue },
                            setFieldValue
                          );
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="searchValue">
                        Search:
                      </label>
                      <Field
                        type="text"
                        id="searchValue"
                        name="searchValue"
                        style={{ height: 40 }}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("searchValue", e.target.value);
                          handleChange(
                            { ...values, searchValue: e.target.value },
                            setFieldValue
                          );
                        }}
                      />
                    </div>
                    <div className="mb-3 col-md-2">
                      <label className="form-label" htmlFor="searchType">
                        Type:
                      </label>
                      <Select
                        id="searchType"
                        name="searchType"
                        options={searchTypeOptions}
                        value={searchTypeOptions.find(
                          (option) => option.value === values.searchType
                        )}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption
                            ? selectedOption.value
                            : "";
                          setFieldValue("searchType", selectedValue);
                          handleChange(
                            { ...values, searchType: selectedValue },
                            setFieldValue
                          );
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable
                      />
                    </div>
                    <div
                      className="mb-3 mt-4 col-md-1 pt-1"
                      style={{ alignSelf: "center", textAlign: "end" }}
                    >
                      <button
                        type="submit"
                        className="btn bg-gray-200 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-300 flex items-center"
                      >
                        <Search size={18} className="mr-2" />
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdvertiserOffersFilter;
