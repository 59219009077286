import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import { sendRequestGetTags } from "../../view_logics/Tags/Tag";
import { editTagLinkClick } from "../../view_logics/Tags/EditTag";
import { isUserTokenValid, readUserToken } from "../../utils/common_functions";
import AppStore from "../../config/AppStore";
import { useNavigate } from "react-router-dom";

const TagsList = (store) => {
  const [data, setData] = useState([]);
  console.log("🚀 ~ file: TagsList.js:12 ~ TagsList ~ data:", data)

  store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "editTag",
    history: navigate,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        inputObj.data = [];
        inputObj.dataHander = setData; // you setting data in this state you don't need to update setData again
        await sendRequestGetTags(inputObj);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="col-12 col-xl-12">
      <div className="card">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Date</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((tag, index) => (
                <tr
                  key={tag.id}
                  className={index % 2 === 0 ? "table-success" : ""}
                >
                  <td>{tag.id}</td>
                  <td>{tag.name}</td>
                  <td>{tag.date}</td>
                  {/* <td className="table-action">
                    <a
                      onClick={() => {
                        inputObj.id = tag.id;
                        editTagLinkClick(inputObj);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit-2 align-middle"
                      >
                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                      </svg>
                    </a>
                    
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Loading data...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TagsList;
