import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import AppStore from "../../config/AppStore";

import { sendCreateAdvertiserFormSubmitRequest } from "../../view_logics/CreateAdvertiser";
import LoadingButton from "../../components/common/LoadingButton";

const CreateAdvertiser = () => {
  let store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const formik = useFormik({
    initialValues: {
      // createdDate: new Date().toISOString().slice(0, 10),
    },
    onSubmit: async (values, { setSubmitting }, event) => {
      // console.log('here');
      // event.preventDefault();
    },
  });
  const [advertiserFormLoading, setAdvertiserFormLoading] = useState(false);

  const [advertiserAlreadyExistsFlag, setAdvertiserAlreadyExistsFlag] =
    useState(false);
  useEffect(() => {
    // Scroll to top when `myState` changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [advertiserAlreadyExistsFlag]);

  const saveAdvertiser = (event) => {
    event.preventDefault();
    console.log("saveAdvertiser");
    // console.log(event.target.name.value);
    const formData = new FormData(event.target);
    const serializedData = {};
    serializedData["httpPingAuthFlag"] = "false";
    serializedData["httpLeadAuthFlag"] = "false";
    for (let [name, value] of formData.entries()) {
      if (name.includes("httpPingAuthFlag")) {
        serializedData["httpPingAuthFlag"] = "true";
      }
      if (name.includes("httpLeadAuthFlag")) {
        serializedData["httpLeadAuthFlag"] = "true";
      } else {
        serializedData[name] = value;
      }
    }
    const inputObj = {
      accessToken: store.getState().login.accessToken,
      url: "/advertiser",
      history: navigate,
      dataToPost: serializedData,
      alreadyExistsHandler: setAdvertiserAlreadyExistsFlag,
      loadingHandler: setAdvertiserFormLoading,
    };
    setAdvertiserFormLoading(true);
    sendCreateAdvertiserFormSubmitRequest(inputObj);
  };

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  const { errors } = formik;

  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Create New Advertiser</h1>
          {advertiserAlreadyExistsFlag ? (
            <div className="alert alert-danger alert-dismissible" role="alert">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
              <div className="alert-message">
                <strong>Advertiser already exists</strong> with the same email
                address!
              </div>
            </div>
          ) : (
            ""
          )}
          <Formik
            initialValues={formik.initialValues}
            validationSchema={Yup.object({
              name: Yup.string().required("Name is required"),
              // SidStr:Yup.string()
              // .required('SidStr is required'),
              DelayInMins: Yup.number()
                // .required('Delay in minutes is required')
                .min("1")
                .max("60"),
              // billingCompany: Yup.string().required('Company name is required'),
              // billingAddress: Yup.string().required('Address is required'),
              // billingCity: Yup.string().required('City is required'),
              // billingState: Yup.string().required('State is required'),
              billingZipCode: Yup.string()
                .length(5)
                .matches(/^[0-9]{5}/),
              // .required('Zip code is required'),
              // cpcOfferWindowHeight: Yup.number()
              // .required('Delay in minutes is required'),
              // cpcOfferWindowWidth: Yup.number()
              // .required('Delay in minutes is required'),
              // pingHttpBasicAuthUsername: Yup.string().required('Username is required'),
              // pingHttpBasicAuthPassword: Yup.string().required('Password is required'),
              // pingEvaluationCode: Yup.string().required('Evaluation code is required'),
              // pingPostingUrl: Yup.string().required(),
              // pingResponsePropertyToMatch: Yup.string().required(),
              // pingResponseSuccessStringToMatch: Yup.string().required(),
              // pingResponseFailureStringToMatch: Yup.string().required(),
              // leadHttpBasicAuthUsername: Yup.string().required(),
              // leadHttpBasicAuthPassword: Yup.string().required(),
              // leadEvaluationCode: Yup.string().required(),
              leadPostingUrl: Yup.string().required(),
              // leadResponsePropertyToMatch: Yup.string().required(),
              leadResponseSuccessStringToMatch: Yup.string().required(),
              leadResponseFailureStringToMatch: Yup.string().required(),
            })}
          >
            {({ isValid, dirty }) => (
              <form onSubmit={saveAdvertiser}>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Basic Info</h5>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="Name">
                          Name
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="Email">
                          Email
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          validate={validateEmail}
                          className={
                            errors.name ? "form-control" : "form-control"
                          }
                          required
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="SidStr">
                          SID
                        </label>
                        <Field
                          type="text"
                          id="sidStr"
                          name="sidStr"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="sidStr"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="globalPixelSid">
                          Global Pixel SID
                        </label>
                        <Field
                          type="text"
                          id="globalPixelSid"
                          name="globalPixelSid"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="globalPixelSid"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="delayInMins">
                          Delay In Minutes
                        </label>
                        <Field
                          type="number"
                          id="delayInMins"
                          name="delayInMins"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="delayInMins"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      {/* <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="createdDate">Created Date</label>
                                                <Field
                                                    type="date"
                                                    id="createdDate"
                                                    name="createdDate"
                                                    className="form-control"
                                                />
                                                <ErrorMessage name="createdDate"  component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                            </div> */}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Billing Info</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="billingEmail">
                          Billing Email
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="email"
                          id="billingEmail"
                          name="billingEmail"
                          validate={validateEmail}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingEmail"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="billingCompany">
                          Billing Company
                        </label>
                        <Field
                          type="text"
                          id="billingCompany"
                          name="billingCompany"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingCompany"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mb-3">
                        <label className="form-label" htmlFor="billingAddress">
                          Billing Address:
                        </label>
                        <Field
                          type="text"
                          id="billingAddress"
                          name="billingAddress"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingAddress"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="billingCity">
                          City
                        </label>
                        <Field
                          type="text"
                          id="billingCity"
                          name="billingCity"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingCity"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="billingState">
                          State
                        </label>
                        <Field
                          type="text"
                          id="billingState"
                          name="billingState"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingState"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-2">
                        <label className="form-label" htmlFor="billingZipCode">
                          Zip
                        </label>
                        <Field
                          type="text"
                          id="billingZipCode"
                          name="billingZipCode"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="billingZipCode"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label
                        className="form-check form-check-inline"
                        htmlFor="individualInvoiceForOfferFlag"
                      >
                        <Field
                          type="checkbox"
                          id="individualInvoiceForOfferFlag"
                          name="individualInvoiceForOfferFlag"
                          className="form-check-input"
                        />
                        <span className="form-check-label ml-10">
                          Indivisual Invoice for Offer
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">CPC Settings</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="cpcOfferWindowHeight"
                        >
                          CPC Offer Window Height
                        </label>
                        <Field
                          type="number"
                          id="cpcOfferWindowHeight"
                          name="cpcOfferWindowHeight"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="cpcOfferWindowHeight"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="cpcOfferWindowWidth"
                        >
                          CPC Offer Window Width
                        </label>
                        <Field
                          type="number"
                          id="cpcOfferWindowWidth"
                          name="cpcOfferWindowWidth"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="cpcOfferWindowWidth"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Ping Settings</h5>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label className="form-check form-check-inline">
                        <Field
                          type="checkbox"
                          id="httpPingAuthFlag"
                          name="httpPingAuthFlag"
                          className="form-check-input"
                        />
                        <span className="form-check-label ml-10">
                          HTTP Auth
                        </span>
                      </label>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingHttpBasicAuthUsername"
                        >
                          Username
                        </label>
                        <Field
                          type="text"
                          id="pingHttpBasicAuthUsername"
                          name="pingHttpBasicAuthUsername"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="pingHttpBasicAuthUsername"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingHttpBasicAuthPassword"
                        >
                          Password
                        </label>
                        <Field
                          type="text"
                          id="pingHttpBasicAuthPassword"
                          name="pingHttpBasicAuthPassword"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="pingHttpBasicAuthPassword"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingPostingMethod"
                        >
                          Posting Method
                        </label>
                        <Field
                          as="select"
                          name="pingPostingMethod"
                          id="pingPostingMethod"
                          className="form-control"
                        >
                          <option value="http_get">HTTP GET</option>
                          <option value="http_post">HTTP POST</option>
                          <option value="http_json_post">HTTP JSON POST</option>
                        </Field>
                        <ErrorMessage
                          name="pingPostingMethod"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingEvaluationCode"
                        >
                          Ping Evaluation Code
                        </label>
                        <Field
                          type="text"
                          id="pingEvaluationCode"
                          name="pingEvaluationCode"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="pingEvaluationCode"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label" htmlFor="pingPostingUrl">
                        Posting URL
                      </label>
                      <Field
                        type="number"
                        id="pingPostingUrl"
                        name="pingPostingUrl"
                        className="form-control  mb-3"
                      />
                      <ErrorMessage
                        name="pingPostingUrl"
                        component="div"
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      />
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingResponseType"
                        >
                          Response Type
                        </label>
                        <Field
                          as="select"
                          name="pingResponseType"
                          id="pingResponseType"
                          className="form-control"
                        >
                          <option value="text">Text</option>
                          <option value="json">Json</option>
                          <option value="html">HTML</option>
                        </Field>
                        <ErrorMessage
                          name="pingResponseType"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingResponsePropertyToMatch"
                        >
                          Ping Response Property
                        </label>
                        <Field
                          type="text"
                          id="pingResponsePropertyToMatch"
                          name="pingResponsePropertyToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="pingResponsePropertyToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingResponseSuccessStringToMatch"
                        >
                          Response Success String
                        </label>
                        <Field
                          type="text"
                          id="pingResponseSuccessStringToMatch"
                          name="pingResponseSuccessStringToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="pingResponseSuccessStringToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="pingResponseFailureStringToMatch"
                        >
                          Response Failure String
                        </label>
                        <Field
                          type="text"
                          id="pingResponseFailureStringToMatch"
                          name="pingResponseFailureStringToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="pingResponseFailureStringToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">lead Settings</h5>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label className="form-label">
                        <Field
                          type="checkbox"
                          id="httpLeadAuthFlag"
                          name="httpLeadAuthFlag"
                          className="form-check-input"
                        />
                        <span className="form-check-label ml-10">
                          HTTP Auth
                        </span>
                      </label>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadHttpBasicAuthUsername"
                        >
                          Username
                        </label>
                        <Field
                          type="text"
                          id="leadHttpBasicAuthUsername"
                          name="leadHttpBasicAuthUsername"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadHttpBasicAuthUsername"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadHttpBasicAuthPassword"
                        >
                          Password
                        </label>
                        <Field
                          type="text"
                          id="leadHttpBasicAuthPassword"
                          name="leadHttpBasicAuthPassword"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadHttpBasicAuthPassword"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadPostingMethod"
                        >
                          lead Posting Method
                        </label>
                        <Field
                          as="select"
                          name="leadPostingMethod"
                          id="leadPostingMethod"
                          className="form-control"
                        >
                          <option value="http_get">HTTP GET</option>
                          <option value="http_post">HTTP POST</option>
                          <option value="http_json_post">HTTP JSON POST</option>
                        </Field>
                        <ErrorMessage
                          name="leadPostingMethod"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadEvaluationCode"
                        >
                          lead Evaluation Code
                        </label>
                        <Field
                          type="text"
                          id="leadEvaluationCode"
                          name="leadEvaluationCode"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadEvaluationCode"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label" htmlFor="leadPostingUrl">
                        lead Posting URL
                        <span className="text-danger">
                          &nbsp;<strong>*</strong>
                        </span>
                      </label>
                      <Field
                        type="text"
                        id="leadPostingUrl"
                        name="leadPostingUrl"
                        className="form-control mb-3"
                      />
                      <ErrorMessage
                        name="leadPostingUrl"
                        component="div"
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      />
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadResponseType"
                        >
                          lead Response Type
                        </label>
                        <Field
                          as="select"
                          name="leadResponseType"
                          id="leadResponseType"
                          className="form-control"
                        >
                          <option value="text">Text</option>
                          <option value="json">Json</option>
                          <option value="html">HTML</option>
                        </Field>
                        <ErrorMessage
                          name="leadResponseType"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadResponsePropertyToMatch"
                        >
                          lead Response Property
                        </label>
                        <Field
                          type="text"
                          id="leadResponsePropertyToMatch"
                          name="leadResponsePropertyToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadResponsePropertyToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadResponseSuccessStringToMatch"
                        >
                          lead Response Success String
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="text"
                          id="leadResponseSuccessStringToMatch"
                          name="leadResponseSuccessStringToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadResponseSuccessStringToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="leadResponseFailureStringToMatch"
                        >
                          lead Response Failure String
                          <span className="text-danger">
                            &nbsp;<strong>*</strong>
                          </span>
                        </label>
                        <Field
                          type="text"
                          id="leadResponseFailureStringToMatch"
                          name="leadResponseFailureStringToMatch"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="leadResponseFailureStringToMatch"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="mb-3 col-md-10 text-danger">
                    Please fill all the fields with <strong>*</strong>
                  </div>
                  <div
                    className="mb-3 col-md-2"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {advertiserFormLoading ? (
                      <LoadingButton name="Save" />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid || !dirty}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </main>
    </div>
  );
};

export default CreateAdvertiser;
