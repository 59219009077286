import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinyMCEComponent = ({
  initialValue,
  value,
  name,
  id,
  onEditorChange,
}) => {
  const editorRef = useRef(null);

  return (
    <Editor
      apiKey="9urvtc9c6gvafjzd6knwtas8ujntek9qgad927s8gx9pbguw"
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={initialValue}
      value={value}
      textareaName={name}
      id={id}
      name={name}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | help",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
      onEditorChange={onEditorChange}
    />
  );
};

export default TinyMCEComponent;
