import Constants from "../../config/Constants";
import { sendGETRequestASync, sendPUTRequest } from "../../utils/common_functions";



export const sendRequestToFetchUserContactDetails = async (inputObj) => {
    const url = Constants.APP_SERVER_OPTION_PUBLISHERS_LIST_URL + inputObj.id;
    const headersToPost = {
      "Content-type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + inputObj.accessToken,
    };
    let data = {
      ...inputObj,
    };
    delete data["accessToken"];
    const result = await sendGETRequestASync(url, data, headersToPost);
    return result;
  };



export const sendRequestToUpdateUserContactDetail = (inputObj) => {
    console.log("Sending Create ADvertiser Request!");
    console.log(inputObj);
  
    const url = Constants.APP_SERVER_OPTION_PUBLISHERS_LIST_URL;
    const headersToPost = {
      "Content-type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + inputObj.accessToken,
    };
    let dataToPost = inputObj.dataToPost;
    // dataToPost.redirectUrl = inputObj.url;
    dataToPost.history = inputObj.history;
    dataToPost.loadingHandler = inputObj?.loadingHandler;
    dataToPost.setResponseHandler = inputObj?.setResponseHandler;
    let data = {
      ...dataToPost,
    };
    sendPUTRequest(
      url,
      data,
      headersToPost,
      successUserContactRequestHandler,
      errorUserContactRequestHandler
    );
  };

  function successUserContactRequestHandler(response, request) {
    console.log(
      "🚀 ~ file: Website.js:133 ~ successHandler ~ response:",
      response,
      request
    );
    request?.loadingHandler && request?.loadingHandler(false);
    request?.setResponseHandler && request?.setResponseHandler(request);
    
    request.history("", {
      state: { from: "Contact Information", type: "edit", message: response.message },
    });
  }
  
  function errorUserContactRequestHandler(error, request) {
    console.log(
      "🚀 ~ file: Website.js:133 ~ successHandler ~ response:",
      error,
      request
    );
    request?.loadingHandler && request?.loadingHandler(false);
  
    request.history("", {
      state: { from: "Contact Information", type: "error", message: error.message },
    });
  }