import { React, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";

import AppStore from '../../config/AppStore';
import { sendRequesttoFetchAdvertiseOfferDetails } from '../../view_logics/AdvertiserOffers/EditAdvertiserOffer';
import { sendRequestGetAdvertisersList, sendRequestGetTagsList } from '../../view_logics/AdvertiserOffers/AdvertiserOffer';

import BasicInfoTab from "./BasicInfoTab";
import LeadSettingsTab from "./LeadSettingsTab";
import TargetingTab from "./TargetingTab";
import ImagesInfoTab from './ImagesInfoTab';
import BlockedAllowedTab from './BlockedAllowedTab';


const EditAdvertiserOffer = () => {

    const { id } = useParams();
    console.log("🚀 ~ file: EditAdvertiserOffer.js:16 ~ EditAdvertiserOffer ~ id:", id)
    const [advertiserOfferInfo, setAdvertiserOfferInfo] = useState({});
    const [advertisersList, setAdvertisersList] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const location = useLocation();

    const [errorOnSave, setErrorOnSave] = useState(false); 
    let store = AppStore.getAppStoreInstance();
    const navigate = useNavigate(); // we can pass navigation to clickhandler
    const inputObj = {
        accessToken: store.getState().login.accessToken,
        url: '',
        history: navigate,
        dataHandler: setAdvertiserOfferInfo,
        offerId: id
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resultT = await sendRequestGetTagsList(inputObj);
                // console.log('advertisersList has been poulated');
                const tagsList1 = [];
                for (let key in resultT) {
                    tagsList1.push({ id: resultT[key].id, name: resultT[key].name });
                }
                setTagsList(tagsList1);

                const results = await sendRequestGetAdvertisersList(inputObj);
                // console.log('advertisersList has been poulated');
                const advertisersList1 = [];
                for (let key in results) {
                    advertisersList1.push({ id: results[key].id, name: results[key].name });
                }
                setAdvertisersList(advertisersList1);

                let result = await sendRequesttoFetchAdvertiseOfferDetails(inputObj);
                
                setAdvertiserOfferInfo(result);
                
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const [activeTab, setActiveTab] = useState(1);
    
    // Map the tab ID to the corresponding component
    const tabComponents = {
        1: BasicInfoTab,
        2: ImagesInfoTab,
        3: LeadSettingsTab,
        4: TargetingTab,
        5: BlockedAllowedTab,
    };
    
    useEffect(()=>{
        if(location?.state?.type === 'create'){
            setActiveTab(2)
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(location.pathname, { replace: true });
        }
    },[location])

    const TabContentComponent = tabComponents[activeTab];

    return (
        <div className="">
      <main className="content">
        <div>
            <h1>Edit Offer</h1>
            { !advertiserOfferInfo ? "Loading..." :
            <div className="col-12 col-lg-12">
                <div className="tab">
                    <ul className="nav nav-tabs" role="tablist">
                        <li 
                            role="presentation"
                            className='nav-item'
                            
                            onClick={() => setActiveTab(1)}
                        >
                            <a className={activeTab === 1 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Basic Information</a>
                            
                        </li>
                        <li
                            role="presentation"
                            className='nav-item'
                            onClick={() => setActiveTab(2)}
                        >
                            
                            <a className={activeTab === 2 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Images Information</a>
                        </li>
                        <li
                            role="presentation"
                            className='nav-item'
                            onClick={() => setActiveTab(3)}
                        >
                            
                            <a className={activeTab === 3 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Lead Settings</a>
                        </li>
                        <li
                            role="presentation"
                            className='nav-item'
                            onClick={() => setActiveTab(4)}
                        >
                            <a className={activeTab === 4 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Targeting</a>
                        </li>
                        <li
                            role="presentation"
                            className='nav-item'
                            onClick={() => setActiveTab(5)}
                        >
                            <a className={activeTab === 5 ? 'active nav-link' : 'nav-link'}  data-bs-toggle="tab" role="tab" aria-selected="true">Blocked/Allowed List</a>
                        </li>
                    </ul>

                    {/* Render the selected tab component */}
                    {
                        advertiserOfferInfo ? 
                    
                    <div className="tab-content">
                        <TabContentComponent advertiserOfferInfo={advertiserOfferInfo} setAdvertiserOfferInfo={setAdvertiserOfferInfo} setActiveTab={setActiveTab} advertisersList={advertisersList} tagsList={tagsList}/>
                    </div>
                    : 'Loading'
                    }
                </div>
            </div>
             }
        </div>
        </main>
        </div>

    );
};

export default EditAdvertiserOffer;
