import React from 'react';
// import App from './App';
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';

import App from './app/views/App';
import { Provider } from 'react-redux';
import AppStore, { persistor } from './app/config/AppStore';
import { PersistGate } from 'redux-persist/integration/react';


createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        <Provider store={AppStore.store}>
            <PersistGate loading={null} persistor={persistor}>
            <App />
            </PersistGate>
        </Provider>
    </BrowserRouter>
);