import { loginAction, setServerErrorAction, logoutAction } from '../view_logics/LoginSlice';

import Constants from "../config/Constants";
import AppStore from "../config/AppStore";

import {

  sendPOSTRequest,
  redirectToPage,
  redirectToDashboardPageIfTokenIsValid1
} from "../utils/common_functions";
import Alert from '../components/common/Alert';




const sendLoginRequest = (dataToPost) => {
  console.log("🚀 ~ sendLoginRequest ~ dataToPost:", dataToPost)
  console.log('Sending Login Request!');
  console.log(dataToPost);

  const url = Constants.APP_SERVER_AUTHENTICATE_USER_URL;
  const headersToPost = { "Content-type": "application/json;charset=UTF-8" };
  sendPOSTRequest(
    url,
    dataToPost,
    headersToPost,
    loginResponseSuccessHandler,
    loginResponseErrorHandler
  );
};
function loginResponseSuccessHandler(response, requestData) {
  console.log("🚀 ~ loginResponseSuccessHandler ~ response:", response, requestData)
  if(response.status?.includes('error')){
    requestData?.errorHandler(response)
    requestData?.loadingHandler && requestData?.loadingHandler(false)
  }
  
  if (response.access) {
    const store = AppStore.getAppStoreInstance();
    const inputObj = { refreshToken: response.refresh, accessToken: response.access, isStaff: response.isStaff, name: response.name, userType: response?.user_type, userId: response.user_id, mainUser: response?.user_type, };

    store.dispatch(loginAction(inputObj));
    requestData.setIsUserAuthenticated(true)
    requestData?.loadingHandler && requestData?.loadingHandler(false)
    
  }
}
function loginResponseErrorHandler(error, requestData) {
console.log("🚀 ~ loginResponseErrorHandler ~ error:", error)

  console.log('loginResponseErrorHandler');

  if ( error.hasOwnProperty("status")  ){
    const store = AppStore.getAppStoreInstance();
    const inputObj = { error: error.message };
    store.dispatch(setServerErrorAction(inputObj));
    // store.login.error = error.message;
    requestData.history('/login');
    requestData?.loadingHandler && requestData?.loadingHandler(false)
  }

  if (error.hasOwnProperty("non_field_errors")  ) {
    if (error.non_field_errors.length) {
      const store = AppStore.getAppStoreInstance();
      const inputObj = { error: error.non_field_errors[0] };
      store.dispatch(setServerErrorAction(inputObj));
      requestData?.loadingHandler && requestData?.loadingHandler(false)
    }
  }
  
}
export const readServerError = (state) => {
  return state.error;
};
export const loginBtnClickHandler = (inputObj) => {
  sendLoginRequest(inputObj);
};

// // Extract the action creators object and the reducer
// const { actions, reducer } = loginSlice;
// // Extract and export each action creator by name
// export const { loginAction, logoutAction, setServerErrorAction } = actions;
// // Export the reducer, either as a default or named export
// export default reducer;
