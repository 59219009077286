import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, shallowEqual } from "react-redux";

import { isUserTokenValid, readUserToken } from '../../utils/common_functions';
import AppStore from '../../config/AppStore';

import { sendRequestGetAdvertisers } from '../../view_logics/Advertiser';
import { editAdvertiserLinkClick } from '../../view_logics/EditAdvertiser';

const AdvertisersList = (store) => {

  const [data, setData] = useState([]);

  store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: 'editAdvertiser',
    history: navigate,
    data: [],
    dataHander: setData
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await sendRequestGetAdvertisers(inputObj);
        console.log('returned data from sendRequestGetAdvertisers is:');
        console.log(inputObj);
        // setData(jsonData);  //this is bad request is recursion
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="col-12 col-xl-12">
      <div className="card">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((advertiser, index) => (
                <tr key={advertiser.id} className={index % 2 === 0 ? 'table-success' : ''}>
                  <td>{advertiser.id}</td>
                  <td>{advertiser.name}</td>
                  <td>{advertiser.email}</td>
                  {/* <td className="d-none d-md-table-cell">{advertiser.BillingInf?.CompanyName}</td> */}
                  <td className="table-action">
                    <a href="#"
                      onClick={() => {
                        inputObj.id = advertiser.id;
                        editAdvertiserLinkClick(inputObj)
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2 align-middle">
                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                      </svg>
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Loading data...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AdvertisersList;