import React from "react";
import {MonthlyRevenueReportsList} from "../reportsView";

const MonthlyRevenueReports = () => {
  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Monthly Revenue Reports</h1>
          <p style={{color: 'red'}}>Report for prior month gets finalized by the 15th of every consecutive month.</p>
          <hr />
          <MonthlyRevenueReportsList />
        </div>
      </main>
    </div>
  );
};

export default MonthlyRevenueReports;