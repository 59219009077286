import { createSlice, createReducer, createAction } from "@reduxjs/toolkit";
import Constants from "../config/Constants";
import { sendGETRequest, sendPUTRequest, redirectToPageIfTokenIsValid } from "../utils/common_functions";

export const editAdvertiserLinkClick = (inputObj) => {
  const url = '/advertiser/edit/' + inputObj.id;
  inputObj.history(url);
}


export const sendRequesttoFetchAdvertiseDetails = async (inputObj) => {
  console.log('sendRequesttoFetchAdvertiseDetails');
  console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_EDIT_ADVERTISER_URL + '/' + inputObj.AdvertiserId;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let data = {
    ...inputObj,
  };
  delete data["accessToken"];
  delete data["AdvertiserId"];
  delete data["url"];
  delete data["advertiserInfo"];
  data.stateChangeHandler = inputObj.dataHandler;
  sendGETRequest(
    url,
    data,
    headersToPost,
    sendRequesttoFetchAdvertiseDetailsSuccessHandler,
    sendRequesttoFetchAdvertiseDetailsErrorHandler
  );
  return;
};
function sendRequesttoFetchAdvertiseDetailsSuccessHandler(response, request) {
  console.log('sendRequesttoFetchAdvertiseDetailsSuccessHandler');
  
  let transformedObject = {};
  for (let key in response) {
    if (response.hasOwnProperty(key)) {
      const transformedKey = key.replace(/_/g, ' ');
      const camelKey = transformedKey.replace(/(?:^\w|[A-Z]|\b\w)/g, (match, index) => {
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      }).replace(/\s+/g, '');
      const value = response[key];
      if ( key === "name" ){
        transformedObject['advertiserName'] = value;
      }
      if ( key === "ping_http_basic_auth_flag" ){
        transformedObject['httpPingAuthFlag'] = false;
        if ( value != 0){
          transformedObject['httpPingAuthFlag'] = true;
        }
      }
      if ( key === "individual_invoice_for_offer_flag" ){
        transformedObject['individualInvoiceForOfferFlag'] = false;
        if ( value != 0){
          transformedObject['individualInvoiceForOfferFlag'] = true;
        }
      }
      if ( key === "lead_http_basic_auth_flag" ){
        transformedObject['leadHttpBasicAuthFlag'] = false;
        if ( value != 0){
          transformedObject['leadHttpBasicAuthFlag'] = true;
        }
      }
      else{
        transformedObject[camelKey] = value;
      }
    }
  }
  delete transformedObject.id;
  delete transformedObject.leadHttpBasicAuthFlag;
  delete transformedObject.updateAt;
  delete transformedObject.webHookUrl;
  delete transformedObject.unixTimestamp;
  delete transformedObject.name;
  delete transformedObject.createdDate;
  delete transformedObject.ftpHost;
  delete transformedObject.ftpUsername;
  delete transformedObject.ftpPassword;
  delete transformedObject.ftpServerPath;
  console.log(transformedObject)
  request.stateChangeHandler(transformedObject);
}
function sendRequesttoFetchAdvertiseDetailsErrorHandler() { }



export const sendUpdateAdvertiserFormSubmitRequest = (inputObj) => {
  console.log('sendUpdateAdvertiserFormSubmitRequest');
  console.log(inputObj.dataToPost);

  const url = Constants.APP_SERVER_OPTION_CREATE_ADVERTISER_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  let data = {
    ...dataToPost,
  };
  delete data["userToken"];
  data.redirectUrl = inputObj.url;
  data.history = inputObj.history;
  data.errorStateChangeHandler = inputObj.errorStateChangeHandler;
  data.loadingHandler = inputObj?.loadingHandler
  sendPUTRequest(
      url,
      data,
      headersToPost,
      sendUpdateAdvertiserFormSubmitResponseSuccessHandler,
      sendUpdateAdvertiserFormSubmitResponseErrorHandler
  );
  // inputObj.history(inputObj.url);
};


function sendUpdateAdvertiserFormSubmitResponseSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
  //    handlersObj.tickerTypes(response);
  // }
  console.log('sendUpdateAdvertiserFormSubmitResponseSuccessHandler');
  console.log(response);
  request?.loadingHandler && request?.loadingHandler(false)
  request.history(request.redirectUrl, { state: { from: "Advertiser", type: 'edit', message: response.message } });
  // request.dataHander(response);
}
function sendRequestAdvertisersErrorHandler() {}


export const sendUpdateAdvertiserFormSubmitResponseErrorHandler = (error, request) => {
  console.log('sendUpdateAdvertiserFormSubmitResponseErrorHandler');
  console.log(error);
  request.errorStateChangeHandler(true);
  request.history('',{ state: { from: "Advertiser", type: 'error', message: error.message }});
  // let inputObj = request;
  // inputObj.url = 'advertiser/create';
  // redirectToPage(request);
};
