import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from '../view_logics/LoginSlice'

// Combine all reducers.
const appReducer = combineReducers({
    login: loginReducer
})

export const rootReducer = (state, action) => {   
    // Clear all data in redux store to initial.
    if(action.type === 'DESTROY_SESSION')
       state = undefined;
    
    return appReducer(state, action);
 };