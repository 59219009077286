import React from "react";
import styles from './styles.module.css'; // Import CSS Module


const MainContentTemplate = () => {
  return (
    <div className={styles.wrapper}>
      {/* <!--Header Start--> */}
      <header className={styles.select_format_template_hdr}>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <a className="navbar-brand" href="#">
              Logo
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Features
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Pricing
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {/* <!--Header End-->
	<!--Content Start--> */}
      <div
        className={`${styles.body_content} row`}
        style={{marginRight: '0px', marginLeft: '0px',paddingBottom: '0px'}}
      >
        <section order="1">
          <div className={`${styles.select_format_template_bx01} col-12`}>
            <div className="container">
              <div className="row">
                <div className={`${styles.select_format_template_bx01_lt} col-sm-8`}>
                  <div className="row">
                    <div className="col-sm-12">
                      <p className={styles.alt01}>
                        What is Lorem Ipsum?
                        <br />
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8 mx-auto">
                      <section order="1">
                        <div className={`${styles.off01_bx01} col-md-12`}>
                          <div>
                            <div
                              className="row fade-in active"
                              id="mainContainer"
                              style={{display: 'block', transition: 'opacity 0.5s ease-in-out 0s'}}
                            >
                              <div className={`${styles.off06_bx01_bx01} col-sm-12 p-4`}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row" id="adsContainer1">
                                      <div className="col-sm-12 gx-5">
                                        <div className="row">
                                          <div className={`${styles.off06_bx01_bx01_bx01} col-12 p-2`}>
                                            <div className="row">
                                              <div className={`${styles.off06_bx01_bx01_bx01_bx01} col-12`}>
                                              Medicare Offer
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className={`${styles.off06_bx01_bx01_bx01_bx02} col-12`}>
                                                <img
                                                  src="https://leadgen-prod.s3.amazonaws.com/xoptin_ads/offer_iamges/off05_img01.png"
                                                  alt=""
                                                  title=""
                                                />
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className={`${styles.off06_bx01_bx01_bx01_bx03} col-12`}>
                                                <div className="row">
                                                  <div className={`${styles.off06_bx01_bx01_bx01_bx03_lt} col-10`}>
                                                    Do you or someone you love
                                                    need a back brace and have
                                                    Medicare? Then get the back
                                                    brace you need for FREE!
                                                  </div>
                                                  <div className={`${styles.off06_bx01_bx01_bx01_bx03_rt} col-2 text-center`}>
                                                    <a
                                                      offer_id="5386"
                                                      offer_url="YFppY4t6fH"
                                                      onclick="handleAddClick(this)"
                                                      href="javascript:{}"
                                                    >
                                                      <img src="https://leadgen-prod.s3.amazonaws.com/xoptin_ads/icn_arw.png" />
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <p className={styles.alt01} style={{marginTop: '15px'}}>
                        What is Lorem Ipsum?
                        <br />
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                      <p className={styles.alt01}>
                        What is Lorem Ipsum?
                        <br />
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`${styles.select_format_template_bx01_rt} col-sm-4`}>
                  <p className={styles.alt01}>Quick Links</p>
                  <ul>
                    <li>Link 1</li>
                    <li>Link 2</li>
                    <li>Link 3</li>
                    <li>Link 4</li>
                    <li>Link 5</li>
                    <li>Link 6</li>
                    <li>Link 7</li>
                    <li>Link 8</li>
                    <li>Link 9</li>
                    <li>Link 10</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!--Content End-->
	<!--Footer Start--> */}
      <footer className={styles.select_format_template_ftr}>
        <div className="col-12">
          <div className="container">
            <div className="row">
              <div className={`${styles.lp01_ftr_bx02} col-12 text-center`}>
                <p className={styles.alt01}>
                  <a href="#">Privacy Policy</a>&nbsp;|&nbsp;
                  <a href="#.html">Terms and Conditions</a>&nbsp;|&nbsp;
                  <a href="#.html">Unsubscribe</a>&nbsp;|&nbsp;
                  <a href="#.html">Contact Us</a>
                </p>
                <p className={styles.alt02}>&copy; Sample Site, All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!--Footer End-->	 */}
    </div>
  );
};

export default MainContentTemplate;
