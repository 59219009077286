import {
   BrowserRouter,
   Routes,
   Route,
   Router
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import CreateTag from './CreateTag';
import TagsList from './TagsList';

import { useNavigate } from 'react-router-dom';
import AppStore from '../../config/AppStore';
import {createPublisherBtnClick} from '../../view_logics/Publishers'


const Tags = () => {

   const [showCreatePublishersContent, setShowCreatePublishersContent] = useState(false);
   const handleCreatePublishersClick = (menuItem) => {
      setShowCreatePublishersContent(menuItem);
   };

   let store = AppStore.getAppStoreInstance();
   const navigate = useNavigate(); // we can pass navigation to clickhandler
   const inputObj = {
      accessToken: store.getState().login.accessToken,
      url: 'create',
      history: navigate
   };

   return (
      <div className="">
         <main className="content">
            <div className="container-fluid p-0">
               <h1>Tags</h1>
               {!showCreatePublishersContent ?
                  <div className="col-md-3">
                     <a
                        // onClick={() => handleCreatePublishersClick('createPublishers')}
                        onClick={() => createPublisherBtnClick(inputObj)}
                        className="btn btn-primary float-right mb-3"
                     >
                        Create New Tag
                     </a>
                  </div>
                  :
                  <div className="col-md-3">
                     <a
                        // onClick={() => handleCreatePublishersClick(false)}
                        className="btn btn-primary float-right mb-3"
                     >
                        Back
                     </a>
                  </div>
               }
               <hr />
               {showCreatePublishersContent ? <CreateTag /> : <TagsList />}
            </div>
         </main>
      </div>

   );
};

export default Tags;