import React, { useEffect, useState } from "react";
import AppStore from "../../config/AppStore";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { sendRequestGetPublisherOfferDailyStatReports } from "../../view_logics/Reports/Reports";
import { useSelector } from "react-redux";
import DatePickerField from "../../components/common/DatePickerField";
import Sub1Img from '../../../assets/images/sub1.png';
import Sub2Img from '../../../assets/images/sub2a.png';
import Sub3Img from '../../../assets/images/sub3a.png';

const PublisherOfferDailyStatReportsList = () => {
  const { userId, publisherId, username, userType } = useSelector(
    (state) => state?.login
  );
  console.log("🚀 ~ PublisherOfferDailyStatReportsList ~ userId:", userId);
  let store = AppStore.getAppStoreInstance();
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [sortOrder, setSortOrder] = useState("asc"); // or 'desc'
  const [dateFieldEmpty, setDateFieldEmpty] = useState(false);
  const [displayStats, setDisplayStats] = useState([]);
  const [displayStatIndex, setDisplayStatIndex] = useState([]);
  const [reportsFilterLoading, setReportsFilterLoading] = useState(false);

  const [isSub1IconClick, setIsSub1IconClick] = useState(false);
  const [isSub2IconClick, setIsSub2IconClick] = useState(false);
  const [isSub3IconClick, setIsSub3IconClick] = useState(false);
  const todayDate = new Date();

  const [collapseStatus, setCollapseStatus] = useState({});
  const [sub1RowCollapseStatus, setSub1RowCollapseStatus] = useState({});
  const [sub2RowCollapseStatus, setSub2RowCollapseStatus] = useState({});

  const [displaySub2Stats, setDisplaySub2Stats] = useState([]);

  const [sub1Loading, setSub1Loading] = useState(false);
  const [sub2Loading, setSub2Loading] = useState(false);
  const [sub3Loading, setSub3Loading] = useState(false);
  const [loadingRowIndex, setLoadingRowIndex] = useState({ mainIndex: null, sub1Index: null , sub2Index: null });

  const handleRowClick = (index) => {
    setCollapseStatus((prevStatus) => ({
      ...prevStatus,
      [index]: !prevStatus[index], 
    }));   
    setSub1RowCollapseStatus({}); // Reset sub1 rows
  setSub2RowCollapseStatus({}); 
  };

  const handleSub2RowClick = (parentIndex, sub1Index) => {
    setSub1RowCollapseStatus((prev) => ({
      ...prev,
      [`${parentIndex}-${sub1Index}`]: !prev[`${parentIndex}-${sub1Index}`], 
    }));
    setSub2RowCollapseStatus({});
  };
  
  const handleSub3RowClick = (parentIndex, sub1Index, sub2Index) => {
    setSub2RowCollapseStatus((prevStatus) => ({
      ...prevStatus,
      [`${parentIndex}-${sub1Index}-${sub2Index}`]: !prevStatus[`${parentIndex}-${sub1Index}-${sub2Index}`],
    }));
  };

 
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "reports",
    data: {
      from: todayDate?.toISOString().split("T")[0],
      to: todayDate?.toISOString().split("T")[0],
      publisher_id:
        publisherId != null ? publisherId : userId != null ? userId : "1",
    },
    dataHander: setApiData,
    loadingHandler: setReportsFilterLoading,
  };

  useEffect(() => {
    if (Array.isArray(apiData)) {

    // sub 1 data
    if(isSub1IconClick === true){
      
      const groupedData = [];

      apiData.forEach((item) => {
        const { sub1 } = item;
        const existingGroup = groupedData.find((group) => group.sub1 === sub1);

        if (existingGroup) {
          existingGroup.items.push(item);
        } else {
          groupedData.push({ sub1, items: [item] });
        }
      });

      setReportsList(prevList =>
        prevList.map((item, idx) =>
          idx === displayStatIndex ? { ...item, sub1Data: groupedData } : item
        )
      );
      setLoadingRowIndex({ mainIndex: null, sub1Index: null, sub2Index: null });
      setSub1Loading(false);
      
      // setDisplayStats(groupedData);
      setIsSub1IconClick(false);
        
    } else if (isSub2IconClick === true){

      // sub 2 data
      const groupedData = [];

      apiData.forEach((item) => {
        const { sub2 } = item;
        const existingGroup = groupedData.find((group) => group.sub2 === sub2);

        if (existingGroup) {
          existingGroup.items.push(item);
        } else {
          groupedData.push({ sub2, items: [item] });
        }
      });

      setReportsList((prevList) =>
        prevList.map((item, idx) =>
          idx === displayStatIndex
            ? {
                ...item,
                sub1Data: item.sub1Data.map((subItem, sIdx) =>
                  sIdx === loadingRowIndex.sub1Index ? { ...subItem, sub2Data: groupedData } : subItem
                ),
              }
            : item
        )
      );
      setLoadingRowIndex({ mainIndex: null, sub1Index: null, sub2Index: null });
      setSub2Loading(false);
      // setDisplaySub2Stats(groupedData);
      setIsSub2IconClick(false);

    } else if(isSub3IconClick === true ) {
        // sub 3 data
        const groupedData = [];

        apiData.forEach((item) => {
          const { sub3 } = item;
          const existingGroup = groupedData.find((group) => group.sub3 === sub3);

          if (existingGroup) {
            existingGroup.items.push(item);
          } else {
            groupedData.push({ sub3, items: [item] });
          }
        });
        setReportsList((prevList) =>
          prevList.map((item, idx) =>
            idx === displayStatIndex
              ? {
                  ...item,
                  sub1Data: item.sub1Data.map((subItem, sIdx) =>
                    sIdx === loadingRowIndex.sub1Index
                      ? {
                          ...subItem,
                          sub2Data: subItem.sub2Data.map((sub2Item, sub2Idx) =>
                            sub2Idx === loadingRowIndex.sub2Index ? { ...sub2Item, sub3Data: groupedData } : sub2Item
                          ),
                        }
                      : subItem
                  ),
                }
              : item
          )
        );

        setLoadingRowIndex({ mainIndex: null, sub1Index: null, sub2Index: null });
        setSub3Loading(false);
        setIsSub3IconClick(false);

    }  else {
      // setReportsList(apiData);
      const initializedData = apiData.map((item) => ({
        ...item,
        sub1Data: [],
      }));
      setReportsList(initializedData);
    }

    console.log("this is report data : >>>>>> :",reportsList);
     
    }
  }, [apiData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await sendRequestGetPublisherOfferDailyStatReports(inputObj);
        // await sendRequestGetPublishers(getPublishersInputObj);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const handleDateRangeChange = (e) => {
    const selected = e.target.value;
    setSelectedDateRange(selected);

    const today = new Date();
    setDateFieldEmpty(true);

    switch (selected) {
      case "today":
        setFromDate(today);
        setToDate(today);
        break;
      case "yesterday":
        setFromDate(addDays(today, -1));
        setToDate(addDays(today, -1));
        break;
      case "thisWeek":
        setFromDate(startOfWeek(today));
        setToDate(endOfWeek(today));
        break;
      case "lastWeek":
        setFromDate(startOfWeek(addDays(today, -7)));
        setToDate(endOfWeek(addDays(today, -7)));
        break;
      case "thisMonth":
        setFromDate(startOfMonth(today));
        setToDate(endOfMonth(today));
        break;
      case "lastMonth":
        setFromDate(startOfMonth(addDays(today, -30)));
        setToDate(endOfMonth(addDays(today, -30)));
        break;
      case "thisYear":
        setFromDate(startOfYear(today));
        setToDate(endOfYear(today));
        break;
      default:
        break;
    }
  };

  const formatDate = (date) => format(date, "yyyy-MM-dd");

  const applyFilterHandler = () => {
    
    let postObject = {
      from: formatDate(fromDate),
      to: formatDate(toDate),
      publisher_id:
        publisherId != null ? publisherId : userId != null ? userId : "",
      loadingHandler: setReportsFilterLoading,
    };
    inputObj.data = postObject;

    const fetchData = async () => {
      try {
        setReportsFilterLoading(true);
        await sendRequestGetPublisherOfferDailyStatReports(inputObj);
        // setDisplayStatIndex();
        // setDisplayStats([]);
      } catch (error) {
        setReportsFilterLoading(false);
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
    setCollapseStatus({}); 
    // setDisplayStats([]);
    // setDisplayStatIndex(null)
  };

  const sub1DataFetch = (date) => {

    let postObject = {
      from: date, 
      to: date,
      publisher_id:
        publisherId != null ? publisherId : userId != null ? userId : "",
      sub1 : "",
      loadingHandler: setSub1Loading,
    };
    inputObj.data = postObject;

    const fetchData = async () => {
      try {
        setSub1Loading(true);
        await sendRequestGetPublisherOfferDailyStatReports(inputObj);
      } catch (error) {
        setSub1Loading(false);
        console.log("Error fetching data:", error);
      }
    };

    fetchData();

    // setDisplayStats([]);
    // setDisplayStatIndex(null)
  };

  const sub2DataFetch = (date,sub1) => {

    if(sub1 !== ""){
      let postObject = {
        from: date, 
        to: date,
        publisher_id:
          publisherId != null ? publisherId : userId != null ? userId : "",
        sub1 : sub1,
        sub2 : "",
        loadingHandler: setSub2Loading,
      };
      inputObj.data = postObject;
  
      const fetchData = async () => {
        try {
          setSub2Loading(true);
          await sendRequestGetPublisherOfferDailyStatReports(inputObj);
        } catch (error) {
          setSub2Loading(false);
          console.log("Error fetching data:", error);
        }
      };
  
      fetchData();
    } else {
      console.log("sub1 have no value");
    }
  };
  const sub3DataFetch = (date,sub1,sub2) => {

    if(sub2 !== ""){
      let postObject = {
        from: date, 
        to: date,
        publisher_id:
          publisherId != null ? publisherId : userId != null ? userId : "",
        sub1 : sub1,
        sub2 : sub2,
        sub3 : "",
        loadingHandler: setSub3Loading,
      };
      inputObj.data = postObject;
  
      const fetchData = async () => {
        try {
          setSub3Loading(true);
          await sendRequestGetPublisherOfferDailyStatReports(inputObj);
        } catch (error) {
          setSub3Loading(false);
          console.log("Error fetching data:", error);
        }
      };
  
      fetchData();
    } else {
      console.log("sub3 have no value");
    }
   
   
  };

  useEffect(() => {
    const newDate = new Date();
    setDateFieldEmpty(true);
    if (newDate) {
      setFromDate(newDate);
      setToDate(newDate);
    }
  }, []);

  const sumOfFieldForRow = (row, fieldName) => {
    const sum = (row.items || []).reduce(
      (total, item) => total + Number(item[fieldName] || 0),
      0
    );
    return sum.toFixed(2);
  };
 
  return (
    <div className="col-12 col-xl-12 position-relative">
      <div className="card">
        <div className="w-100 px-3 pt-3 d-flex">
          <div className="w-100 d-flex gap-1">
            
            <div className="col-md-12 col-lg-12 col-xl-10 col-xxl-8 d-flex gap-1 align-items-center">
              <div className="d-flex w-50">
                <select
                  className="form-select"
                  aria-label="select example"
                  value={selectedDateRange ? selectedDateRange : "today"}
                  onChange={handleDateRangeChange}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="thisWeek">This Week</option>
                  <option value="lastWeek">Last Week</option>
                  <option value="thisMonth">This Month</option>
                  <option value="lastMonth">Last Month</option>
                  <option value="thisYear">This Year</option>
                </select>
              </div>
              <div className="w-100 d-flex gap-1 align-items-center">
                <div className="w-60 d-flex gap-1 align-items-center justify-content-between">
                  <label className="form-label mb-0" htmlFor="advertiserId">
                    From:
                  </label>
                  <DatePickerField value={fromDate} setValue={setFromDate}/>
                  
                </div>

                <div className="w-40 d-flex gap-1 align-items-center justify-content-between">
                  <label className="form-label mb-0" htmlFor="advertiserId">
                    To:
                  </label>
                  <DatePickerField value={toDate} setValue={setToDate}/>

                </div>
              </div>

              <div>
                {reportsFilterLoading ? (
                  <button
                    className="btn btn-primary d-flex gap-2 align-items-center"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Apply
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={applyFilterHandler}
                  >
                    Apply
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <table id="reports" className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="position-relative">
                Date{" "}  
              </th>
              <th scope="col">
                Name{" "}
              </th>
              <th scope="col">
                Dup Count{" "}
              </th>
              <th scope="col">
                Click Count{" "} 
              </th>
              <th scope="col">
                Click Cost{" "} 
              </th>
              <th scope="col">
                Click Income{" "}
              </th>
              <th scope="col">
                Total Click Income{" "} 
              </th>
              <th scope="col">
                Gross Income{" "} 
              </th>
              <th scope="col">
                Net Income{" "}
              </th>
              <th scope="col">
                Impressions Count{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {reportsList.length > 0 ? (
              reportsList.map((report, index) => {
                const isExpanded = collapseStatus[index];
               
                return (
                  <React.Fragment key={`report-${index}`}>
                    <tr className="table-primary">
                      <td
                        title="Show Sub1 Stats"
                        className="cursor-pointer"
                        onClick={() => {
                          handleRowClick(index);
                          if (!collapseStatus[index]) {
                            setDisplayStatIndex(index);
                            // setSub1RowCollapseStatus({}); 
                            // setSub2RowCollapseStatus({}); 
                            setIsSub1IconClick(true); 
                            setLoadingRowIndex({ mainIndex: index, sub1Index: null, sub2Index: null });
                            sub1DataFetch(report.date);
                          }
                        }}
                      >
                        {`${report.date}`}
                        <i
                          className={`fa-solid ${isExpanded ? 'fa-circle-minus' : 'fa-circle-plus'} ps-3`}
                        ></i>
                        {sub1Loading && loadingRowIndex.mainIndex === index && loadingRowIndex.sub1Index === null ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                      </td>
                      <td>{report.ad_unit_name}</td>
                      <td>{report.dup_count}</td>
                      <td>{report.click_count}</td>
                      <td>{"$ " + parseFloat(report.click_cost).toFixed(2)}</td>
                      <td>{"$ " + parseFloat(report.click_income).toFixed(2)}</td>
                      <td>{"$ " + parseFloat(report.total_click_income).toFixed(2)}</td>
                      <td>{"$ " + parseFloat(report.gross_income).toFixed(2)}</td>
                      <td>{"$ " + parseFloat(report.net_income).toFixed(2)}</td>
                      <td>{report.impression_count}</td>
                    </tr>

                    {/* Sub1 Rows */}
                    {isExpanded &&
                      report.sub1Data.map((item1, sub1Index) => {
                        const isSub1Expanded = sub1RowCollapseStatus[`${index}-${sub1Index}`];
                        return (
                          <React.Fragment key={`sub1-${index}-${sub1Index}`}>
                             <tr className="table-secondary">
                              <td
                                // style={{ paddingLeft: "30px" }}
                                title="Show Sub2 Stats"
                                className="cursor-pointer"
                                onClick={() => {
                                  handleSub2RowClick(index, sub1Index);
                                  if (!isSub1Expanded) {
                                    setIsSub2IconClick(true);
                                    setLoadingRowIndex({ mainIndex: index, sub1Index , sub2Index: null });
                                    sub2DataFetch(report.date, item1.sub1);
                                  }
                                }}
                              >
                               {item1.sub1 === "" || item1.sub1 === undefined ? 
                                  (
                                    <span>
                                      <img width={"20px"} height={"20px"} src={Sub1Img} alt="" title="" />
                                      blank
                                    </span>
                                  ) : (
                                  <>
                                    { <img width={"20px"} height={"20px"} src={Sub1Img} alt="" title=""/>}
                                    {item1.sub1}
                                    {item1.sub1 && (
                                      <>
                                        <i
                                          className={`${isSub1Expanded ? 'fa-solid fa-circle-minus' : 'fa-solid fa-circle-plus'} ps-3`}
                                        ></i>
                                        {sub2Loading && loadingRowIndex.mainIndex === index && loadingRowIndex.sub1Index === sub1Index ? (
                                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : null}
                                      </>
                                    )}
                                  </>
                                )}
                                
                              </td>
                              <td>{item1.items[0].ad_unit_name}</td>
                              <td>{item1.items[0].dup_count}</td>
                              <td>{item1.items[0].click_count}</td>
                              <td>{"$ " + sumOfFieldForRow(item1, "click_cost")}</td>
                              <td>{"$ " + sumOfFieldForRow(item1, "click_income")}</td>
                              <td>{"$ " + sumOfFieldForRow(item1, "total_click_income")}</td>
                              <td>{ "$ " + sumOfFieldForRow(item1, "gross_income")}</td>
                              <td>{"$ " + sumOfFieldForRow(item1, "net_income")}</td>
                              <td>{item1.items[0].impression_count}</td>
                            </tr>

                            {/* Sub2 Rows */}
                            {isSub1Expanded &&
                              report.sub1Data[sub1Index].sub2Data?.map((item2, sub2index) => {
                                const isSub2Expanded = sub2RowCollapseStatus[`${index}-${sub1Index}-${sub2index}`];
                                return (
                                  <React.Fragment key={`sub2-${index}-${sub1Index}-${sub2index}`}>
                                    <tr className="table-success">
                                      <td
                                        // style={{ paddingLeft: "50px" }}
                                        title="Show Sub3 Stats"
                                        className="cursor-pointer"
                                        onClick={() => {
                                          handleSub3RowClick(index, sub1Index, sub2index);
                                          if(!isSub2Expanded){
                                            setIsSub3IconClick(true);
                                            setLoadingRowIndex({ mainIndex: index, sub1Index , sub2Index: sub2index });
                                            sub3DataFetch(report.date, item1.sub1, item2.sub2);
                                          }
                                          
                                        }}
                                      >
                                        {item2.sub2 === "" || item2.sub2 === undefined ?  (
                                          <span>
                                            <img width={"40px"} height={"20px"} src={Sub2Img} alt="" title=""/>
                                            blank
                                          </span>
                                        ) : (
                                          <>
                                          { <img width={"40px"} height={"20px"} src={Sub2Img} alt="" title=""/>}
                                            {item2.sub2}
                                            {item2.sub2 && (
                                              <>
                                                <i
                                                  className={`fa-solid ${isSub2Expanded ? 'fa-circle-minus' : 'fa-circle-plus'} ps-3`}
                                                ></i>
                                                {sub3Loading && loadingRowIndex.mainIndex === index && loadingRowIndex.sub1Index === sub1Index && loadingRowIndex.sub2Index === sub2index ? (
                                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                ) : null}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </td>
                                      <td>{item2.items[0].ad_unit_name}</td>
                                      <td>{item2.items[0].dup_count}</td>
                                      <td>{item2.items[0].click_count}</td>
                                      <td>{"$ " + sumOfFieldForRow(item2, "click_cost")}</td>
                                      <td>{"$ " + sumOfFieldForRow(item2, "click_income")}</td>
                                      <td>{"$ " + sumOfFieldForRow(item2, "total_click_income")}</td>
                                      <td>{"$ " + sumOfFieldForRow(item2, "gross_income")}</td>
                                      <td>{"$ " + sumOfFieldForRow(item2, "net_income")}</td>
                                      <td>{item2.items[0].impression_count}</td>
                                    </tr>
                                    {isSub2Expanded && item2.sub3Data?.map((item3, sub3index) => (
                                      <tr className="table-warning" key={`sub3-${index}-${sub1Index}-${sub2index}-${sub3index}`}>
                                        {/* <td style={{ paddingLeft: "75px" }}> */}
                                        <td>
                                          {item3.sub3 === "" || item3.sub3 === undefined ? (
                                              <span>
                                                <img width={"60px"} height={"20px"} src={Sub3Img} alt="" title=""  />
                                                blank
                                              </span>
                                            ) :   <span>
                                            <img width={"60px"} height={"20px"} src={Sub3Img} alt="" title=""  />
                                            item3.sub3
                                          </span> }
                                          { " : no sub record found"}
                                        </td>
                                        <td>{item3.items[0].ad_unit_name}</td>
                                        <td>{item3.items[0].dup_count}</td>
                                        <td>{item3.items[0].click_count}</td>
                                        <td>{"$ " + sumOfFieldForRow(item3, "click_cost")}</td>
                                        <td>{"$ " + sumOfFieldForRow(item3, "click_income")}</td>
                                        <td>{"$ " + sumOfFieldForRow(item3, "total_click_income")}</td>
                                        <td>{"$ " + sumOfFieldForRow(item3, "gross_income")}</td>
                                        <td>{"$ " + sumOfFieldForRow(item3, "net_income")}</td>
                                        <td>{item3.items[0].impression_count}</td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                );
                              })}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan="12">No records found…</td>
              </tr>
            )}
          </tbody>


        </table>
        {/* <hr />
        <PaginationComponent /> */}
      </div>
      {/* {apiData == null && <Spinner/>} */}
    </div>
  );
};

export default PublisherOfferDailyStatReportsList;
   