import React, { useEffect, useState } from "react";
import Spinner from "../../components/common/Spinner";
import LoadingButton from "../../components/common/LoadingButton";
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import AppStore from "../../config/AppStore";
import {
  sendRequestToFetchUserContactDetails,
  sendRequestToUpdateUserContactDetail,
} from "../../view_logics/ContactInfo";
import { useSelector } from "react-redux";

const UpdateContactInfo = () => {
  let store = AppStore.getAppStoreInstance();
  let accessToken = store.getState().login.accessToken;
  const { userId, publisherId } = useSelector((state) => state?.login);

  const [userFormLoading, setUserFormLoading] = useState(false);
  const [userById, setUserById] = useState(null);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      email: "",
      company_name: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      last_name: "",
      is_active: 1,
      xoptin_commission: "",
      revenue_share: "",
    },
    onSubmit: async (values, { setSubmitting }, event) => {
      console.log("here");
      event.preventDefault();
    },
  });

  useEffect(() => {
    if (publisherId != null) {
      const fetchData = async () => {
        let res = await sendRequestToFetchUserContactDetails({
          id: publisherId,
          accessToken: accessToken,
        });
        if (res) {
          setUserById({ ...res });
        }
      };
      fetchData();
    }
  }, [publisherId]);

  const updateProfileHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const serializedData = {
      first_name: "",
      last_name: userById?.last_name,
      email: "",
      password: userById?.password,
      company_name: "",
      xoptin_commission: userById?.xoptin_commission,
      revenue_share: userById?.revenue_share,
      address: "",
      country: "",
      city: "",
      state: "",
      zip_code: "",
      publisher_id: "",
    };

    for (let [name, value] of formData.entries()) {
      if (name) {
        serializedData[name] = value ? value : "";
      }
    }
    if (publisherId != undefined) {
      serializedData["publisher_id"] = publisherId;
    }
    const jsonData = JSON.stringify(serializedData);
    console.log("jsondata", jsonData);
    const inputObj = {
      accessToken: accessToken,
      url: "/contact_info",
      history: navigate,
      dataToPost: serializedData,
      loadingHandler: setUserFormLoading,
      setResponseHandler: setUserById
    };
    setUserFormLoading(true);
    if (publisherId != undefined) {
      sendRequestToUpdateUserContactDetail(inputObj);
    }
  };

  const userValidation = Yup.object().shape({
    first_name: Yup.string().required("First name is Required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email format.Example: example@mail.com"
      )
      .required("This field is required"),
    company_name: Yup.string().required("Company name is Required"),

    address: Yup.string().required("Address is Required"),
    city: Yup.string().required("City Id Required"),
    state: Yup.string().required("State Id Required"),
    zip_code: Yup.string().required("Zip code Id Required"),
    country: Yup.string().required("Country Id Required"),
  });
  return (
    <div className="position-relative">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>{"Contact Information"}</h1>
          <Formik
            initialValues={userById || formik.initialValues}
            validationSchema={userValidation}
            enableReinitialize
          >
            {({ handleSubmit, errors, isValid }) => {
              console.log(
                "🚀 ~ file: CreateWebsite.js:166 ~ CreateWebsite ~ errors:",
                errors
              );
              return (
                <form onSubmit={updateProfileHandler}>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Basic Info</h5>
                    </div>

                    <div className="card-body">
                      <div className="row">
                      <div className="mb-3 col-md-3">
                          <label className="form-label" htmlFor="Email">
                            First Name
                          </label>
                          <Field
                            type="text"
                            id="first_name"
                            name="first_name"
                            className={"form-control"}
                            required
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>

                        <div className="mb-3 col-md-3">
                          <label className="form-label" htmlFor="Email">
                            Last Name
                          </label>
                          <Field
                            type="text"
                            id="last_name"
                            name="last_name"
                            className={"form-control"}
                            required
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>

                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Name">
                            Company Name
                          </label>
                          <Field
                            type="text"
                            id="company_name"
                            name="company_name"
                            className="form-control"
                            required
                            //   value={websiteById && websiteById.name}
                          />
                          <ErrorMessage
                            name="company_name"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        
                      </div>

                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Email">
                            Contact Email
                          </label>
                          <Field
                            type="text"
                            id="email"
                            name="email"
                            className={"form-control"}
                            required
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Name">
                            Country
                          </label>
                          <Field
                            type="text"
                            id="country"
                            name="country"
                            className="form-control"
                            required
                            //   value={websiteById && websiteById.name}
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Email">
                            Address
                          </label>
                          <Field
                            type="text"
                            id="address"
                            name="address"
                            className={"form-control"}
                            required
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Name">
                            City
                          </label>
                          <Field
                            type="text"
                            id="city"
                            name="city"
                            className="form-control"
                            required
                            //   value={websiteById && websiteById.name}
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="Email">
                            State
                          </label>
                          <Field
                            type="text"
                            id="state"
                            name="state"
                            className={"form-control"}
                            required
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>

                        <div className="mb-3 col-md-2">
                          <label className="form-label" htmlFor="Email">
                            Zip
                          </label>
                          <Field
                            type="text"
                            id="zip_code"
                            name="zip_code"
                            className={"form-control"}
                            required
                          />
                          <ErrorMessage
                            name="zip_code"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr></hr>
                  <div className="form-group row mb-0">
                    <div className="d-flex gap-2 justify-content-end">
                      <button
                        className="btn btn-success btn-lg"
                        onClick={() => navigate("/profile/reset-password")}
                        // disabled={isValid}
                      >
                        Reset Password
                      </button>
                      {userFormLoading ? (
                        <LoadingButton name="Save" />
                      ) : (
                        <button
                          disabled={isValid ? false : true}
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </main>
      {publisherId && !userById && <Spinner />}
    </div>
  );
};

export default UpdateContactInfo;
