import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import CreateWebsite from "./CreateWebsite";
import WebsitesList from "./WebsitesList";

import { useNavigate } from "react-router-dom";
import AppStore from "../../config/AppStore";
import { createPublisherBtnClick } from "../../view_logics/Publishers";

const Websites = () => {
  const [showCreatePublishersContent, setShowCreatePublishersContent] =
    useState(false);
  const handleCreatePublishersClick = (menuItem) => {
    setShowCreatePublishersContent(menuItem);
  };

  let store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler

  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "create",
    history: navigate,
  };


  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Websites</h1>
          {!showCreatePublishersContent ? (
            <>
              <div className="col-md-3">
                <a
                  // onClick={() => handleCreatePublishersClick('createPublishers')}
                  onClick={() => createPublisherBtnClick(inputObj)}
                  className="btn btn-primary float-right mb-3"
                  style={{ width: "max-content", whiteSpace: "nowrap" }}
                >
                  Create New Website
                </a>
              </div>
            </>
          ) : (
            <div className="col-md-3">
              <a
                // onClick={() => handleCreatePublishersClick(false)}
                className="btn btn-primary float-right mb-3"
              >
                Back
              </a>
            </div>
          )}

          <hr />
          {showCreatePublishersContent ? <CreateWebsite /> : <WebsitesList />}
        </div>
      </main>
    </div>
  );
};

export default Websites;
