import React from "react";

const Alert = ({message=null, alertType=null}) => {
  console.log("🚀 ~ file: Alert.js:4 ~ Alert ~ alertType:", alertType)
  return (
    <div className={`alert ${alertType ? 'alert-'+alertType : 'alert-primary'} alert-dismissible w-50`} role="alert" style={{zIndex: 1000}}>
      {/* <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button> */}
      <div className="alert-icon">
        <i className="far fa-fw fa-bell"></i>
      </div>
      <div className="alert-message">
        <strong>{message ? message : ''}</strong>
      </div>
    </div>
  );
};

export default Alert;
