import { React, useRef, useEffect } from "react";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
const Step3 = (props) => {
  console.log("🚀 ~ Step3 ~ props:", props?.formData);

  const selectCookiesRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      
      // Initial values for form fields
      // ...
    },
    onSubmit: (values) => {
      // Handle form submission
      // ...
    },
  });

  const handleBackButton = (props) => {
    if(props?.formData?.type == "exit_popup_template"){
      return props.navigation.previous();
    }
    // if (
    //   props.formData.offer_rotation_time < 10 ||
    //   props.formData.offer_rotation_time > 60
    // ) {
    //   document.getElementById("offer_rotation_time").focus();
    //   return;
    // }
      // const offers = document.getElementById("no_of_offers").value;
      // if (props?.formData?.type != "custom_ad_sequence" && offers < 1 || offers > 10) {
      //   document.getElementById("offersNumberError").style.display = "block";
      //   document.getElementById("headerError").style.display = "none";
      //   document.getElementById("no_of_offers").focus();
      //   return;
      // }
      // if (props?.formData?.type == "headerTemplate") {
      //   if (offers == 0 || offers % 2 !== 0) {
      //     document.getElementById("headerError").style.display = "block";
      //     document.getElementById("offersNumberError").style.display = "none";
      //     document.getElementById("no_of_offers").focus();
      //     return;
      //   } else {
      //     document.getElementById("headerError").style.display = "none";
      //   }
      // }
    props.navigation.previous();
  };


  useEffect(()=>{
    if(props?.formData.type == "exit_popup_template"){
      props?.setFormData((prev) => ({
        ...prev,
        show_on_navgiation_away: true
      }))
    }
  },[props?.formDatas])
  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>
            {props.formData.ad_unit_id
              ? "Create New Add Unit"
              : "Edit Add Unit"}
          </h1>
          <div className="tab-content">
            <div className="tab-pane active" id="tab-1" role="tabpanel">
              <Formik
                initialValues={formik.initialValues}
                onSubmit={formik.handleSubmit}
                validationSchema={Yup.object({
                  offer_rotation_time: Yup.number()
                    .required("Please enter value between 10 and 60")
                    .min(10, "Minimum value is 10 Seconds")
                    .max(60, "Maximum value is 60 Seconds"),
                })}
              >
                <form name="form3Data">
                  <input
                    type="hidden"
                    name="_token"
                    value="C5Oe8oLVgFK6wo5dqcWEYVrjvHXO1yrmMsiMvqnf"
                  />
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Advanced Options</h5>
                      <p className="text-danger">
                        We don't suggest making any changes here, unless you are
                        very sure about it!
                      </p>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        {props?.formData?.type != "exit_popup_template" && props?.formData?.type != "custom_ad_sequence" && 
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="no_of_offers">
                            No of Offers
                          </label>
                          <Field
                            type="number"
                            id="no_of_offers"
                            name="no_of_offers"
                            className="form-control"
                            required
                            onChange={(e)=> props?.setFormData((prevConfigObject) => ({
                              ...prevConfigObject,
                              no_of_offers: e.target.value
                            }))}
                            value={props.formData.no_of_offers}
                          />
                          <div
                            id="headerError"
                            className="invalid-feedback"
                            style={{ display: "none" }}
                          >
                            Should be in multiples of 2
                          </div>
                          <div
                            id="offersNumberError"
                            className="invalid-feedback"
                            style={{ display: "none" }}
                          >
                            Values should be betwen 1-10
                          </div>

                          <ErrorMessage
                            name="no_of_offers"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        }

                        <div className="mb-3 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="encrypt_cookies_flag"
                          >
                            Encrypt Cookies
                          </label>
                          <select
                            name="encrypt_cookies_flag"
                            id="encrypt_cookies_flag"
                            ref={selectCookiesRef}
                            className="form-control"
                            value={props.formData.encrypt_cookies_flag}
                            // onChange={props.setFormData}
                            onChange={(e)=> props?.setFormData((prevConfigObject) => ({
                              ...prevConfigObject,
                              encrypt_cookies_flag: e.target.value
                            }))}
                          >
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                          <ErrorMessage
                            name="encrypt_cookies_flag"
                            component="div"
                          />
                        </div>

                        {props?.formData?.type == "exit_popup_template" &&
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="min_ecpm">
                            Min required ECPM
                          </label>
                          <Field
                            type="number"
                            id="min_ecpm"
                            name="min_ecpm"
                            className="form-control"
                            // onChange={props.setFormData}
                            onChange={(e)=> props?.setFormData((prevConfigObject) => ({
                              ...prevConfigObject,
                              min_ecpm: e.target.value
                            }))}
                            value={props.formData.min_ecpm}
                          />
                          <ErrorMessage name="min_ecpm" component="div" />
                        </div>
                        }
                      </div>
                      <div className="row">
                        
                        {props?.formData?.type != "exit_popup_template" &&
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="min_ecpm">
                            Min required ECPM
                          </label>
                          <Field
                            type="number"
                            id="min_ecpm"
                            name="min_ecpm"
                            className="form-control"
                            // onChange={props.setFormData}
                            onChange={(e)=> props?.setFormData((prevConfigObject) => ({
                              ...prevConfigObject,
                              min_ecpm: e.target.value
                            }))}
                            value={props.formData.min_ecpm}
                          />
                          <ErrorMessage name="min_ecpm" component="div" />
                        </div>
                        }

                        {props?.formData?.type != "custom_ad_sequence" &&
                        <div className="mb-3 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="offer_rotation_time"
                          >
                            Ad Rotaion Interval(Seconds)
                          </label>
                          <Field
                            type="number"
                            id="offer_rotation_time"
                            name="offer_rotation_time"
                            className="form-control"
                            required
                            // onChange={props.setFormData}
                            onChange={(e)=> props?.setFormData((prevConfigObject) => ({
                              ...prevConfigObject,
                              offer_rotation_time: e.target.value
                            }))}
                            value={props.formData.offer_rotation_time}
                          />
                          <ErrorMessage
                            name="offer_rotation_time"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        }
                      </div>

                      {props?.formData?.type == "exit_popup_template" && (
                        <>
                          <hr />

                          <div className="">
                            <h5 className="card-title">Exit Popup Configs</h5>

                            <div className="d-flex align-items-center ">
                              <div
                                className="w-50 d-flex gap-2"
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <label className="form-check form-check-inline">
                                  <input
                                    type="checkbox"
                                    id="show_on_navgiation_away"
                                    name="show_on_navgiation_away"
                                    className="form-check-input"
                                    onChange={(e)=> props?.setFormData((prevConfigObject) => ({
                                      ...prevConfigObject,
                                      show_on_navgiation_away: e.target.checked
                                    }))}
                                    // value={props?.formData?.show_on_navgiation_away}
                                    checked={props?.formData?.show_on_navgiation_away == '1' ? true : false}
                                  />
                                  <span className="form-check-label ml-10">
                                    Show on navgiation away
                                  </span>
                                </label>
                              </div>

                              <div className=" w-50 mb-3 col-md-6">
                                <label className="form-label" htmlFor="Name">
                                  {`Inactivity duration (seconds)`}
                                </label>
                                <input
                                  type="number"
                                  id="inactivity_duration"
                                  name="inactivity_duration"
                                  className="form-control"
                                  required
                                  onChange={(e)=> props?.setFormData((prevConfigObject) => ({
                                    ...prevConfigObject,
                                    inactivity_duration: e.target.value
                                  }))}
                                  value={props?.formData?.inactivity_duration ? props?.formData?.inactivity_duration : 5}
                                  //   value={websiteById && websiteById.name}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* <button 
                        className="btn btn-success btn-lg ml-5" 
                        onClick={() =>props.navigation.next()}> 
                        Next
                    </button> */}

                  {/* <hr />
                    <div className="form-group row mb-0">
                        <div className="col-md-6 offset-md-11">
                            <button type="submit" className="btn btn-primary">
                                Save
                            </button>
                        </div>
                    </div> */}
                </form>
              </Formik>
              <button
                className="btn btn-warning btn-lg"
                onClick={() => handleBackButton(props)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Step3;
