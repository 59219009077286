import React, { useEffect } from "react";
import styles from "./styles.module.css"; // Import CSS Module

const CustomSidebarPage = () => {
  useEffect(() => {
    // Define the script to be executed
    const script = document.createElement("script");
    script.innerHTML = `
    var xoptinSettings = {
      'aid': '01HCNBJZ7FTMF15WZ6G32YX55J',
      'sub1': '',
      'sub2': '',
      'sub3': '',
      'first_name': '[first_name]',
      'last_name': '[last_name]',
      'email': '[email]',
      'phone': '[phone]',
      'gender': '[gender]',
      'dob': '[dob]',
      'state': '[state]',
      'country': '[country]',
      'zip': '[zip]',
      'city': '[city]',
      'address': '[address]'
    };
    initialize_ads(xoptinSettings);
    `;
    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <script src="https://stgcdn.xoptin.com/xoptin-ads.js"></script>
      <div className={styles.wrapper}>
        {/* <!--Header Start--> */}
        <header className={styles.select_format_template_hdr}>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
              <a className="navbar-brand" href="#">
                Logo
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Features
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Pricing
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        {/* <!--Header End-->
	<!--Content Start--> */}
        <div
          className={`${styles.body_content} row`}
          style={{
            marginRight: "0px",
            marginLeft: "0px",
            paddingBottom: "0px",
          }}
        >
          <section order="1">
            <div className={`${styles.select_format_template_bx01} col-12`}>
              <div className="container">
                <div className="row">
                  <div
                    className={`${styles.select_format_template_bx01_lt} col-sm-8`}
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <p className={styles.alt01}>
                          What is Lorem Ipsum?
                          <br />
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                        <p className={styles.alt01}>
                          What is Lorem Ipsum?
                          <br />
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                        <p className={styles.alt01}>
                          What is Lorem Ipsum?
                          <br />
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.select_format_template_mar_tp25} col-sm-4`}
                  >
                    <div
                      id="01HCNBJZ7FTMF15WZ6G32YX55J"
                      style={{
                        background: "white",
                        height: "400px",
                      }}
                    >
                      <iframe
                        width="100%"
                        style={{
                          border: "0px",
                          height: "400px",
                        }}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <!--Content End-->
	<!--Footer Start--> */}
        <footer className={styles.select_format_template_ftr}>
          <div className="col-12">
            <div className="container">
              <div className="row">
                <div className={`${styles.lp01_ftr_bx02} col-12 text-center`}>
                  <p className={styles.alt01}>
                    <a href="#">Privacy Policy</a>&nbsp;|&nbsp;
                    <a href="#.html">Terms and Conditions</a>&nbsp;|&nbsp;
                    <a href="#.html">Unsubscribe</a>&nbsp;|&nbsp;
                    <a href="#.html">Contact Us</a>
                  </p>
                  <p className={styles.alt02}>
                    &copy; Sample Site, All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* <!--Footer End-->	 */}
      </div>
    </>
  );
};

export default CustomSidebarPage;
