
import {
  addToSessionStorage,
  clearSessionStorage,
  sendGETRequest,
  sendPOSTRequest,
  validateUserToken,
  redirectToPage
} from "../../utils/common_functions";

import Constants from "../../config/Constants";

  var handlersObj = {};
  export const sendRequestGetPublishers = async(dataToPost) => {
    console.log('sendRequestGetPublishers');
    // console.log(dataToPost.advertisers);
    const url = Constants.APP_SERVER_OPTION_PUBLISHERS_LIST_URL;
    const headersToPost = {
      "Content-type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + dataToPost.accessToken,
    };
    let data = {
      ...dataToPost,
    };
    delete data["accessToken"];
    console.log(dataToPost);
    console.log(headersToPost);
    sendGETRequest(
      url,
      data,
      headersToPost,
      sendRequestPublishersSuccessHandler,
      sendRequestPublishersErrorHandler
    );
  };

function sendRequestPublishersSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("PublisherId")) {
  //    handlersObj.tickerTypes(response);
  // }
  request.dataHander(response);
}
function sendRequestPublishersErrorHandler() {}


export const createPublisherBtnClick = (dataToPost) => {
  console.log('createPublisherBtnClick');
  console.log(dataToPost);
  // let store = AppStore.getAppStoreInstance();
  // const inputObj = {
  //   userToken: store.getState().login.accessToken,
  // };
  redirectToPage(dataToPost);
};
