import React, { useEffect } from "react";
import styles from "./styles.module.css"; // Import CSS Module

const CustomAdFullPage = () => {

  useEffect(() => {
    // Define the script to be executed
    const script = document.createElement('script');
    script.innerHTML = `
      var xoptinSettings = {
        aid: "01HCNBKBFSBNG0SJD3ABGJDZ6X",
        sub1: "",
        sub2: "",
        sub3: "",
        first_name: "[first_name]",
        last_name: "[last_name]",
      };
      initialize_ads(xoptinSettings);
    `;
    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

 
  return (
    <>
      <>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />
        <title>Opinion City</title>

        <link
          rel="manifest"
          href="https://staging.opinioncity.com/manifest.json"
        />
        {/* <!-- <link rel="stylesheet" type="text/css" href="css/style.css"> --> */}
        <link
          href="https://fonts.googleapis.com/css?family=Lato:700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,700i,800,800i"
          rel="stylesheet"
        />
        <link
          rel="shortcut icon"
          href="https://s3.amazonaws.com/leadgen-prod/1/images/favicon.ico"
          type="image/x-icon"
        />
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
          integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u"
          crossOrigin="anonymous"
        />
        <link
          type="text/css"
          rel="stylesheet"
          href="https://staging.opinioncity.com/css/libs/font-awesome.min.css"
        />
        <link
          type="text/css"
          rel="stylesheet"
          href="https://staging.opinioncity.com/css/libs/parsley.min.css"
        />
        <link
          type="text/css"
          rel="stylesheet"
          href="https://staging.opinioncity.com/1.css"
        />

        {/* <!-- <link rel="stylesheet" type="text/css" href="css/custom_ad_full.css" /> --> */}

        <script
          type="text/javascript"
          async=""
          src="https://www.google-analytics.com/analytics.js"
        ></script>
        <script
          type="text/javascript"
          async=""
          src="https://www.googletagmanager.com/gtag/js?id=G-FLLMB7BMFX&amp;l=dataLayer&amp;cx=c"
        ></script>
        <script
          src="https://connect.facebook.net/en_US/sdk.js?hash=ce44f1335e12aed3b15c7c01e9d3a6cb"
          async=""
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://connect.facebook.net/en_US/sdk.js?hash=c66d964543c4f5246470b93c8cd3ef03"
          async=""
          crossOrigin="anonymous"
        ></script>
        <script
          id="facebook-jssdk"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&amp;version=v2.12"
        ></script>
        <script
          language="JavaScript"
          src="https://staging.opinioncity.com/js/libs/jquery-2.2.4.min.js"
        ></script>
        <script
          language="JavaScript"
          src="https://staging.opinioncity.com/js/libs/jquery.cookie.js"
        ></script>

        <script
          language="JavaScript"
          src="https://staging.opinioncity.com/js/libs/bootstrap.min.js"
        ></script>

        <script
          type="text/javascript"
          src="https://staging.opinioncity.com/js/libs/parsley.min.js"
        ></script>
        <script
          type="text/javascript"
          src="https://staging.opinioncity.com/js/user/common.js"
        ></script>

        <link
          rel="stylesheet"
          href="https://api.tnapplications.com/assets/beb319ca-47df-413a-8277-07d0c5efc2da/flow-container.css"
        />
        <script
          referrerPolicy="no-referrer-when-downgrade"
          src="https://api.tnapplications.com/assets/beb319ca-47df-413a-8277-07d0c5efc2da/flow-container.js"
        ></script>

        <script
          type="text/javascript"
          src="https://staging.opinioncity.com/js/user/common_qa.js"
        ></script>
        <script
          type="text/javascript"
          src="https://staging.opinioncity.com/js/user/qa_cpc_list_view.js"
        ></script>

        <script
          type="text/javascript"
          src="https://api.pushnami.com/scripts/v1/pushnami-adv/5e20d40a43413028619a9a58"
        ></script>
        <script
          type="text/javascript"
          src="https://api.pushnami.com/scripts/v1/pushnami-adv/5e20d40a43413028619a9a58"
        ></script>
        <script
          async=""
          src="https://www.googletagmanager.com/gtag/js?id=UA-86826352-3"
        ></script>

        <script src="https://staging.opinioncity.com/affiliate0_pixel_touchpoints.js"></script>

        <script src="https://stgcdn.xoptin.com/xoptin-ads.js"></script>

        <script
          type="text/javascript"
          async=""
          src="https://cdn.trustedform.com/trustedform-1.9.4.js"
        ></script>
      </>

      <div className="w-100 d-flex flex-column">
        <div className="wrapper" style={{ minHeight: "10px" }}>
          {/* <!--Header Start--> */}
          <header className={`${styles.header}`}>
            <nav className={`${styles.navbar}`}>
              <div className="container">
                <div className="col-md-3 col-sm-3 col-xs-6">
                  <img
                    title=""
                    alt=""
                    src="https://s3.amazonaws.com/leadgen-prod/1/images/logo.png"
                  />
                </div>
                <div
                  className={`hidden-lg hidden-md hidden-sm visible-xs col-xs-6 text-right`}
                >
                  <div
                    className={`${styles.fb_iframe_widget} fb-like`}
                    data-action="like"
                    data-share="true"
                    data-show-faces="false"
                    data-size="large"
                    data-layout="button_count"
                    data-href="https://www.facebook.com/opinioncitymatters/"
                    fb-xfbml-state="rendered"
                    fb-iframe-plugin-query="action=like&amp;app_id=&amp;container_width=0&amp;href=https%3A%2F%2Fwww.facebook.com%2Fopinioncitymatters%2F&amp;layout=button_count&amp;locale=en_US&amp;sdk=joey&amp;share=true&amp;show_faces=false&amp;size=large"
                  >
                    <span
                      style={{
                        verticalAlign: "bottom",
                        width: "174px",
                        height: "28px",
                      }}
                    >
                      <iframe
                        width="1000px"
                        height="1000px"
                        style={{
                          border: "none",
                          visibility: "visible",
                          width: "174px",
                          height: "28px",
                        }}
                        name="f2d99d546cb2e594b"
                        data-testid="fb:like Facebook Social Plugin"
                        title="fb:like Facebook Social Plugin"
                        frameBorder="0"
                        allowtransparency="true"
                        allowFullScreen={true}
                        scrolling="no"
                        allow="encrypted-media"
                        src="https://www.facebook.com/v2.12/plugins/like.php?action=like&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df5ad503d789622147%26domain%3Dstaging.opinioncity.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fstaging.opinioncity.com%252Ff838064b1ac870b87%26relation%3Dparent.parent&amp;container_width=0&amp;href=https%3A%2F%2Fwww.facebook.com%2Fopinioncitymatters%2F&amp;layout=button_count&amp;locale=en_US&amp;sdk=joey&amp;share=true&amp;show_faces=false&amp;size=large"
                        className=""
                      ></iframe>
                    </span>
                  </div>
                </div>
                <div
                  className={`${styles.qa3_01_header_midtxt} col-md-7 col-sm-6 col-xs-12  text-center`}
                >
                  GET PAID by taking great surveys from home or anywhere!
                </div>
                <div className="col-md-2 col-sm-3 hidden-xs fb-like-share-btn-mt text-right">
                  <div
                    className={`${styles.fb_iframe_widget} fb-like `}
                    data-action="like"
                    data-share="true"
                    data-show-faces="false"
                    data-size="large"
                    data-layout="button_count"
                    data-href="https://www.facebook.com/opinioncitymatters/"
                    fb-xfbml-state="rendered"
                    fb-iframe-plugin-query="action=like&amp;app_id=&amp;container_width=174&amp;href=https%3A%2F%2Fwww.facebook.com%2Fopinioncitymatters%2F&amp;layout=button_count&amp;locale=en_US&amp;sdk=joey&amp;share=true&amp;show_faces=false&amp;size=large"
                  >
                    <span
                      style={{
                        verticalAlign: "bottom",
                        width: "174px",
                        height: "28px",
                      }}
                    >
                      <iframe
                        name="fc26ca9e8a86026ca"
                        width="1000px"
                        height="1000px"
                        data-testid="fb:like Facebook Social Plugin"
                        title="fb:like Facebook Social Plugin"
                        frameBorder="0"
                        allowtransparency="true"
                        allowFullScreen={true}
                        scrolling="no"
                        allow="encrypted-media"
                        src="https://www.facebook.com/v2.12/plugins/like.php?action=like&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3511a1dc321285bd%26domain%3Dstaging.opinioncity.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fstaging.opinioncity.com%252Ffe7013ba2cda3254f%26relation%3Dparent.parent&amp;container_width=174&amp;href=https%3A%2F%2Fwww.facebook.com%2Fopinioncitymatters%2F&amp;layout=button_count&amp;locale=en_US&amp;sdk=joey&amp;share=true&amp;show_faces=false&amp;size=large"
                        style={{
                          border: "none",
                          visibility: "visible",
                          width: "174px",
                          height: "28px",
                        }}
                        className=""
                      ></iframe>
                    </span>
                  </div>
                </div>
              </div>
            </nav>
          </header>
          {/* <!--Header End--> */}
        </div>
        <nav
          className={`${styles.navbar_default} ${styles.navbar} text-center`}
          style={{ height: "94px" }}
        >
          <p className={styles.qa3_01_navtxt}>
            Click YES to all the exclusive offers you’d like to learn more
            about!
          </p>
        </nav>
        {/* <!--Content Start--> */}
        <div
          className={`row ${styles.body_content} `}
          style={{
            marginRight: "0px",
            marginLeft: "0px",
            paddingBottom: "150px",
          }}
        >
          <section order="1" className="page">
            <div className="col-md-12">
              <div className="container">
                <div className="d-flex flex-column gap-5">
                  <div
                    id="01HCNBKBFSBNG0SJD3ABGJDZ6X"
                    style={{ background: "white", height: "600px" }}
                  >
                    <iframe
                      width="100%"
                      style={{ border: "0px", minHeight: "600px", height: '600px' }}
                      title="Embedded Content"
                    ></iframe>
                  </div>

                 
                  <div
                    className={`${styles.listque_bx01_bx03} col-md-12 text-center`}
                  >
                    <button
                      id="continueBtn"
                      className={`${styles.listque_bx01_bx03_btn} `}
                      redirect_url="https://www.opinioncity.com/coreg.html?id=3"
                    >
                      Continue To Paid Surveys
                      <img
                        src="https://s3.amazonaws.com/leadgen-prod/1/images/hm01_arw.png"
                        alt=""
                        title=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className={`col-sm-12 ${styles.no_pad} `}
                style={{ marginTop: "25px", textAlign: "center" }}
              ></div>
            </div>
          </section>
        </div>
        <footer className={`${styles.footer}`}>
          <div className="container">
            <div className="row">
              <div className="col-md-offset-3 col-md-6 col-sm-12 text-center">
                <p className="alt01">
                  © Copyright 2016 - 2024 OpinionCity.com. All rights reserved.
                </p>
                <p className="alt02 d-flex gap-2 justify-content-center">
                  <a
                    href="https://staging.opinioncity.com/privacy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  <span>|</span>
                  <a
                    href="https://staging.opinioncity.com/terms.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                  <span>|</span>
                  <a
                    href="https://staging.opinioncity.com/unsubscribe.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Unsubscribe
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default CustomAdFullPage;
