import Constants from "../config/Constants";
import {
    addToSessionStorage,
    clearSessionStorage,
    sendGETRequest,
    sendPOSTRequest,
    validateUserToken,
    redirectToPage
  } from "../utils/common_functions";


export const sendCreateAdvertiserFormSubmitRequest = (inputObj) => {
    console.log('Sending Create ADvertiser Request!');
    console.log(inputObj);

    const url = Constants.APP_SERVER_OPTION_CREATE_ADVERTISER_URL;
    const headersToPost = {
      "Content-type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + inputObj.accessToken,
    };
    let dataToPost = inputObj.dataToPost;
    dataToPost.stateChangeHandler = inputObj.alreadyExistsHandler;
    dataToPost.history = inputObj.history;
    dataToPost.recirectUrl = inputObj.url;
    dataToPost.loadingHandler = inputObj?.loadingHandler
    sendPOSTRequest(
        url,
        dataToPost,
        headersToPost,
        sendCreateAdvertiserFormSubmitResponseSuccessHandler,
        sendCreateAdvertiserFormSubmitResponseErrorHandler
    );
    // inputObj.history(inputObj.url);
};


function sendCreateAdvertiserFormSubmitResponseSuccessHandler(response, request) {
    // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
    //    handlersObj.tickerTypes(response);
    // }
    console.log('sendCreateAdvertiserFormSubmitResponseSuccessHandler', response, request);
    console.log(response);
    if ( response.status == 'error' ){
      request.stateChangeHandler(true);
      // request.history('/create');
      request?.loadingHandler && request?.loadingHandler(false)
    }
    else{
      // console.log('going to redirect');
      request?.loadingHandler && request?.loadingHandler(false)
      request.history(request.recirectUrl, { state: { from: "Advertiser", type: 'create', message: response.message } });
    }
    // request.dataHander(response);
  }
  function sendRequestAdvertisersErrorHandler() {}
  
  
  export const sendCreateAdvertiserFormSubmitResponseErrorHandler = (error, request) => {
    console.log('sendCreateAdvertiserFormSubmitResponseErrorHandler');
    console.log(error);
    // request.stateChangeHandler(true);
    request?.loadingHandler && request?.loadingHandler(false)
    request.history('',{ state: { from: "Advertiser", type: 'error', message: error.message }});
    // request.history('/create');
  };