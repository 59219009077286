import Constants from "../../config/Constants";
import {
  addToSessionStorage,
  clearSessionStorage,
  sendGETRequest,
  sendPOSTRequest,
  sendGETRequestASync,
  validateUserToken,
  redirectToPage,
} from "../../utils/common_functions";

var handlersObj = {};
export const sendRequestGetAdvertiserOffers = async (dataToPost) => {
  console.log("sendRequestGetAdvertiserOffers");
  // console.log(dataToPost.advertisers);
  const url = Constants.APP_SERVER_OPTION_ADVERTISER_OFFERS_LIST_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  let data = {
    ...dataToPost,
  };
  delete data["accessToken"];
  console.log(dataToPost);
  console.log(headersToPost);
  sendGETRequest(
    url,
    data,
    headersToPost,
    sendRequestAdvertiserOffersSuccessHandler,
    sendRequestAdvertiserOffersErrorHandler
  );
};

function sendRequestAdvertiserOffersSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
  //    handlersObj.tickerTypes(response);
  // }
  request.dataHander(response);
}
function sendRequestAdvertiserOffersErrorHandler() {}

export const createAdvertiserBtnClick = (dataToPost) => {
  console.log("createAdvertiserBtnClick");
  console.log(dataToPost);
  // let store = AppStore.getAppStoreInstance();
  // const inputObj = {
  //   userToken: store.getState().login.accessToken,
  // };

  redirectToPage(dataToPost);
};

export const sendRequestGetAdvertisersList = async (dataToPost) => {
  console.log("sendRequestGetAdvertisersList");
  // console.log(dataToPost.advertisers);
  const url = Constants.APP_SERVER_OPTION_ADVERTISERS_LIST_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  let data = {
    ...dataToPost,
  };
  delete data["accessToken"];
  // console.log(dataToPost);
  // console.log(headersToPost);
  const result = sendGETRequestASync(
    url,
    data,
    headersToPost,
    sendRequestAdvertisersListSuccessHandler,
    sendRequestAdvertisersListErrorHandler
  );
  // dataToPost.dataHander(result);
  // console.log('results is : ');
  // console.log(result);
  return result;
};

function sendRequestAdvertisersListSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
  //    handlersObj.tickerTypes(response);
  // }
  // request.dataHander(response);
}
function sendRequestAdvertisersListErrorHandler() {}

export const sendRequestGetTagsList = async (dataToPost) => {
  console.log("sendRequestGetTagsList");
  // console.log(dataToPost.advertisers);
  const url = Constants.APP_SERVER_OPTION_TAGS_LIST_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  let data = {
    ...dataToPost,
  };
  delete data["accessToken"];
  // console.log(dataToPost);
  // console.log(headersToPost);
  const result = sendGETRequestASync(
    url,
    data,
    headersToPost,
    sendRequestTagsListSuccessHandler,
    sendRequestTagsListErrorHandler
  );
  // dataToPost.dataHander(result);
  // console.log('results is : ');
  // console.log(result);
  return result;
};

function sendRequestTagsListSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
  //    handlersObj.tickerTypes(response);
  // }
  // request.dataHander(response);
}
function sendRequestTagsListErrorHandler() {}

export const sendRequestGetAffiliateString = async (dataToPost) => {
  console.log("sendRequestGetAffiliateString");
  const url = Constants.AFFILIATE_STRING_SEARCH_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  const { dataHander, accessToken, ...data } = dataToPost;
  // let data = {
  //   ...dataToPost,
  // };
  // delete data["accessToken"];
  console.log(dataToPost);
  console.log(headersToPost);
  sendGETRequest(url, data, headersToPost, dataHander, dataHander);
};
