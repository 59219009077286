import React, { useEffect } from "react";
import styles from "./styles.module.css"; // Import CSS Module
import CloseIcon from "../../../../assets/images/close.png";
import ProgrossBarImg from "../../../../assets/images/progressbar.png";
import AdsImg from "../../../../assets/images/ads.png";

const CustomExitPopupPage = () => {
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "css/_close.scss"; // Specify the path to your stylesheet
    document.head.appendChild(link);

    // Clean up function to remove the link when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  // useEffect(() => {
  //   // Define the script to be executed
  //   const script = document.createElement("script");
  //   script.innerHTML = `
  //   var xoptinSettings = {
  //     'aid': '01HCNBJTWYMRDQ9QTTB2XMRTW4',
  //     'sub1': '',
  //     'sub2': '',
  //     'sub3': '',
  //     'show_on_navgiation_away': True,
  //     'inactivity_duration': 9,
  //     'first_name': '[first_name]',
  //     'last_name': '[last_name]'
  //   };
  //   initialize_exit_popup(xoptinSettings);
  //   `;
  //   // Append the script to the document body
  //   document.body.appendChild(script);

  //   // Clean up the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  return (
    <div className="">
      <div className="modal-dialog modal-lg border border-4 border-dark rounded-3">
        <div className="modal-content">
          <div className={`${styles.popup_ads_bx01} modal-body text-center`}>
            <div className="row">
              <div className={`${styles.popup_ads_bx01_bx01} col-12`}>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className={styles.popup_ads_bx01_bx01_close}
                >
                  <img src={CloseIcon} alt="" title="" />
                </button>
              </div>
            </div>
            <div className="row">
              <div className={`${styles.popup_ads_bx01_bx02} col-12`}>
                <p className={styles.alt01}>[fName], ARE YOU STILL THERE?</p>
                <p className={styles.alt02}>
                  HERE ARE SOME AMAZING OPPORTUNITIES FOR YOU!
                </p>
              </div>
            </div>
            <div className="row">
              <div className={`${styles.popup_ads_bx01_bx03} col-12`}>
                <img src={ProgrossBarImg} alt="" title="" />
              </div>
            </div>
            <div className="row">
              <div className={`${styles.popup_ads_bx01_bx04} col-12`}>
                <img src={AdsImg} alt="" title="" />
              </div>
            </div>
            <div className="row">
              <div className={`${styles.popup_ads_bx01_bx05} col-12`}>
                <p className={styles.alt01}>
                  If you won a $100 Amazon.com Gift Card, how would you spend
                  it?
                </p>
              </div>
            </div>
            <div className="row">
              <div className={`${styles.popup_ads_bx01_bx06} col-12`}>
                <div className="row justify-content-center">
                  <div className="col-lg-2 col-sm-1 col-2 d-none d-sm-block"></div>
                  <div className="col-lg-4 col-sm-5 col-6">
                    <button
                      type="button"
                      className={`${styles.popup_ads_bx01_bx06_btn01} btn btn-block`}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-lg-4 col-sm-5 col-6">
                    <button
                      type="button"
                      className={`${styles.popup_ads_bx01_bx06_btn02} btn btn-block`}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-lg-2 col-sm-1 d-none d-sm-block"></div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div
                className={`${styles.popup_ads_bx01_bx07} col-lg-4 col-sm-5 col-6`}
              >
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className={styles.popup_ads_bx01_bx07_btn01}
                >
                  CLOSE THIS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomExitPopupPage;

// <div
//   id="01HCNBJTWYMRDQ9QTTB2XMRTW4"
//   style={{
//     background: "white",
//     height: "auto",
//   }}
// >
//   <iframe
//     width="100%"
//     style={{
//       border: "0px",
//       height: "auto",
//     }}
//   ></iframe>
// </div>
