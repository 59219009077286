import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  useNavigate,
  useLocation,
} from "react-router-dom";

import AppStore from "../../config/AppStore";

import CreateAdvertiser from "./CreateAdvertiser";
import AdvertisersList from "./AdvertisersList";

import { createAdvertiserBtnClick } from "../../view_logics";

function Advertisers() {
  const [showCreateAdvertiserContent, setShowCreateAdvertiserContent] =
    useState(false);
  const handleCreateAdvertiserClick = (menuItem) => {
    setShowCreateAdvertiserContent(menuItem);
  };

  let store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "create",
    history: navigate,
  };
 
  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Advertisers</h1>
          {!showCreateAdvertiserContent ? (
            <div className="col-md-3">
              <a
                // onClick={() => handleCreateAdvertiserClick('createadvertiser')}
                onClick={() => createAdvertiserBtnClick(inputObj)}
                className="btn btn-primary float-right mb-3"
              >
                Create New Advertiser
              </a>

            </div>
          ) : (
            <div className="col-md-3">
              <a
                // onClick={() => handleCreateAdvertiserClick(false)}
                className="btn btn-primary float-right mb-3"
              >
                Back
              </a>
            </div>
          )}
          <hr />
          {showCreateAdvertiserContent ? (
            <CreateAdvertiser />
          ) : (
            <AdvertisersList />
          )}
        </div>
      </main>
    </div>
  );
}

export default Advertisers;
