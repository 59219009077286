
import { createSlice, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { rootReducer } from "./combineReducer";

class AppStore {
  //static store = configureStore({reducer: authReducer});
  static persistConfig = {
    key: "root",
    storage,
  };
  static persistedReducer = persistReducer(
    this.persistConfig,
    rootReducer
  );

  static store = configureStore({
    reducer: this.persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  });

  static sessionKeepAliveTimer = null;
  static setAppDrawerOpen = null;

  static getAppStoreInstance() {
    return this.store;
  }
}

export default AppStore;
export const persistor = persistStore(AppStore.getAppStoreInstance());
