import Constants from "../../config/Constants";
import {
  addToSessionStorage,
  clearSessionStorage,
  sendGETRequest,
  sendPOSTRequest,
  validateUserToken,
  redirectToPage
} from "../../utils/common_functions";

  var handlersObj = {};
  export const sendRequestGetAdUnis = async(dataToPost) => {
    console.log('sendRequestGetAdUnis');
    // console.log(dataToPost.advertisers);
    const url = Constants.APP_SERVER_OPTION_AD_UNIS_LIST_URL;
    const headersToPost = {
      "Content-type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + dataToPost.accessToken,
    };
    let data = {
      ...dataToPost,
    };
    delete data["accessToken"];
    console.log(dataToPost);
    console.log(headersToPost);
    sendGETRequest(
      url,
      data,
      headersToPost,
      sendRequestGetAdUnisSuccessHandler,
      sendRequestGetAdUnisErrorHandler
    );
  };

function sendRequestGetAdUnisSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
  //    handlersObj.tickerTypes(response);
  // }
 // request.dataHander(response);
  request?.loadingHandler && request?.loadingHandler(false)
  request?.dataHander && request?.dataHander(response)
}
function sendRequestGetAdUnisErrorHandler(error, request) {
  request.history('', {
    state: { from: "Add Unit", type: "error", message: error.message },
  });
}


export const createAddUnitBtnClick = (dataToPost) => {
  console.log('createPublisherBtnClick');
  console.log(dataToPost);
  // let store = AppStore.getAppStoreInstance();
  // const inputObj = {
  //   userToken: store.getState().login.accessToken,
  // };

  redirectToPage(dataToPost);
};


export const showEmbedScriptLinkHandler = (inputObj) => {
  
  // const url = APP_SERVER_OPTION_GET_ADUNIT_EMBED_SCRIPT_URL + inputObj.id;
  sendRequestToFetchAdUnitEmbedScript(inputObj);

  // inputObj.history(url);
}



export const sendRequestToFetchAdUnitEmbedScript = async (inputObj) => {
  console.log('sendRequestToFetchAdUnitDetails');
  console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_GET_ADUNIT_EMBED_SCRIPT_URL +  inputObj.id;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let data = {
    ...inputObj,
  };
  delete data["accessToken"];
  delete data["id"];
  delete data["url"];
  delete data["advertiserInfo"];
  sendGETRequest(
    url,
    data,
    headersToPost,
    sendRequesttoFetchAdUnitEmbedScriptSuccessHandler,
    sendRequesttoFetchAdUnitEmbedScriptErrorHandler
  );
  return;
};
function sendRequesttoFetchAdUnitEmbedScriptSuccessHandler(response, request) {
  console.log('sendRequesttoFetchAdvertiseDetailsSuccessHandler');
  console.log(response)
  request.openModalHandler(response);
}
function sendRequesttoFetchAdUnitEmbedScriptErrorHandler(error, request) { 
  // request.history(request.redirectUrl, {
  //   state: { from: "Add Unit", type: "error", message: error.message },
  // });
}
