import React, { useState } from "react";
var CryptoJS = require("crypto-js");

const CookiesUtility = () => {
  let secretKey = "thisisthekey";

  const [encryptedValue, setEncryptedValue] = useState('')
  const [decryptedValue, setDecryptedValue] = useState('')

  function decryptData(encryptedData, secretKey) {
    if (encryptedData.length > 0) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey, {
          mode: CryptoJS.mode.CBC, // Use CBC mode for decryption
          padding: CryptoJS.pad.Pkcs7,
        });
        const decryptedDataStr = bytes.toString(CryptoJS.enc.Utf8);
        const decryptedDataArray = JSON.parse(decryptedDataStr);
        setDecryptedValue(decryptedDataArray)
        setEncryptedValue('')
        return decryptedDataArray;
      } catch (error) {
        console.error("Error decrypting data:", error);
        return [];
      }
    }
    return [];
  }

    return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Cookies Utility</h1>

          <hr />
          <div className="">
            <form style={{display:'flex', flexDirection: 'column'}}>
              <div className="form-group">
                <label for="encryptedValue" style={{marginBottom: '10px'}}>Encrypted value</label>
                <input
                  type="text"
                  className="form-control"
                  id="encryptedValue"
                  aria-describedby="encryptedValue"
                  placeholder="Enter encrypted value"
                  onChange={(e)=> setEncryptedValue(e.target.value)}
                  value={encryptedValue ? encryptedValue : decryptedValue}
                />
              </div>

              <button
                onClick={(e) => { e.preventDefault(); decryptData(encryptedValue, secretKey)}}
                className="btn btn-primary float-right mt-3"
                style={{ width: "max-content", whiteSpace: "nowrap", alignSelf: 'flex-end' }}
              >
                Decrypt
              </button>
            </form>

          </div>

        </div>
      </main>
    </div>
  );
};

export default CookiesUtility;
