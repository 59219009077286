import Constants from "../../config/Constants";
import { sendGETRequestASync, sendPOSTRequest, sendPUTRequest } from "../../utils/common_functions";


export const sendCreateTagFormSubmitRequest = (inputObj) => {
  console.log("Sending Create TAG Request!");
  // console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_CREATE_TAG_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  dataToPost.history = inputObj.history;
  dataToPost.redirectUrl = inputObj.url;
  dataToPost.loadingHandler = inputObj?.loadingHandler
  sendPOSTRequest(
    url,
    dataToPost,
    headersToPost,
    sendCreateTagFormSubmitResponseSuccessHandler,
    sendCreateTagFormSubmitResponseErrorHandler
  );
  // inputObj.history(inputObj.url);
};

function sendCreateTagFormSubmitResponseSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("TagId")) {
  //    handlersObj.tickerTypes(response);
  // }
  console.log("sendCreateTagFormSubmitResponseSuccessHandler");
  console.log(response);
  // request.dataHander(response);
  request?.loadingHandler && request?.loadingHandler(false);

  request.history(request.redirectUrl, { state: { from: "Tag", type: 'create', message: response.message } });
}
function sendRequestTagsErrorHandler() {}

export const sendCreateTagFormSubmitResponseErrorHandler = (error, request) => {
  console.log("sendCreateTagFormSubmitResponseErrorHandler");
  console.log(error);
  request?.loadingHandler && request?.loadingHandler(false);
  request.history(request.redirectUrl, { state: { from: "Tag", type: 'error', message: error.message } });
};


export const sendRequestToFetchTagDetails = async (inputObj) => {
  console.log(
    "🚀 ~ file: Website.js:59 ~ sendRequestToFetchWebsiteDetails ~ inputObj:",
    inputObj
  );

  const url = Constants.APP_SERVER_OPTION_CREATE_TAG_URL+ `/${inputObj.id}`;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let data = {
    ...inputObj,
  };
  delete data["accessToken"];
  delete data["id"];
  delete data["url"];
  delete data["advertiserInfo"];
  // const result = await sendGETRequestASync(url, data, headersToPost);
  // return result;
};


export const sendRequestToUpdateTagDetail = (inputObj) => {
  console.log("Sending Create ADvertiser Request!");
  console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_WEBSITES_CREATE_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  let data = {
    ...dataToPost,
  };
  // delete data["userToken"];
  data.redirectUrl = inputObj.url;
  data.history = inputObj.history;
  // sendPUTRequest(url, data, headersToPost);

};
