import React from 'react'
import PublisherOfferDailyStatReportsList from './PublisherOfferDailyStatReportsList'

const PublisherOfferDailyStatReports = () => {
  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Daily stats</h1>
          <hr />
          <PublisherOfferDailyStatReportsList />
        </div>
      </main>
    </div>
  )
}

export default PublisherOfferDailyStatReports