import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";
import HeaderTemplate from "../../components/static/headerTemplate";
import SidebarTemplate from "../../components/static/sidebarTemplate";
import MainContentTemplate from "../../components/static/mainContentTemplate";
import ExitPopupTemplate from "../../components/static/exitPopupTemplate";
// import FormatHeaderTemplate from '../../../assets/select_format_template_header.html';

const CustomTemplate = ({ html, css, js }) => {
  console.log("🚀 ~ CustomTemplate ~ html:", html, js);
  useEffect(() => {
    // Execute JavaScript when the component mounts
    const scriptElement = document.createElement("script");
    scriptElement.innerHTML = js;
    document.body.appendChild(scriptElement);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(scriptElement);
    };
  }, [js]);

  return (
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <style>{css}</style>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <script>{js}</script>
    </div>
  );
};

const PreviewModal = ({ offerType = "", show, setShow, adUnitObj = null }) => {
  const handleClose = () => setShow(false);
  const baseUrl = window.location.origin;


  const [headerTitle, setHeaderTitle] = useState("");

  const onSelectTemplateHandler = (value) => {
    if (value == "header_template") {
      return <HeaderTemplate />;
    } else if (value == "sidebar_template") {
      return <SidebarTemplate/>;
    } else if (value == "main_content_template") {
      return <MainContentTemplate/>;
    } else if (value == "exit_popup_template") {
      return <ExitPopupTemplate/>;
    } else if (value == "custom_template") {
    } else {
      return <HeaderTemplate />;
    }
  };

  useEffect(() => {
    if (offerType != "") {
      if (offerType == "header_template") {
        setHeaderTitle("Header");
      }
      if (offerType == "sidebar_template") {
        setHeaderTitle("Sidebar");
      }
      if (offerType == "main_content_template") {
        setHeaderTitle("InContent");
      }
      if (offerType == "exit_popup_template") {
        setHeaderTitle("Exit Popup");
      }
      if (offerType == "custom_template" || offerType == "custom_ad_sequence") {
        setHeaderTitle("Custom");
      }
    }
  }, [offerType]);

  function decodeAndExtractText(encodedString) {
    // Decode HTML entities
    const decodedHtml = new DOMParser().parseFromString(
      encodedString,
      "text/html"
    ).body.textContent;

    return decodedHtml;
  }

  return (
    // <>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        style={{ padding: "0px !important", height: '90% !important'}}
      >
        <div style={{ height: "100%" }}>
          <Modal.Header
            style={{ padding: "12px 16px", backgroundColor: "#222e3c" }}
          >
            <Modal.Title
              style={{ color: "#91979e", fontWeight: "600" }}
            >{`${headerTitle} Preview`}</Modal.Title>
            <CloseButton onClick={handleClose} variant="white" />
          </Modal.Header>

          <Modal.Body style={{ padding: "0px" }}>
            <div
              style={{
                position: "relative",
                // paddingBottom: "75%",
                // height: "0",
                overflow: "hidden",
              }}
            >
              {offerType == "custom_template" ||
              offerType == "custom_ad_sequence" ? (
                <div className="d-flex p-3">
                  <CustomTemplate
                    html={adUnitObj?.custom_html}
                    css={adUnitObj?.custom_css}
                    js={adUnitObj?.custom_js}
                  />
                </div>
              ) : (
                // <iframe
                //   style={{
                //     pointerEvents: "none",
                //     position: "absolute",
                //     top: "0",
                //     left: "0",
                //     width: "100%",
                //     height: "100%",
                //   }}
                //   src={onSelectTemplateHandler(offerType)}
                //   aria-disabled="true"
                // ></iframe>
                <div
                  style={{
                    overflowY: 'auto',
                    maxHeight: '700px',
                    background: 'white'
                  }}
                >
                  {onSelectTemplateHandler(offerType)}
                </div>
              )}
            </div>
          </Modal.Body>
        </div>
      </Modal>
    // </>
  );
};

export default PreviewModal;
