import React, { useState, useEffect } from "react";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { useForm, useStep } from "react-hooks-helper";
import AppStore from "../../config/AppStore";
import Constants from "../../config/Constants";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const CreateAddUnit = () => {
  let store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const [websitesData, setwebsitesData] = useState(null);
  const [tagsData, setTagsData] = useState(null);
  const accessToken = store.getState().login.accessToken;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + accessToken,
  };

  useEffect(() => {
    // Replace 'yourApiUrl' with the actual URL you want to fetch data from
    const url = Constants.APP_SERVER_OPTION_WEBSITES_LIST_URL;
    fetch(url, {
      headers: headersToPost,
    })
      .then((response) => response.json())
      .then((data) => {
        // Store the fetched data in the state
        setwebsitesData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    const url1 = Constants.APP_SERVER_OPTION_TAGS_LIST_URL;
    fetch(url1, {
      headers: headersToPost,
    })
      .then((response) => response.json())
      .then((data) => {
        // Store the fetched data in the state
        setTagsData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // console.log(websitesData);
  const defaultData = {
    type: "header_template",
    custom_html: "",
    custom_css: "",
    custom_js: `//Make your own function calls here.
        function customDocumentReady() {
            // Overwrite this method for making calls after the page is loaded
        };`,
    no_of_offers: 2,
    min_ecpm: 0,
    tags: [],
    website_id: "",
    params: [""],
    encrypt_cookies_flag: 1,
    offer_rotation_time: 30,
    offer_rotation_flag: 1,
    ad_unit_name: "",
    website_name: "",
    show_on_navgiation_away: 0,
    inactivity_duration: 0,
    redirect_url: "",
  };

  const steps = [
    { id: "step1" },
    { id: "step2" },
    { id: "step3" },
    { id: "step4" },
  ];

  const [formData, setFormData] = useState(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { formData, setFormData, navigation, websitesData, tagsData };

  switch (step.id) {
    case "step1":
      return <Step1 {...props} />;
    case "step2":
      return <Step2 {...props} />;
    case "step3":
      return <Step3 {...props} />;
    case "step4":
      return <Step4 {...props} />;
  }
};

export default CreateAddUnit;
