import { ErrorMessage, Field, Formik, useFormik } from "formik";
import React, { createRef, useEffect, useState } from "react";
import AppStore from "../../config/AppStore";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Constants from "../../config/Constants";
import {
  sendOfferImageRequestToGetS3UploadData,
  sendUpdateAdvertiserOfferImagesFormSubmitRequest,
} from "../../view_logics/AdvertiserOffers/EditAdvertiserOffer";
import LoadingButton from "../../components/common/LoadingButton";
import Modal from "react-modal";
import TinyMCEComponent from "../../../components/TinyMCE";
import axios from "axios";

const ONLY_IMAGE = "only_image";
const IMAGE_TEXT = "image_text";
const ONLY_TEXT = "only_text";
const IMAGE_TEXT_BUTTON = "image_text_button";
const DEFAULT_ENABLED = true;

const CustomModal = ({ isOpen, onClose, children, title, height, width }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: 9,
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#fff",
          // padding: '20px',
          border: "1px solid #ccc",
          width: width,
          height: height,
        },
      }}
    >
      {/* <h2>{title}</h2> */}
      {children}
    </Modal>
  );
};

const ImagesInfoTab = ({
  advertiserOfferInfo,
  setAdvertiserOfferInfo,
  setActiveTab,
  offerId,
}) => {
  const [imageInfoFormLoading, setImageInfoFormLoading] = useState(false);

  const store = AppStore.getAppStoreInstance();
  const accessToken = store.getState().login.accessToken;
  const { id } = useParams();
  const AWS_HOST = Constants.AWS_ACCESS_HOST;

  const [leaderboardImage, setLeaderboardImage] = useState("");
  const [sidebarImage, setSidebarImage] = useState("");
  const [inContentImage, setInContentImage] = useState("");
  const [exitPopupImage, setExitPopupImage] = useState("");

  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    // url: 'editAdvertiser',
    history: navigate,
  };

  const formik = useFormik({
    initialValues: {
      leaderboard_image: advertiserOfferInfo?.leaderboard_image_desktop
        ? advertiserOfferInfo?.leaderboard_image_desktop.includes("https://")
          ? advertiserOfferInfo?.leaderboard_image_desktop
          : AWS_HOST + advertiserOfferInfo?.leaderboard_image_desktop
        : null,
      // leaderboard_image_mobile: "",
      leaderboard_description: advertiserOfferInfo?.leaderboard_description
        ? advertiserOfferInfo.leaderboard_description
        : "",
      is_leaderboard_type_enabled:
        advertiserOfferInfo?.is_leaderboard_type_enabled == 1,
      leaderboard_type: advertiserOfferInfo?.leaderboard_type
        ? advertiserOfferInfo.leaderboard_type
        : IMAGE_TEXT,

      sidebar_image: advertiserOfferInfo?.sidebar_image_desktop
        ? advertiserOfferInfo.sidebar_image_desktop.includes("https://")
          ? advertiserOfferInfo.sidebar_image_desktop
          : AWS_HOST + advertiserOfferInfo.sidebar_image_desktop
        : null,
      // sidebar_image_mobile: "",
      sidebar_description: advertiserOfferInfo?.sidebar_description
        ? advertiserOfferInfo.sidebar_description
        : "",
      is_sidebar_type_enabled:
        advertiserOfferInfo?.is_sidebar_type_enabled == 1,
      sidebar_type: advertiserOfferInfo?.sidebar_type
        ? advertiserOfferInfo.sidebar_type
        : IMAGE_TEXT,

      exit_popup_image: advertiserOfferInfo?.exit_popup_image_desktop
        ? advertiserOfferInfo.exit_popup_image_desktop.includes("https://")
          ? advertiserOfferInfo.exit_popup_image_desktop
          : AWS_HOST + advertiserOfferInfo.exit_popup_image_desktop
        : null,
      // exit_popup_image_mobile: "",
      exit_popup_description: advertiserOfferInfo?.exit_popup_description
        ? advertiserOfferInfo.exit_popup_description
        : "",
      is_exit_popup_type_enabled:
        advertiserOfferInfo?.is_exit_popup_type_enabled == 1,
      exit_popup_type: advertiserOfferInfo?.exit_popup_type
        ? advertiserOfferInfo.exit_popup_type
        : IMAGE_TEXT,

      incontent_image: advertiserOfferInfo?.image_desktop
        ? advertiserOfferInfo.image_desktop.includes("https://")
          ? advertiserOfferInfo.image_desktop
          : AWS_HOST + advertiserOfferInfo.image_desktop
        : null,
      // image_mobile: "",
      incontent_description: advertiserOfferInfo?.description
        ? advertiserOfferInfo.description
        : "",
      is_main_content_type_enabled:
        advertiserOfferInfo?.is_main_content_type_enabled == 1,
      main_content_type: advertiserOfferInfo?.main_content_type
        ? advertiserOfferInfo.main_content_type
        : IMAGE_TEXT,
      main_content_button_text: advertiserOfferInfo?.main_content_button_text
        ? advertiserOfferInfo.main_content_button_text
        : "Access Your Credit Report Now",
    },
  });

  const checkSupportedFormat = (value) => {
    if (!value.type) return true;
    return value && Constants.SUPPORTED_FORMATS.includes(value.type);
  };

  // const checkFileSize = (value) => {
  //   return value && value.size <= Constants.MAX_FILE_SIZE;
  // };

  async function checkAspectRatio(value, name) {
    if (!value.name) return true;
    const reader = new FileReader();
    let imageHeight;
    const aspectRatio = new Promise((resolve, reject) => {
      reader.readAsDataURL(value);
      reader.onload = function (value) {
        const img = new Image();
        img.src = value.target.result;
        img.onload = function () {
          imageHeight = this.height;
          const ratio = this.width * this.height;
          resolve(ratio);
        };
      };
    });

    let result;

    const ratio = await aspectRatio;

    switch (name) {
      case "leaderboard_image_only":
        let leaderboardOnlyImageResolution = Number(
          Constants.LEADERBOARD_IMAGE_RESOLUTION_HEIGHT_RATION *
            imageHeight *
            imageHeight
        );
        result = ratio >= leaderboardOnlyImageResolution;
        return result;
      case "leaderboard":
        let leaderboardImageResolution = Number(
          Constants.LEADERBOARD_IMAGE_RESOLUTION_WIDTH *
            Constants.LEADERBOARD_IMAGE_RESOLUTION_HEIGHT
        );
        result = ratio == leaderboardImageResolution;
        return result;
      case "sidebar":
        let sidebarImageResolution = Number(
          Constants.SIDEBAR_IMAGE_RESOLUTION_WIDTH *
            Constants.SIDEBAR_IMAGE_RESOLUTION_HEIGHT
        );
        result = ratio == sidebarImageResolution;
        return result;
      case "exitpopup":
        let exitpopupImageResolution = Number(
          Constants.EXIT_POPUP_IMAGE_RESOLUTION_WIDTH *
            Constants.EXIT_POPUP_IMAGE_RESOLUTION_HEIGHT
        );
        result = ratio == exitpopupImageResolution;
        return result;
      case "incontent":
        let incontentImageResolution = Number(
          Constants.INCONTENT_IMAGE_RESOLUTION_WIDTH *
            Constants.INCONTENT_IMAGE_RESOLUTION_HEIGHT
        );
        result = ratio == incontentImageResolution;
        return result;
      default:
        return false;
    }
  }

  const imagesTabValidation = Yup.object().shape({
    leaderboard_image: Yup.mixed().when(
      ["is_leaderboard_type_enabled", "leaderboard_type"],
      (val) => {
        if (val[0] && val[1] == ONLY_IMAGE)
          return Yup.mixed()
            .test("fileFormat", "Unsupported Format", (value) =>
              checkSupportedFormat(value)
            )
            .test(
              "fileResolution",
              `Image resolution must be more than 5 times the height`,
              async (value) =>
                await checkAspectRatio(value, "leaderboard_image_only")
            );
        else if (val[0] && val[1] == IMAGE_TEXT)
          return Yup.mixed().test("fileFormat", "Unsupported Format", (value) =>
            checkSupportedFormat(value)
          );
        else return Yup.mixed().notRequired();
      }
    ),

    leaderboard_description: Yup.string().when(
      ["is_leaderboard_type_enabled", "leaderboard_type"],
      (val, schema) => {
        if (val[0] && (val[1] == ONLY_TEXT || val[1] == IMAGE_TEXT))
          return Yup.string().required("Description is Required");
        else return Yup.string().notRequired();
      }
    ),

    sidebar_image: Yup.mixed().when(
      ["is_sidebar_type_enabled", "sidebar_type"],
      (val) => {
        if (val[0] && val[1].includes("image"))
          return Yup.mixed().test("fileFormat", "Unsupported Format", (value) =>
            checkSupportedFormat(value)
          );
        else return Yup.mixed().notRequired();
      }
    ),

    sidebar_description: Yup.string().when(
      ["is_sidebar_type_enabled", "sidebar_type"],
      (val, schema) => {
        if (val[0] && val[1] == IMAGE_TEXT)
          return Yup.string().required("Description is Required");
        else return Yup.string().notRequired();
      }
    ),

    exit_popup_image: Yup.mixed().when(
      ["is_exit_popup_type_enabled", "exit_popup_type"],
      (val) => {
        if (val[0] && val[1].includes("image"))
          return Yup.mixed().test("fileFormat", "Unsupported Format", (value) =>
            checkSupportedFormat(value)
          );
        else return Yup.mixed().notRequired();
      }
    ),

    exit_popup_description: Yup.string().when(
      ["is_exit_popup_type_enabled", "exit_popup_type"],
      (val, schema) => {
        if (val[0] && val[1] == IMAGE_TEXT)
          return Yup.string().required("Description is Required");
        else return Yup.string().notRequired();
      }
    ),

    incontent_image: Yup.mixed().when(
      ["is_main_content_type_enabled", "main_content_type"],
      (val) => {
        if (val[0] && val[1].includes("image"))
          return Yup.mixed().test("fileFormat", "Unsupported Format", (value) =>
            checkSupportedFormat(value)
          );
        else return Yup.mixed().notRequired();
      }
    ),

    incontent_description: Yup.string().when(
      ["is_main_content_type_enabled", "main_content_type"],
      (val, schema) => {
        if ((val[0] && val[1] == IMAGE_TEXT_BUTTON) || val[1] == IMAGE_TEXT)
          return Yup.string().required("Description is Required");
        else return Yup.string().notRequired();
      }
    ),

    main_content_button_text: Yup.mixed().when(
      ["is_main_content_type_enabled", "main_content_type"],
      (val) => {
        if (val[0] && val[1] == IMAGE_TEXT_BUTTON)
          return Yup.string()
            .max(40, "String must be less than or equal to 40 characters")
            .test(
              "maxWords",
              "String must have a maximum of 5 words",
              (value) => {
                console.log("value >>", value);
                if (!value) return true; // Allow empty strings
                const words = value.trim().split(/\s+/);
                return words.length <= 5;
              }
            );
        else return Yup.mixed().notRequired();
      }
    ),
  });

  const updatedState = {
    leaderboard_image: advertiserOfferInfo?.leaderboard_image_desktop
      ? advertiserOfferInfo?.leaderboard_image_desktop.includes("https://")
        ? advertiserOfferInfo?.leaderboard_image_desktop
        : AWS_HOST + advertiserOfferInfo?.leaderboard_image_desktop
      : null,
    // leaderboard_image_mobile: "",
    leaderboard_description: advertiserOfferInfo?.leaderboard_description
      ? advertiserOfferInfo.leaderboard_description
      : "",
    is_leaderboard_type_enabled:
      advertiserOfferInfo?.is_leaderboard_type_enabled === 1,
    leaderboard_type: advertiserOfferInfo?.leaderboard_type
      ? advertiserOfferInfo.leaderboard_type
      : IMAGE_TEXT,

    sidebar_image: advertiserOfferInfo?.sidebar_image_desktop
      ? advertiserOfferInfo.sidebar_image_desktop.includes("https://")
        ? advertiserOfferInfo.sidebar_image_desktop
        : AWS_HOST + advertiserOfferInfo.sidebar_image_desktop
      : null,
    // sidebar_image_mobile: "",
    sidebar_description: advertiserOfferInfo?.sidebar_description
      ? advertiserOfferInfo.sidebar_description
      : "",
    is_sidebar_type_enabled: advertiserOfferInfo?.is_sidebar_type_enabled === 1,
    sidebar_type: advertiserOfferInfo?.sidebar_type
      ? advertiserOfferInfo.sidebar_type
      : IMAGE_TEXT,

    exit_popup_image: advertiserOfferInfo?.exit_popup_image_desktop
      ? advertiserOfferInfo.exit_popup_image_desktop.includes("https://")
        ? advertiserOfferInfo.exit_popup_image_desktop
        : AWS_HOST + advertiserOfferInfo.exit_popup_image_desktop
      : null,
    // exit_popup_image_mobile: "",
    exit_popup_description: advertiserOfferInfo?.exit_popup_description
      ? advertiserOfferInfo.exit_popup_description
      : "",
    is_exit_popup_type_enabled:
      advertiserOfferInfo?.is_exit_popup_type_enabled === 1,
    exit_popup_type: advertiserOfferInfo?.exit_popup_type
      ? advertiserOfferInfo.exit_popup_type
      : IMAGE_TEXT,

    incontent_image: advertiserOfferInfo?.image_desktop
      ? advertiserOfferInfo.image_desktop.includes("https://")
        ? advertiserOfferInfo.image_desktop
        : AWS_HOST + advertiserOfferInfo.image_desktop
      : null,
    // image_mobile: "",
    incontent_description: advertiserOfferInfo?.description
      ? advertiserOfferInfo.description
      : "",
    is_main_content_type_enabled:
      advertiserOfferInfo?.is_main_content_type_enabled === 1,
    main_content_type: advertiserOfferInfo?.main_content_type
      ? advertiserOfferInfo.main_content_type
      : IMAGE_TEXT,
    main_content_button_text: advertiserOfferInfo?.main_content_button_text
      ? advertiserOfferInfo.main_content_button_text
      : "Access Your Credit Report Now",
  };

  const updateImagesInfoTab = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let serializedData = {
      offerId: null,
      leaderboard: {
        description: "",
        leaderboard_type: "image_text",
        is_leaderboard_type_enabled: 0,
      },
      sidebar: {
        description: "",
        sidebar_type: "image_text",
        is_sidebar_type_enabled: 0,
      },
      incontent: {
        description: "",
        main_content_type: "image_text",
        is_main_content_type_enabled: 0,
        main_content_button_text: "Access Your Credit Report Now",
      },
      exitpopup: {
        description: "",
        exit_popup_type: "image_text",
        is_exit_popup_type_enabled: 0,
      },
    };
    let arrayOfFiles = [];
    for (let [name, value] of formData.entries()) {
      if (name && value) {
        if (name.includes("leaderboard")) {
          if (name.includes("description")) {
            serializedData.leaderboard.description = value;
          } else if (name == "is_leaderboard_type_enabled") {
            serializedData.leaderboard[name] = value == "true" ? 1 : 0;
          } else if (name == "leaderboard_type") {
            serializedData.leaderboard[name] = value;
          }
        } else if (name.includes("sidebar")) {
          if (name.includes("description")) {
            serializedData.sidebar.description = value;
          } else if (name == "is_sidebar_type_enabled") {
            serializedData.sidebar[name] = value == "true" ? 1 : 0;
          } else if (name == "sidebar_type") {
            serializedData.sidebar[name] = value;
          }
        } else if (
          name.includes("incontent") ||
          name.includes("main_content")
        ) {
          if (name.includes("description")) {
            serializedData.incontent.description = value;
          } else if (name == "is_main_content_type_enabled") {
            serializedData.incontent[name] = value == "true" ? 1 : 0;
          } else if (name == "main_content_type") {
            serializedData.incontent[name] = value;
          } else if (name == "main_content_button_text") {
            serializedData.incontent[name] = value;
          }
        } else if (name.includes("exit_popup")) {
          if (name.includes("description")) {
            serializedData.exitpopup.description = value;
          } else if (name == "is_exit_popup_type_enabled") {
            serializedData.exitpopup[name] = value == "true" ? 1 : 0;
          } else if (name == "exit_popup_type") {
            serializedData.exitpopup[name] = value;
          }
        } else {
        }
      }
      if (typeof value == "object") {
        if (value.name != "") {
          arrayOfFiles.push({ name: name, value });
        }
      }
    }

    let promises = [];
    arrayOfFiles.forEach((obj, ind) => {
      promises.push(sendOfferImageRequestToGetS3UploadData(obj, accessToken));
    });

    let responses = await Promise.all(promises);
    if (responses) {
      responses.forEach((obj, ind) => {
        if (obj?.postData?.name?.includes("leaderboard")) {
          serializedData.leaderboard.fileName = obj.presignedData.fields.key;
        }
        if (obj?.postData?.name?.includes("sidebar")) {
          serializedData.sidebar.fileName = obj.presignedData.fields.key;
        }
        if (obj?.postData?.name?.includes("incontent")) {
          serializedData.incontent.fileName = obj.presignedData.fields.key;
        }
        if (obj?.postData?.name?.includes("exit_popup")) {
          serializedData.exitpopup.fileName = obj.presignedData.fields.key;
        }
      });
    }

    if (id != undefined) {
      serializedData.offerId = Number(id);
    }

    delete serializedData["_token"];
    const inputObj = {
      accessToken: accessToken,
      url: "/offers",
      history: navigate,
      dataToPost: serializedData,
      setActiveTab: setActiveTab,
      loadingHandler: setImageInfoFormLoading,
    };
    setImageInfoFormLoading(true);

    sendUpdateAdvertiserOfferImagesFormSubmitRequest(inputObj);
  };

  const formInputChanged = (e) => {
    let { name, value } = e.target;
    advertiserOfferInfo[name] =
      value == "true" || value == "false" ? (value == "true" ? 1 : 0) : value;
    setAdvertiserOfferInfo(advertiserOfferInfo);
  };

  const [modalHeight, setModalHeight] = useState(250);
  const [modalWidth, setModalWidth] = useState(300);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leaderPreview, setLeaderPreview] = useState(false);

  const handleOpenModal = () => {
    setLeaderPreview(true);
    if (advertiserOfferInfo.leaderboard_type === ONLY_IMAGE) {
      setModalHeight(137);
      setModalWidth(720);
    }
    if (advertiserOfferInfo.leaderboard_type === IMAGE_TEXT) {
      setModalHeight(147);
      setModalWidth(570);
    }
    if (advertiserOfferInfo.leaderboard_type === ONLY_TEXT) {
      setModalHeight(147);
      setModalWidth(570);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setLeaderPreview(false);
    setIsModalOpen(false);
  };

  const [isModalOpenSidebar, setIsModalOpenSidebar] = useState(false);
  const [sidebarPreview, setSidebarPreview] = useState(false);

  const handleOpenModalSidebar = () => {
    setSidebarPreview(true);
    if (advertiserOfferInfo.sidebar_type === ONLY_IMAGE) {
      setModalHeight(242);
      setModalWidth(343);
    }
    if (advertiserOfferInfo.sidebar_type === IMAGE_TEXT) {
      setModalHeight(142);
      setModalWidth(430);
    }
    setIsModalOpenSidebar(true);
  };

  const handleCloseModalSidebar = () => {
    setSidebarPreview(false);
    setIsModalOpenSidebar(false);
  };

  const [isModalOpenInContent, setIsModalOpenInContent] = useState(false);
  const [inContentPreview, setInContentPreview] = useState(false);

  const handleOpenModalInContent = () => {
    setInContentPreview(true);
    if (advertiserOfferInfo.main_content_type === ONLY_IMAGE) {
      setModalHeight(242);
      setModalWidth(343);
    }
    if (advertiserOfferInfo.main_content_type === IMAGE_TEXT) {
      setModalHeight(430);
      setModalWidth(350);
    }
    if (advertiserOfferInfo.main_content_type === IMAGE_TEXT_BUTTON) {
      setModalHeight(470);
      setModalWidth(350);
    }
    setIsModalOpenInContent(true);
  };

  const handleCloseModalInContent = () => {
    setInContentPreview(false);
    setIsModalOpenInContent(false);
  };

  // const [initialValues, setInitialValues] = useState({
  //   leaderboard_description: "",
  //   sidebar_description: "",
  //   incontent_description: "",
  //   exit_popup_description: "",
  // });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`/api/offers/${offerId}`);
  //       setInitialValues((prevValues) => ({
  //         ...prevValues,
  //         leaderboard_description: response.data.leaderboard_description,
  //       }));
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [offerId]);

  return (
    <div className="tab-pane active" id="tab-1" role="tabpanel">
      {!advertiserOfferInfo?.id ? (
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Configs</h5>

            <div
              className="alert alert-danger alert-outline alert-dismissible"
              role="alert"
            >
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
              <div className="alert-icon">
                <i className="far fa-fw fa-bell"></i>
              </div>
              <div className="alert-message">
                <strong>Warning!</strong> Please fill out and save the basic
                information first!
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Formik
        initialValues={formik.initialValues}
        onSubmit={formik.handleSubmit}
        validationSchema={imagesTabValidation}
        enableReinitialize
      >
        {({
          handleSubmit,
          errors,
          isValid,
          values,
          setFieldValue,
          touched,
        }) => {
          let shouldValid = true;
          shouldValid = !(advertiserOfferInfo?.id && isValid);
          console.log("isValid >>>", isValid, shouldValid, errors);
          const leaderboardImageStyles =
            values.leaderboard_type === ONLY_IMAGE
              ? { width: "728px", height: "95px" }
              : { width: "180px", height: "140px" };
          return (
            <form onSubmit={updateImagesInfoTab}>
              <input
                type="hidden"
                name="_token"
                value="C5Oe8oLVgFK6wo5dqcWEYVrjvHXO1yrmMsiMvqnf"
              />
              <div className="card-body">
                <div className="card position-relative">
                  <div className="card-header">
                    <label className="form-check form-check-inline">
                      <Field
                        type="checkbox"
                        id="is_leaderboard_type_enabled"
                        name="is_leaderboard_type_enabled"
                        className="form-check-input"
                        onInput={formInputChanged}
                      />
                      <span className="form-check-label ml-10">
                        Leader Board
                      </span>
                      {/* <span>
                          <h5 className="card-title">Leader Board</h5>
                        </span> */}
                    </label>
                    {}
                  </div>
                  {values?.is_leaderboard_type_enabled && (
                    <div className="card-body">
                      <div className="mb-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleOpenModal}
                        >
                          Preview
                        </button>
                      </div>
                      <CustomModal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        width={modalWidth}
                        height={modalHeight}
                      >
                        {values.leaderboard_type === ONLY_IMAGE && (
                          <>
                            <img
                              src={
                                leaderboardImage
                                  ? leaderboardImage
                                  : updatedState.leaderboard_image
                              }
                              alt=""
                              className="img-fluid"
                              style={{
                                width: "728px",
                                height: "95px",
                                boxShadow: "1px 1px 10px #888888",
                              }}
                            />
                          </>
                        )}

                        {values.leaderboard_type === IMAGE_TEXT && (
                          <>
                            <div className="flex">
                              <div
                                className="bg-white p-1 border border-gray-300"
                                style={{ boxShadow: "1px 1px 10px #888888" }}
                              >
                                <div class="row align-items-center">
                                  <div class="col-4">
                                    <img
                                      src={
                                        leaderboardImage
                                          ? leaderboardImage
                                          : updatedState.leaderboard_image
                                      }
                                      alt=""
                                      className="img-fluid"
                                      style={{ width: "182px", height: "95px" }}
                                    />
                                  </div>
                                  <div className="col-8">
                                    <h6>
                                      <a href="">
                                        {advertiserOfferInfo.heading}
                                      </a>
                                    </h6>
                                    <img
                                      src="/images/icn_arw02.png"
                                      style={{
                                        position: "absolute",
                                        top: "50px",
                                        right: "30px",
                                      }}
                                    />
                                    <p style={{ width: "85%" }}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            values.leaderboard_description,
                                        }}
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {values.leaderboard_type === ONLY_TEXT && (
                          <div
                            style={{
                              height: "105px",
                              width: "528px",
                              padding: "15px",
                              boxShadow: "1px 1px 10px #888888",
                            }}
                          >
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: values.leaderboard_description,
                                }}
                              />
                            </p>
                          </div>
                        )}
                      </CustomModal>
                      <div className="mb-3">
                        <label className="form-check form-check-inline">
                          <Field
                            type="radio"
                            id="leaderboard_type_only_image"
                            name="leaderboard_type"
                            className="form-check-input"
                            value={ONLY_IMAGE}
                            onInput={(e) => {
                              setFieldValue("leaderboard_image", null);
                              setLeaderboardImage("");
                              formInputChanged(e);
                            }}
                          />
                          <span className="form-check-label ml-10">
                            Image Only
                          </span>
                        </label>
                        <label className="form-check form-check-inline">
                          <Field
                            type="radio"
                            id="leaderboard_type_image_text"
                            name="leaderboard_type"
                            className="form-check-input"
                            value={IMAGE_TEXT}
                            onInput={(e) => {
                              setFieldValue("leaderboard_image", null);
                              setLeaderboardImage("");
                              formInputChanged(e);
                            }}
                          />
                          <span className="form-check-label ml-10">
                            Image + Text
                          </span>
                        </label>
                        <label className="form-check form-check-inline">
                          <Field
                            type="radio"
                            id="leaderboard_type_only_text"
                            name="leaderboard_type"
                            className="form-check-input"
                            value={ONLY_TEXT}
                            onInput={formInputChanged}
                          />
                          <span className="form-check-label ml-10">
                            Text Only
                          </span>
                        </label>
                      </div>
                      {(values?.leaderboard_type == ONLY_IMAGE ||
                        values?.leaderboard_type == IMAGE_TEXT) && (
                        <div className="mb-3 col-md-6 d-flex flex-column">
                          <label
                            className="form-label d-flex justify-content-between d-flex justify-content-between"
                            htmlFor="leaderboard_image"
                          >
                            Leaderboard image
                            <p className="" style={{ fontSize: "12px" }}>
                              {values?.leaderboard_type === ONLY_IMAGE
                                ? `Image resolution must be more than 5 times the height`
                                : `Image resolution should be (200 X 120 , 336 x 280,  300 X 250)`}
                            </p>
                          </label>
                          <Field
                            type="file"
                            id="leaderboard_image"
                            name="leaderboard_image"
                            accept="image/*"
                            className="form-control"
                            value={undefined}
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file) {
                                setFieldValue("leaderboard_image", file);
                                const url = URL.createObjectURL(file);
                                if (url) {
                                  setLeaderboardImage(url);
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            name="leaderboard_image"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                          <div
                            className="position-absolute"
                            style={{ top: "10%", right: "6%" }}
                          >
                            <img
                              src={
                                leaderboardImage
                                  ? leaderboardImage
                                  : updatedState.leaderboard_image
                              }
                              alt=""
                              className="img-fluid"
                              style={leaderboardImageStyles}
                            />
                          </div>
                        </div>
                      )}
                      {/* <div className="row"> */}
                      {(values?.leaderboard_type == ONLY_TEXT ||
                        values?.leaderboard_type == IMAGE_TEXT) && (
                        //   {/* <Field
                        //     as="textarea"
                        //     id="leaderboard_description"
                        //     name="leaderboard_description"
                        //     rows={4}
                        //     cols={50}
                        //     className="form-control"
                        //     required
                        //     onInput={formInputChanged}
                        //   /> */}

                        <div className="mb-3 col-md-12">
                          <label
                            className="form-label d-flex justify-content-between"
                            htmlFor="leaderboard_description"
                          >
                            Description
                          </label>
                          <TinyMCEComponent
                            id="leaderboard_description"
                            name="leaderboard_description"
                            initialValue={values.leaderboard_description}
                            onEditorChange={(content) => {
                              advertiserOfferInfo.leaderboard_description =
                                content;
                              values.leaderboard_description = content;
                            }}
                          />
                          <ErrorMessage
                            name="leaderboard_description"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      )}
                      {/* </div> */}
                    </div>
                  )}
                </div>
                <hr />
                <div className="card position-relative">
                  <div className="card-header">
                    <label className="form-check form-check-inline">
                      <Field
                        type="checkbox"
                        id="is_sidebar_type_enabled"
                        name="is_sidebar_type_enabled"
                        className="form-check-input"
                        onInput={formInputChanged}
                      />
                      <span className="form-check-label ml-10">Side Bar</span>
                      {/* <h5 className="card-title">Side Bar</h5> */}
                    </label>
                  </div>
                  {values?.is_sidebar_type_enabled && (
                    <div className="card-body">
                      <div className="mb-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleOpenModalSidebar}
                        >
                          Preview
                        </button>
                      </div>
                      <CustomModal
                        isOpen={isModalOpenSidebar}
                        onClose={handleCloseModalSidebar}
                        width={modalWidth}
                        height={modalHeight}
                      >
                        {values.sidebar_type === ONLY_IMAGE && (
                          <>
                            <img
                              src={
                                sidebarImage
                                  ? sidebarImage
                                  : updatedState.sidebar_image
                              }
                              alt=""
                              className="img-fluid"
                              style={{
                                width: "300px",
                                height: "200px",
                                boxShadow: "1px 1px 10px #888888",
                              }}
                            />
                            <div
                              className="sidebar-play-icon"
                              style={{
                                position: "absolute",
                                backgroundColor: "white",
                                right: "21px",
                                top: "20px",
                                padding: "5px 10px 5px 13px",
                              }}
                            >
                              <i className="fa fa-play fa-1x icon play-icon"></i>
                              <span
                                className="sidebar-play-xoptinads"
                                style={{ color: "#0000ff" }}
                              >
                                Xoptin Ads
                              </span>
                            </div>
                          </>
                        )}

                        {values.sidebar_type === IMAGE_TEXT && (
                          <div className="flex">
                            <div
                              className="bg-white p-1 border border-gray-300"
                              style={{ boxShadow: "1px 1px 10px #888888" }}
                            >
                              <div class="row align-items-center">
                                <div class="col-4">
                                  <img
                                    src={
                                      sidebarImage
                                        ? sidebarImage
                                        : updatedState.sidebar_image
                                    }
                                    alt=""
                                    className="img-fluid"
                                    style={{ width: "150px", height: "90px" }}
                                  />
                                </div>
                                <div className="col-8">
                                  <h6>
                                    <a href="">{advertiserOfferInfo.heading}</a>
                                  </h6>
                                  <p>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: values.sidebar_description,
                                      }}
                                    />
                                  </p>
                                </div>
                                {/* <div className="col-2">
                                <img src="https://leadgen-prod.s3.amazonaws.com/xoptin_ads/icn_arw.png" />
                              </div> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </CustomModal>
                      <div className="mb-3">
                        <label className="form-check form-check-inline">
                          <Field
                            type="radio"
                            id="sidebar_image_only"
                            name="sidebar_type"
                            className="form-check-input"
                            value={ONLY_IMAGE}
                            onInput={formInputChanged}
                          />
                          <span className="form-check-label ml-10">
                            Image Only
                          </span>
                        </label>
                        <label className="form-check form-check-inline">
                          <Field
                            type="radio"
                            id="sidebar_image_text"
                            name="sidebar_type"
                            className="form-check-input"
                            value={IMAGE_TEXT}
                            onInput={formInputChanged}
                          />
                          <span className="form-check-label ml-10">
                            Image + Text
                          </span>
                        </label>
                      </div>
                      {(values?.sidebar_type === ONLY_IMAGE ||
                        values?.sidebar_type === IMAGE_TEXT) && (
                        <div className="mb-3 col-md-6 d-flex flex-column">
                          <label
                            className="form-label d-flex justify-content-between d-flex justify-content-between"
                            htmlFor="sidebar_image"
                          >
                            Sidebar image
                            <p
                              className=""
                              style={{ fontSize: "12px" }}
                            >{`Image resolution should be (300 X 250 ,   300 x 200)`}</p>
                          </label>
                          <Field
                            type="file"
                            id="sidebar_image"
                            name="sidebar_image"
                            accept="image/*"
                            className="form-control"
                            value={undefined}
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              const reader = new FileReader();
                              if (file) {
                                setFieldValue("sidebar_image", file);
                                const url = URL.createObjectURL(file);
                                if (url) {
                                  setSidebarImage(url);
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            name="sidebar_image"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                          <div
                            className="position-absolute"
                            style={{ top: "10%", right: "6%" }}
                          >
                            <img
                              src={
                                sidebarImage
                                  ? sidebarImage
                                  : updatedState.sidebar_image
                              }
                              alt=""
                              className="img-fluid"
                              style={{ width: "180px", height: "140px" }}
                            />
                          </div>
                        </div>
                      )}
                      {/* <div className="row"> */}
                      {values?.sidebar_type === IMAGE_TEXT && (
                        <div className="mb-3 col-md-12">
                          <label
                            className="form-label d-flex justify-content-between"
                            htmlFor="sidebar_description"
                          >
                            Description
                          </label>
                          {/* <Field
                            as="textarea"
                            id="sidebar_description"
                            name="sidebar_description"
                            rows={4}
                            cols={50}
                            className="form-control"
                            required
                            onInput={formInputChanged}
                          /> */}
                          <TinyMCEComponent
                            id="sidebar_description"
                            name="sidebar_description"
                            initialValue={values.sidebar_description}
                            onEditorChange={(content) => {
                              values.sidebar_description = content;
                              advertiserOfferInfo.sidebar_description = content;
                            }}
                          />
                          <ErrorMessage
                            name="sidebar_description"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      )}
                      {/* </div> */}
                    </div>
                  )}
                </div>
                <hr />
                <div className="card position-relative">
                  <div className="card-header">
                    <label className="form-check form-check-inline">
                      <Field
                        type="checkbox"
                        id="is_main_content_type_enabled"
                        name="is_main_content_type_enabled"
                        className="form-check-input"
                        onInput={formInputChanged}
                      />
                      <span className="form-check-label ml-10">In Content</span>
                      {/* <h5 className="card-title">In Content</h5> */}
                    </label>
                  </div>
                  {values?.is_main_content_type_enabled && (
                    <div className="card-body">
                      <div className="mb-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleOpenModalInContent}
                        >
                          Preview
                        </button>
                      </div>
                      <CustomModal
                        isOpen={isModalOpenInContent}
                        onClose={handleCloseModalInContent}
                        width={modalWidth}
                        height={modalHeight}
                      >
                        {values.main_content_type === ONLY_IMAGE && (
                          <>
                            <img
                              src={
                                inContentImage
                                  ? inContentImage
                                  : updatedState.incontent_image
                              }
                              alt=""
                              className="img-fluid"
                              style={{
                                width: "300px",
                                height: "200px",
                                boxShadow: "1px 1px 10px #888888",
                              }}
                            />
                          </>
                        )}

                        {(values.main_content_type === IMAGE_TEXT ||
                          values.main_content_type === IMAGE_TEXT_BUTTON) && (
                          <div
                            className="d-flex justify-content-center bg-white p-4 border border-gray-300"
                            style={{ boxShadow: "1px 1px 10px #888888" }}
                          >
                            <div className="text-center">
                              <div>
                                <h6>
                                  <a href="">{advertiserOfferInfo.heading}</a>
                                </h6>
                              </div>
                              <div style={{ minHeight: "100px" }}>
                                <p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: values.incontent_description,
                                    }}
                                  />
                                </p>
                              </div>
                              <img
                                src={
                                  inContentImage
                                    ? inContentImage
                                    : updatedState.incontent_image
                                }
                                alt=""
                                className="img-fluid "
                                style={{ width: "300px", height: "200px" }}
                              />
                              {values.main_content_type ===
                                IMAGE_TEXT_BUTTON && (
                                <button
                                  type="button"
                                  className="btn btn-primary mt-3 "
                                >
                                  {values.main_content_button_text.trim() &&
                                  values.main_content_type === IMAGE_TEXT_BUTTON
                                    ? values.main_content_button_text
                                    : "Access Your Credit Report Now"}
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </CustomModal>
                      <div className="mb-3">
                        <label className="form-check form-check-inline">
                          <Field
                            type="radio"
                            id="main_content_image_only"
                            name="main_content_type"
                            className="form-check-input"
                            value={ONLY_IMAGE}
                            onInput={formInputChanged}
                          />
                          <span className="form-check-label ml-10">
                            Image Only
                          </span>
                        </label>
                        <label className="form-check form-check-inline">
                          <Field
                            type="radio"
                            id="main_content_image_text"
                            name="main_content_type"
                            className="form-check-input"
                            value={IMAGE_TEXT}
                            onInput={formInputChanged}
                          />
                          <span className="form-check-label ml-10">
                            Image + Text
                          </span>
                        </label>
                        <label className="form-check form-check-inline">
                          <Field
                            type="radio"
                            id="image_text_button"
                            name="main_content_type"
                            className="form-check-input"
                            value={IMAGE_TEXT_BUTTON}
                            onInput={formInputChanged}
                          />
                          <span className="form-check-label ml-10">
                            Image + Text + Button
                          </span>
                        </label>
                      </div>
                      {(values?.main_content_type === ONLY_IMAGE ||
                        values?.main_content_type === IMAGE_TEXT ||
                        values?.main_content_type === IMAGE_TEXT_BUTTON) && (
                        <div className="mb-3 col-md-6 d-flex flex-column">
                          <label
                            className="form-label d-flex justify-content-between d-flex justify-content-between"
                            htmlFor="incontent_image"
                          >
                            In Content image
                            <p
                              className=""
                              style={{ fontSize: "12px" }}
                            >{`Image resolution should be (300 X 250 ,   300 x 200)`}</p>
                          </label>
                          <Field
                            type="file"
                            id="incontent_image"
                            name="incontent_image"
                            accept="image/*"
                            className="form-control"
                            value={undefined}
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              const reader = new FileReader();
                              if (file) {
                                setFieldValue("incontent_image", file);
                                const url = URL.createObjectURL(file);
                                if (url) {
                                  setInContentImage(url);
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            name="incontent_image"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                          <div
                            className="position-absolute"
                            style={{ top: "10%", right: "6%" }}
                          >
                            <img
                              src={
                                inContentImage
                                  ? inContentImage
                                  : updatedState.incontent_image
                              }
                              alt=""
                              className="img-fluid"
                              style={{ width: "180px", height: "140px" }}
                            />
                          </div>
                        </div>
                      )}
                      {/* <div className="row"> */}
                      {(values?.main_content_type === IMAGE_TEXT ||
                        values?.main_content_type === IMAGE_TEXT_BUTTON) && (
                        <div className="mb-3 col-md-12">
                          <label
                            className="form-label d-flex justify-content-between"
                            htmlFor="incontent_description"
                          >
                            Description
                          </label>
                          {/* <Field
                            as="textarea"
                            id="incontent_description"
                            name="incontent_description"
                            rows={4}
                            cols={50}
                            className="form-control"
                            required
                            onInput={formInputChanged}
                          /> */}
                          <TinyMCEComponent
                            id="incontent_description"
                            name="incontent_description"
                            initialValue={values.incontent_description}
                            onEditorChange={(content) => {
                              values.incontent_description = content;
                              advertiserOfferInfo.description = content;
                            }}
                          />
                          <ErrorMessage
                            name="incontent_description"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      )}
                      {values?.main_content_type === IMAGE_TEXT_BUTTON && (
                        <div className="mb-3 col-md-12">
                          <label
                            className="form-label d-flex justify-content-between"
                            htmlFor="main_content_button_text"
                          >
                            Button Text
                          </label>
                          <Field
                            id="main_content_button_text"
                            name="main_content_button_text"
                            className="form-control"
                            required
                            onInput={formInputChanged}
                          />
                          <ErrorMessage
                            name="main_content_button_text"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      )}
                      {/* </div> */}
                    </div>
                  )}
                </div>
                <hr />
                <div className="card position-relative">
                  <div className="card-header">
                    <label className="form-check form-check-inline">
                      <Field
                        type="checkbox"
                        id="is_exit_popup_type_enabled"
                        name="is_exit_popup_type_enabled"
                        className="form-check-input"
                        onInput={formInputChanged}
                      />
                      <span className="form-check-label ml-10">Exit Popup</span>
                      {/* <h5 className="card-title">Exit Popup</h5> */}
                    </label>
                  </div>
                  {values?.is_exit_popup_type_enabled && (
                    <div className="card-body">
                      <div className="mb-3">
                        <label className="form-check form-check-inline">
                          <Field
                            type="radio"
                            id="exit_popup_image_only"
                            name="exit_popup_type"
                            className="form-check-input"
                            value={ONLY_IMAGE}
                            onInput={formInputChanged}
                          />
                          <span className="form-check-label ml-10">
                            Image Only
                          </span>
                        </label>
                        <label className="form-check form-check-inline">
                          <Field
                            type="radio"
                            id="exit_popup_image_text"
                            name="exit_popup_type"
                            className="form-check-input"
                            value={IMAGE_TEXT}
                            onInput={formInputChanged}
                          />
                          <span className="form-check-label ml-10">
                            Image + Text
                          </span>
                        </label>
                      </div>
                      {(values?.exit_popup_type == ONLY_IMAGE ||
                        values?.exit_popup_type == IMAGE_TEXT) && (
                        <div className="mb-3 col-md-6 d-flex flex-column">
                          <label
                            className="form-label d-flex justify-content-between"
                            htmlFor="exit_popup_image"
                          >
                            Exit popup image
                            <p
                              className=""
                              style={{ fontSize: "12px" }}
                            >{`Image resolution should be (600 X 400,    500 X 300)`}</p>
                          </label>
                          <Field
                            type="file"
                            id="exit_popup_image"
                            name="exit_popup_image"
                            accept="image/*"
                            className="form-control"
                            value={undefined}
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              const reader = new FileReader();
                              if (file) {
                                setFieldValue("exit_popup_image", file);
                                const url = URL.createObjectURL(file);
                                if (url) {
                                  setExitPopupImage(url);
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            name="exit_popup_image"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                          <div
                            className="position-absolute"
                            style={{ top: "10%", right: "6%" }}
                          >
                            <img
                              src={
                                exitPopupImage
                                  ? exitPopupImage
                                  : updatedState.exit_popup_image
                              }
                              alt=""
                              className="img-fluid"
                              style={{ width: "180px", height: "140px" }}
                            />
                          </div>
                        </div>
                      )}
                      {/* <div className="row"> */}
                      {values?.exit_popup_type == IMAGE_TEXT && (
                        <div className="mb-3 col-md-12">
                          <label
                            className="form-label"
                            htmlFor="exit_popup_description"
                          >
                            Description
                          </label>
                          {/* <Field
                            as="textarea"
                            id="exit_popup_description"
                            name="exit_popup_description"
                            rows={4}
                            cols={50}
                            className="form-control"
                            required
                            onInput={formInputChanged}
                          /> */}
                          <TinyMCEComponent
                            id="exit_popup_description"
                            name="exit_popup_description"
                            initialValue={values.exit_popup_description}
                            onEditorChange={(content) => {
                              values.exit_popup_description = content;
                              advertiserOfferInfo.exit_popup_description =
                                content;
                            }}
                          />
                          <ErrorMessage
                            name="exit_popup_description"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      )}
                      {/* </div> */}
                    </div>
                  )}
                </div>
              </div>
              <hr></hr>
              <div className="form-group row mb-0">
                <div className="col-md-6 offset-md-11 mb-2">
                  {imageInfoFormLoading ? (
                    <LoadingButton name="Save" />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={shouldValid}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ImagesInfoTab;
