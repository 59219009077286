import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Router, useNavigate } from 'react-router-dom';

import AppStore from '../../config/AppStore';

import {createPublisherBtnClick} from '../../view_logics/Publishers'

import CreatePublisher from './CreatePublisher';
import PublishersList from './PublishersList';
import { useDispatch, useSelector } from 'react-redux';
import { setUserType } from '../../view_logics/LoginSlice';

const Publishers = () => {

   const [showCreatePublishersContent, setShowCreatePublishersContent] = useState(false);
   const handleCreatePublishersClick = (menuItem) => {
      setShowCreatePublishersContent(menuItem);
   };
  const {userId, username, userType } = useSelector((state) => state?.login);


   let store = AppStore.getAppStoreInstance();
   const navigate = useNavigate(); // we can pass navigation to clickhandler
   const inputObj = {
      accessToken: store.getState().login.accessToken,
      url: 'create',
      history: navigate
   };
   let dispatch = useDispatch()


  
   return (
      <div className="">
         <main className="content">
            <div className="container-fluid p-0">
               <h1 >Publishers</h1>
               {!showCreatePublishersContent ?
                  <div className="col-md-3">
                     <a
                        // onClick={() => handleCreatePublishersClick('createPublishers')}
                        onClick={() => createPublisherBtnClick(inputObj)}
                        className="btn btn-primary float-right mb-3"
                     >
                        Create New Publishers
                     </a>
                  </div>
                  :
                  <div className="col-md-3">
                     <a
                        // onClick={() => handleCreatePublishersClick(false)}
                        className="btn btn-primary float-right mb-3"
                     >
                        Back
                     </a>
                  </div>
               }
               <hr />
               {showCreatePublishersContent ? <CreatePublisher /> : <PublishersList />}
            </div>
         </main>
      </div>

   );
};

export default Publishers;