import { createSlice } from "@reduxjs/toolkit";
import Constants from "../config/Constants";

import {
  addToSessionStorage,
  clearSessionStorage,
  validateUserToken,
} from "../utils/common_functions";

const initStateValues = Constants.AppStoreState;
initStateValues.error = null;

const loginSlice = createSlice({
  name: "logins",
  initialState: initStateValues,
  reducers: {
    loginAction(state, action) {
      console.log("loginReducer", action);
      console.log('state',state)
      executeLoginReducer(state, action);

      addToSessionStorage(
        ["userToken", "userId", 'isStaff', 'name', 'userType', "mainUser"],
        [state.userToken, state.userId, state.isStaff, state.name, state.userType, state.userType]
      );
      const inputObj = { userToken: state.userToken };

    },
    logoutAction(state, action) {
      executeLogoutReducer(state, action);
      clearSessionStorage();

    },
    setServerErrorAction(state, action) {
      executeSetServerErrorReducer(state, action)
    },
    setUserType: (state, action)=> {
      executeUserTypeChangeReducer(state, action)
    }
  },
});

const executeUserTypeChangeReducer = (state, action) => {
  state.userType = action.payload?.type;
  state.publisherId = action.payload?.id;
  state.publisherCompany = action.payload?.publisherCompany;
  state.publisherName = action.payload?.publisherName
};

const executeSetServerErrorReducer = (state, action) => {
  state.error = action.payload.error;
};
const executeLoginReducer = (state, action) => {
	state.currentExecutedAction = action.type;
  state.accessToken = action.payload.accessToken;
  state.refreshToken = action.payload.refreshToken
  state.isStaff = action.payload.isStaff;
  state.username = action.payload.name
  state.userType = action.payload.userType
  state.userId = action.payload.userId
  state.mainUser = action.payload.userType
  state.publisherId = action.payload.userType == "publisher" ? action.payload.userId : null
  state.error = null
}

const executeLogoutReducer = (state, action) => {
	state.currentExecutedAction = action.type;
	state.userToken = state.userId = state.isStaff = null;
  state.accessToken = null;
  state.refreshToken = null
  state.userType = null
  state.publisherId = null
  state.publisherCompany = null
  state.publisherName = null
  state.username = null;
  state.mainUser = null;
}



// Extract the action creators object and the reducer
const { actions, reducer } = loginSlice;
// Extract and export each action creator by name
export const { loginAction, logoutAction, setServerErrorAction, setUserType } = actions;
// Export the reducer, either as a default or named export
export default reducer;
