import React, { useEffect, useState } from "react";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import {
  sendCreateTagFormSubmitRequest,
  sendRequestToFetchTagDetails,
  sendRequestToUpdateTagDetail,
} from "../../view_logics/Tags/CreateTag";
import { useNavigate, useParams } from "react-router-dom";
import AppStore from "../../config/AppStore";
import * as Yup from "yup";
import Spinner from "../../components/common/Spinner";
import LoadingButton from "../../components/common/LoadingButton";

const CreateTag = () => {
  const [tagsFormLoading, setTagsFormLoading] = useState(false);

  let store = AppStore.getAppStoreInstance();
  let accessToken = store.getState().login.accessToken;
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const { id } = useParams();
  console.log("🚀 ~ file: CreateTag.js:12 ~ CreateTag ~ id:", id);
  const formik = useFormik({
    initialValues: {
      name: "",
    },
  });

  const [tagById, setTagById] = useState({});

  useEffect(() => {
    if (id != undefined) {
      const fetchData = async () => {
        let res = await sendRequestToFetchTagDetails({
          id: id,
          accessToken: accessToken,
        });
        if (res) {
          setTagById({ ...res });
        }
      };
      fetchData();
    }
  }, [id]);

  const saveTag = (event) => {
    event.preventDefault();
    console.log("saveTag");
    // console.log(event.target.name.value);
    const formData = new FormData(event.target);
    const serializedData = {};

    for (let [name, value] of formData.entries()) {
      serializedData[name] = value;
    }

    if(id){
        serializedData["id"] = id;
    }

    const inputObj = {
      accessToken: accessToken,
      url: "/tags",
      history: navigate,
      dataToPost: serializedData,
      loadingHandler: setTagsFormLoading,
      
    };
    setTagsFormLoading(true);
    console.log("🚀 ~ file: CreateTag.js:58 ~ saveTag ~ inputObj:", inputObj)
    if (id != undefined) {
      sendRequestToUpdateTagDetail(inputObj);
    } else {
      sendCreateTagFormSubmitRequest(inputObj);
    }
  };

  const { errors } = formik;

  return (
    <div className="position-relative">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>{id ? "Edit Tag" : "Create New Tag"}</h1>
          <Formik
            initialValues={tagById || formik.initialValues}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Tag name is Required"),
            })}
            enableReinitialize
          >
            {({ handleSubmit, isValid }) => (
              <form onSubmit={saveTag}>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Basic Info</h5>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      {/* <div className="mb-3 col-md-6">
                                    <label className="form-label" htmlFor="Name">ID</label>
                                    <Field
                                        type="text"
                                        id="name"
                                        name="Name"
                                        className="form-control"
                                        required
                                    />
                                    <ErrorMessage name="Name" component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                </div> */}
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="name">
                          Name
                        </label>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          className={
                            errors.name ? "form-control" : "form-control"
                          }
                          required
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr></hr>
                <div className="form-group row mb-0">
                  <div className="d-flex justify-content-end">
                    
                    {tagsFormLoading ? (
                        <LoadingButton name="Save" />
                      ) : (
                        <button
                        disabled={!isValid}
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </main>

      {id && !tagById && <Spinner/>}
    </div>
  );
};

export default CreateTag;
