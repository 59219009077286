import { React, useRef, useEffect, useState } from "react";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import Editor from "../../components/common/Editor";
import { useParams } from "react-router-dom";
import Spinner from "../../components/common/Spinner";
import PreviewModal from "./PreviewModal";
import TinyMceEditor from "./TinyMceEditor";
import * as Yup from "yup";
import PrefillIcon from "../../../assets/images/prefill-icon.png";
import Constants from "../../config/Constants";
import CustomAdSequencePage from "../../components/static/customAdSequencePage";
import { createRoot } from "react-dom/client";
import Modal from "react-bootstrap/Modal";

const Step1 = (props) => {
  const { id } = useParams();
  const formData = props.formData;
  const [offerType, setofferType] = useState(
    props?.formData?.type ? props?.formData?.type : ""
  );

  console.log("🚀 ~ Step1 ~ offerType:", offerType);
  const [adUnitById, setAdUnitById] = useState({
    redirect_url: "",
    type: "",
  });

  const formik = useFormik({
    initialValues: {
      redirect_url: props?.formData?.redirect_url
        ? props?.formData?.redirect_url
        : "",
      // type: "",
    },
  });
  const [customHtmlTag, setCustomHtmlTag] = useState(false);

  const [isValidCustom, setIsValidCustom] = useState(false);

  const handleRadioChange = (event) => {
    setofferType(event.target.value);
    props.formData.type = event.target.value;
    if (event.target.value == "custom_template") {
      setCustomHtmlTag(true);
    } else {
      props?.setFormData((prev) => ({
        ...prev,
        custom_html: "",
        custom_css: "",
        custom_js: `//Make your own function calls here.
        function customDocumentReady() {
            // Overwrite this method for making calls after the page is loaded
        };`,
      }));
      setCustomHtmlTag(false);
    }
  };

  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (
      props?.formData?.type == "custom_template" ||
      (props?.formData?.type == "custom_ad_sequence" && customHtmlTag == false)
    ) {
      setCustomHtmlTag(true);
      setofferType(props?.formData?.type);
    } else {
      setofferType(props?.formData?.type);
    }
  }, [props?.formData]);

  const nextHandler = (values) => {
    if (values.redirect_url == "") {
      let element = document.getElementById("redirect_url");
      if (element) {
        return element.focus();
      }
    }

    if (values.redirect_url) {
      props?.setFormData((prev) => ({
        ...prev,
        redirect_url: values.redirect_url,
      }));
    }
    // props.setFormData()
    props.navigation.next();
  };

  useEffect(() => {
    if (offerType == "custom_template" || offerType == "custom_ad_sequence") {
      if (
        props?.formData?.custom_js?.includes("function customDocumentReady()")
      ) {
        setIsValidCustom(false);
      } else {
        setIsValidCustom(true);
      }
    } else {
      setIsValidCustom(false);
    }
  }, [offerType, props.formData]);

  const templateChangeHandler = (e) => {
    console.log("🚀 ~ templateChangeHandler ~ e:", e.target.value);
    props.setFormData((prevConfigObject) => ({
      ...prevConfigObject,
      type: e.target.value,
    }));
  };

  const htmlCssPrefillHHandler = () => {
    props?.setFormData((prev) => ({
      ...props.formData,
      custom_html: Constants.PREFILL_EXAMPLE_HTML_TEMPLATE,
      custom_css: Constants.PREFILL_EXAMPLE_CSS_TEMPLATE,
    }));
  };

  const handleLiveExample = () => {
    console.log("🚀 ~ handleLiveExample ~ offerType:", offerType);
    if (offerType == "custom_template") {
      window.open("/custom_ad_full", "_blank");
    }
    if (offerType == "custom_ad_sequence") {
      window.open("/custom_ad_sequence", "_blank");
    }

    if (offerType == "header_template") {
      window.open("/custom_header", "_blank");
    }
    if (offerType == "sidebar_template") {
      window.open("/custom_sidebar", "_blank");
    }
    if (offerType == "main_content_template") {
      window.open("/custom_main_content", "_blank");
    }
    if (offerType == "exit_popup_template") {
      window.open("/custom_exit_popup", "_blank");
    }
  };

  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>{id ? "Edit Add Unit '" + formData.name + "'" : "Create New Add Unit"}</h1>
          <div className="tab-content">
            <div className="tab-pane active" id="tab-1" role="tabpanel">
              <Formik
                initialValues={props?.formData || formik.initialValues}
                validationSchema={Yup.object().shape({
                  redirect_url: Yup.string()
                    .matches(
                      /^(?!:\/\/)([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.)+[a-zA-Z]{2,}$/,
                      "Invalid domain format. Example: example.com"
                    )
                    .required("Domain is required")
                    .strict(true),
                  // publisherId: Yup.string().required("Publisher Id Required"),
                })}
                enableReinitialize
              >
                {({ handleSubmit, errors, isValid, values }) => {
                  return (
                    <form name="form1Data">
                      <input
                        type="hidden"
                        name="_token"
                        value="C5Oe8oLVgFK6wo5dqcWEYVrjvHXO1yrmMsiMvqnf"
                      />
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title">STEP 1 - Offer Format</h5>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="mb-3 col-md-6">
                              <fieldset className="mb-3">
                                <div className="row">
                                  {/* <label className="col-form-label col-sm-2 text-sm-end pt-sm-0">Radios</label> */}
                                  <div className="col-sm-12">
                                    <div className="w-100 mb-3 d-flex flex-row gap-5">
                                      <div className="w-50 d-flex">
                                        <label className="col-form-label text-sm-end">
                                          Header
                                        </label>
                                        <div className="ms-auto">
                                          <label className="col-form-label text-sm-end">
                                            <input
                                              type="radio"
                                              name="type"
                                              value="header_template"
                                              className="form-check-input"
                                              checked={
                                                formData.type ===
                                                "header_template"
                                                  ? true
                                                  : false
                                              }
                                              onChange={handleRadioChange}
                                            />
                                            <span className="form-check-label ml-5">
                                              Yes
                                            </span>
                                          </label>
                                        </div>
                                      </div>

                                      <div className="w-50 d-flex gap-4">
                                        <label className="col-form-label text-sm-end">
                                          Sidebar
                                        </label>
                                        <div className="ms-auto">
                                          <label className="col-form-label text-sm-end">
                                            <input
                                              type="radio"
                                              name="type"
                                              value="sidebar_template"
                                              className="form-check-input"
                                              checked={
                                                formData.type ===
                                                "sidebar_template"
                                                  ? true
                                                  : false
                                              }
                                              onChange={handleRadioChange}
                                            />
                                            <span className="form-check-label ml-5">
                                              Yes
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="w-100 mb-3 d-flex flex-row gap-5">
                                      <div className="w-50 d-flex gap-4">
                                        <label className="col-form-label text-sm-end">
                                          Main Content
                                        </label>
                                        <div className="ms-auto">
                                          <label className="col-form-label text-sm-end">
                                            <input
                                              type="radio"
                                              name="type"
                                              value="main_content_template"
                                              className="form-check-input"
                                              checked={
                                                formData.type ===
                                                "main_content_template"
                                              }
                                              onChange={handleRadioChange}
                                            />
                                            <span className="form-check-label ml-5">
                                              Yes
                                            </span>
                                          </label>
                                        </div>
                                      </div>

                                      <div className="w-50 d-flex gap-4">
                                        <label className="col-form-label text-sm-end">
                                          Exit Popup
                                        </label>
                                        <div className="ms-auto">
                                          <label className="col-form-label text-sm-end">
                                            <input
                                              type="radio"
                                              name="type"
                                              value="exit_popup_template"
                                              className="form-check-input"
                                              checked={
                                                formData.type ===
                                                "exit_popup_template"
                                                  ? true
                                                  : false
                                              }
                                              onChange={handleRadioChange}
                                            />
                                            <span className="form-check-label ml-5">
                                              Yes
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="w-50 d-flex pe-4">
                                      <label className="col-form-label text-sm-end">
                                        Custom Format
                                      </label>
                                      <div className="ms-auto">
                                        <label className="col-form-label text-sm-end">
                                          <input
                                            type="radio"
                                            name="ad_format"
                                            value="custom_template"
                                            className="form-check-input"
                                            checked={
                                              formData.type ===
                                                "custom_template" ||
                                              formData.type ==
                                                "custom_ad_sequence"
                                            }
                                            onChange={handleRadioChange}
                                          />
                                          <span className="form-check-label ml-5">
                                            Yes
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                            {customHtmlTag && (
                              <div className="d-flex flex-column gap-3">
                                <div className="d-flex align-items-start gap-3">
                                  <div className="d-flex flex-column w-50 gap-1 align-items-start">
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="advertiserId"
                                    >
                                      Custom Template
                                    </label>
                                    <select
                                      name="type"
                                      id="type"
                                      className="form-select"
                                      value={props?.formData?.type}
                                      onChange={templateChangeHandler}
                                    >
                                      <option value="custom_template">
                                        Full Page Template
                                      </option>
                                      <option value="custom_ad_sequence">
                                        Ad Sequence Template
                                      </option>
                                    </select>
                                  </div>
                                  {(props.formData.type == "custom_template" ||
                                    props.formData.type ==
                                      "custom_ad_sequence") && (
                                    <div className="d-flex flex-column w-50 gap-1 align-items-start">
                                      <label
                                        className="form-label mb-0"
                                        htmlFor="advertiserId"
                                      >
                                        Redirect URL:{" "}
                                        <i
                                          className="fa-solid fa-circle-info"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title={`This is the URL to which user will be redirected at the end of the offers sequence`}
                                        ></i>
                                      </label>
                                      <div className="w-100">
                                        <Field
                                          type="text"
                                          id="redirect_url"
                                          name="redirect_url"
                                          className={"form-control"}
                                          required
                                        />
                                        <ErrorMessage
                                          name="redirect_url"
                                          component="div"
                                          className="invalid-feedback"
                                          style={{ display: "block" }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="d-flex align-items-center gap-3">
                                  <img
                                    src={PrefillIcon}
                                    style={{ width: "20px" }}
                                    alt=""
                                  />
                                  <button
                                    type="button"
                                    onClick={htmlCssPrefillHHandler}
                                    className="border-0 rounded-2 p-1"
                                    style={{
                                      background: "#3b7ddd",
                                      color: "white",
                                    }}
                                  >
                                    Prefill with an example template
                                  </button>
                                  {(offerType == "custom_template" ||
                                    offerType == "custom_ad_sequence") && (
                                    <button
                                      className="btn btn-primary border-0 rounded-2 p-1"
                                      onClick={handleLiveExample}
                                      type="button"
                                    >
                                      Live Example
                                    </button>
                                  )}
                                </div>

                                <div className="d-flex flex-column gap-1">
                                  <label style={{ fontWeight: "bold" }}>
                                    Html
                                  </label>
                                  {/* <Editor props={props} /> */}
                                  <TinyMceEditor props={props} />
                                </div>
                              </div>
                            )}
                            <div className="mb-3 col-md-6">
                              <PreviewModal
                                offerType={offerType}
                                show={showPreview}
                                setShow={setShowPreview}
                                adUnitObj={props?.formData}
                              />
                              {/* </span> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex gap-2">
                        <button
                          type="submit"
                          className="btn btn-success btn-lg"
                          onClick={(e) => {
                            e.preventDefault();

                            nextHandler(values);
                          }}
                          disabled={
                            isValidCustom || Object.keys(errors).length !== 0
                          }
                        >
                          Next
                        </button>

                        <button
                          className="btn btn-primary btn-lg"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowPreview(true);
                          }}
                        >
                          Launch Preview
                        </button>

                        {!(offerType == "custom_template" ||
                          offerType == "custom_ad_sequence") && (
                          <button
                            type="button"
                            className="btn btn-primary btn-lg"
                            onClick={handleLiveExample}
                          >
                            Live Example
                          </button>
                        )}
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </main>

      {id && !props.formData?.id && <Spinner />}
    </div>
  );
};

export default Step1;
