import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import AppStore from "../../config/AppStore";
import {
  redirectToLoginPageIfTokenIsInvalid,
  redirectToDashboardPageIfTokenIsValid,
  isAccessTokenValid,
} from "../../utils/common_functions";

import { loginBtnClickHandler } from "../../view_logics/Login";
import Alert from "../../components/common/Alert";
import LoadingButton from "../../components/common/LoadingButton";

const Login = () => {
  const [loginFormLoading, setLoginFormLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: true,
  });

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const [loginError, setLoginError] = useState("");

  const formik = useFormik({
    initialValues: {
      // Initial values for form fields
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      // Handle form submission
      // ...
    },
  });
  const { errors } = formik;

  const [apiErrors, setApiErrors] = useState(null);
  console.log("🚀 ~ Login ~ apiErrors:", apiErrors);

  const navigate = useNavigate(); // we can pass navigation to clickhandler
  // console.log(isAccessTokenValid)
  const inputObj = {
    history: navigate,
    accessToken: useSelector(isAccessTokenValid),
    errorHandler: setApiErrors,
    loadingHandler: setLoginFormLoading,
  };
  // console.log(inputObj)
  useEffect(() => {
    //const error = useSelector(readServerError);
    console.log("isUserAuthenticated", isUserAuthenticated);
    redirectToDashboardPageIfTokenIsValid(inputObj);
  }, [isUserAuthenticated]);
  let store = AppStore.getAppStoreInstance();

  useEffect(() => {
    if (apiErrors != null) {
      setTimeout(() => {
        setApiErrors(null);
      }, 2500);
    }
  }, [apiErrors]);

  return (
    <>
      {apiErrors != null && (
        <div
          className="position-absolute d-flex w-100 justify-content-center"
          //   style={{ top: alertType == "danger" ? "100px" : "140px" }}
        >
          <Alert message={apiErrors?.message} alertType={"danger"} />
        </div>
      )}
      <main className="d-flex w-100 h-100">
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-4">
                  <h1 className="h2">Welcome Xoptin Ads</h1>
                  <p className="lead">Sign in to your account to continue</p>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      <div className="d-flex justify-content-center">
                        <img
                          src="xoptin_logo.png"
                          alt="Charles Hall"
                          className="img-fluid mb-5"
                          // width="280"
                          // height="132"
                        />
                      </div>
                      <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={Yup.object({
                          email: Yup.string()
                            .email("Invalid email address")
                            .required("This field is required"),
                          password: Yup.string().required(
                            "This field is required"
                          ),
                        })}
                        onSubmit={(values, actions) => {
                          // Handle form submission
                          console.log("Login Submit handler called!");
                          setLoginFormLoading(true)
                          values.setIsUserAuthenticated =
                            setIsUserAuthenticated;
                          console.log(values);
                          values.history = inputObj.history;
                          values.errorHandler = inputObj.errorHandler;
                          values.loadingHandler = inputObj.loadingHandler;

                          loginBtnClickHandler(values);
                        }}
                      >
                        <Form className="text-start">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <Field
                              type="email"
                              id="email"
                              name="email"
                              className="form-control form-control-lg"
                              required
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {store.getState().login.error}
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Password</label>
                            <Field
                              type="password"
                              id="password"
                              name="password"
                              className="form-control form-control-lg"
                              required
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                            <small>
                              <a href="" onClick={(e)=> {e.preventDefault(); navigate('/reset-password')}}>
                                Forgot password?
                              </a>
                            </small>
                          </div>

                          <div className="d-flex justify-content-center mt-3">
                            {loginFormLoading ? (
                              <LoadingButton size='btn-lg' name="Sign in"/>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary"
                              >
                                Sign in
                              </button>
                            )}
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
