import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useAlertOnNavigation = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [message, setShowMessage] = useState('');
  const [alertType, setAlertType] = useState('')
  const navigate = useNavigate();
  const location = useLocation()
  console.log("🚀 ~ useAlertOnNavigation ~ location:", location)

  useEffect(() => {
    console.log("🚀 ~ handleNavigation ~ location:", location, navigate)
    const handleNavigation = () => {
      if (location && location?.state?.from) {
        if (location?.state?.type === 'create' || location?.state?.type === 'edit') {
          setAlertType('success')
        }

        if(location?.state?.type === 'error'){
          setAlertType('danger')
        }
        
        setShowMessage(location?.state?.message)
        setShowAlert(true);
        window.scrollTo({ top: 0, behavior: 'smooth' })
        navigate(location.pathname, { replace: true });
        setTimeout(() => {
          setShowAlert(false);
        }, 2500);
      }
    };

    handleNavigation();

    return () => {
      // Clean-up or additional logic if needed
    };
  }, [location, navigate]);

  return { showAlert, alertType, message };
};

export default useAlertOnNavigation;