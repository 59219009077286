import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import SimpleBar from "simplebar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isUserTokenValid } from "../utils/common_functions";
import LogoutDropdown from "./common/LogoutDropdown";

// let sideBarItems = [];

export const dashboardLinkClickHandler = (event, input) => {
  input.history("/dashboard");
};

export const advertisersLinkClickHandler = (event, input) => {
  input.history("/advertiser");
};

export const advertiserOffersLinkClickHandler = (event, input) => {
  input.history("/offers");
};

export const publishersLinkClickHandler = (event, input) => {
  input.history("/publishers");
};

export const websitesLinkClickHandler = (event, input) => {
  input.history("/websites");
};

export const tagsLinkClickHandler = (event, input) => {
  input.history("/tags");
};

export const addUnisLinkClickHandler = (event, input) => {
  input.history("/adunits");
};

export const cookiesUtilityClickHandler = (event, input) => {
  input.history("/cookiesutility");
};

export const offerStatReportsHandler = (event, input) => {
  input.history("/reports/offer_stats_report");
};

export const publisherOfferStatReportsHandler = (event, input) => {
  input.history("/reports/publisher_offer_stats");
};

export const publisherOfferDailyStatReportsHandler = (event, input) => {
  input.history("/reports/publisher_offer_daily_stats");
};

export const monthlyRevenueReportsHandler = (event, input) => {
  input.history("/reports/monthly_revenue_report");
};

export const monthlyRevenueReportsAddHandler = (event, input) => {
  input.history("/reports/monthly_revenue_report_add");
};

export const getSideBarMenuItemsToRender = (inputObj, location, userType) => {
  console.log("🚀 ~ getSideBarMenuItemsToRender ~ userType:", userType);
  return (
    <>
      <li
        className={`sidebar-item ${
          location.pathname.includes("dashboard") ? "active" : ""
        }`}
      >
        <a
          data-bs-target="#dashboards"
          // data-bs-toggle="collapse"
          className="sidebar-link"
          // onClick={() => onMenuItemClick('dashboard')}
          onClick={(event) => {
            dashboardLinkClickHandler(event, inputObj);
          }}
        >
          <i className="align-middle" data-feather="sliders"></i>
          <span className="align-middle">Dashboard</span>
        </a>
      </li>

      {userType === "admin" && (
        <li
          className={`sidebar-item ${
            location.pathname.includes("advertiser") ||
            location.pathname.includes("offers")
              ? "active"
              : ""
          }`}
        >
          <a data-bs-toggle="collapse" className="sidebar-link collapsed">
            <i className="align-middle" data-feather="layout"></i>
            <span className="align-middle">Adverstiser settings</span>
          </a>
          <ul
            id="pages"
            className="sidebar-dropdown list-unstyled collapse show"
            data-bs-parent="#sidebar"
          >
            <li
              className={`sidebar-item ${
                location.pathname.includes("advertiser") ? "active" : ""
              }`}
            >
              <a
                data-bs-target="#pages"
                // data-bs-toggle="collapse"
                className="sidebar-link collapsed"
                onClick={(event) => {
                  advertisersLinkClickHandler(event, inputObj);
                }}
              >
                <i className="align-middle" data-feather="layout"></i>
                <span className="align-middle">Advertiser</span>
              </a>
            </li>

            <li
              className={`sidebar-item ${
                location.pathname.includes("offers") ? "active" : ""
              }`}
            >
              <a
                data-bs-target="#pages"
                // data-bs-toggle="collapse"
                className="sidebar-link collapsed"
                onClick={(event) => {
                  advertiserOffersLinkClickHandler(event, inputObj);
                }}
              >
                <i className="align-middle" data-feather="layout"></i>
                <span className="align-middle">Advertiser Offers</span>
              </a>
            </li>
          </ul>
        </li>
      )}

      <li
        className={`sidebar-item ${
          location.pathname.includes("publishers") ||
          location.pathname.includes("websites") ||
          location.pathname.includes("adunits")
            ? "active"
            : ""
        }`}
      >
        <a data-bs-toggle="collapse" className="sidebar-link collapsed">
          <i className="align-middle" data-feather="layout"></i>
          <span className="align-middle">Publisher settings</span>
        </a>
        <ul
          id="pages"
          className="sidebar-dropdown list-unstyled collapse show"
          data-bs-parent="#sidebar"
        >
          {userType === "admin" && (
            <li
              className={`sidebar-item ${
                location.pathname.includes("publishers") ? "active" : ""
              }`}
            >
              <a
                onClick={(event) => {
                  publishersLinkClickHandler(event, inputObj);
                }}
                className="sidebar-link"
              >
                Publisher
              </a>
            </li>
          )}

          <li
            className={`sidebar-item ${
              location.pathname.includes("websites") ? "active" : ""
            }`}
          >
            <a
              onClick={(event) => {
                websitesLinkClickHandler(event, inputObj);
              }}
              className="sidebar-link"
            >
              Websites
            </a>
          </li>
          <li
            className={`sidebar-item ${
              location.pathname.includes("adunits") ? "active" : ""
            }`}
          >
            <a
              data-bs-target="#pages"
              // data-bs-toggle="collapse"
              className="sidebar-link collapsed"
              onClick={(event) => {
                addUnisLinkClickHandler(event, inputObj);
              }}
            >
              <span className="align-middle">Ad Units</span>
            </a>
          </li>
        </ul>
      </li>

      {userType === "admin" && (
        <li
          className={`sidebar-item ${
            location.pathname.includes("tags") ? "active" : ""
          }`}
        >
          <a
            data-bs-target="#pages"
            // data-bs-toggle="collapse"
            className="sidebar-link collapsed"
            onClick={(event) => {
              tagsLinkClickHandler(event, inputObj);
            }}
          >
            <i className="align-middle" data-feather="layout"></i>
            <span className="align-middle">Tags</span>
          </a>
        </li>
      )}

      <li
        className={`sidebar-item ${
          location.pathname.includes("reports") ? "active" : ""
        }`}
      >
        <a data-bs-toggle="collapse" className="sidebar-link collapsed">
          <i className="align-middle" data-feather="layout"></i>
          <span className="align-middle">
            {userType === "admin" ? "Reports" : "Publisher reports"}
          </span>
        </a>
        <ul
          id="pages"
          className="sidebar-dropdown list-unstyled collapse show"
          data-bs-parent="#sidebar"
        >
          {userType === "admin" && (
            <li
              className={`sidebar-item ${
                location.pathname.includes("offer_stats_report") ? "active" : ""
              }`}
            >
              <a
                onClick={(event) => {
                  offerStatReportsHandler(event, inputObj);
                }}
                className="sidebar-link"
              >
                Offers Stats Reports
              </a>
            </li>
          )}

          {/* {userType !== "admin" && (
            <li
              className={`sidebar-item ${
                location.pathname.includes("publisher_offer_stats")
                  ? "active"
                  : ""
              }`}
            >
              <a
                onClick={(event) => {
                  publisherOfferStatReportsHandler(event, inputObj);
                }}
                className="sidebar-link"
              >
                Day wise report
              </a>
            </li>
          )} */}

          {userType !== "admin" && (
            <li
              className={`sidebar-item ${
                location.pathname.includes("publisher_offer_daily_stats")
                  ? "active"
                  : ""
              }`}
            >
              <a
                onClick={(event) => {
                  publisherOfferDailyStatReportsHandler(event, inputObj);
                }}
                className="sidebar-link"
              >
                Daily Stats
              </a>
            </li>
          )}

          {/* {userType != "admin" && ( */}
            <li
              className={`sidebar-item ${
                location.pathname.includes("/monthly_revenue_report") &&
                !location.pathname.includes("/monthly_revenue_report_add")
                  ? "active"
                  : ""
              }`}
            >
              <a
                onClick={(event) => {
                  monthlyRevenueReportsHandler(event, inputObj);
                }}
                className="sidebar-link"
              >
                Monthly Revenue Reports
              </a>
            </li>
          {/* )} */}

          {/* {userType != "admin" && ( */}
          {userType !== "admin" && (
            <li
              className={`sidebar-item ${
                location.pathname.includes("monthly_revenue_report_add")
                  ? "active"
                  : ""
              }`}
            >
              <a
                onClick={(event) => {
                  monthlyRevenueReportsAddHandler(event, inputObj);
                }}
                className="sidebar-link"
              >
                Monthly Revenue Reports [ New ]
              </a>
            </li>
          )}
          {/* )} */}
        </ul>
      </li>

      {userType === "admin" && (
        <li
          className={`sidebar-item ${
            location.pathname.includes("cookiesutility") ? "active" : ""
          }`}
        >
          <a
            data-bs-target="#pages"
            // data-bs-toggle="collapse"
            className="sidebar-link collapsed"
            onClick={(event) => {
              cookiesUtilityClickHandler(event, inputObj);
            }}
          >
            <i className="align-middle" data-feather="layout"></i>
            <span className="align-middle">Cookies Utility</span>
          </a>
        </li>
      )}
    </>
  );
};

const initialize = () => {
  initializeSimplebar();
  initializeSidebarCollapse();
};

const initializeSimplebar = () => {
  const simplebarElement = document.getElementsByClassName("js-simplebar")[0];

  if (simplebarElement) {
    const simplebarInstance = new SimpleBar(simplebarElement);

    /* Recalculate simplebar on sidebar dropdown toggle */
    const sidebarDropdowns = document.querySelectorAll(
      ".js-sidebar [data-bs-parent]"
    );

    sidebarDropdowns.forEach((link) => {
      link.addEventListener("shown.bs.collapse", () => {
        simplebarInstance.recalculate();
      });
      link.addEventListener("hidden.bs.collapse", () => {
        simplebarInstance.recalculate();
      });
    });
  }
};

const initializeSidebarCollapse = () => {
  const sidebarElement = document.getElementsByClassName("js-sidebar")[0];
  const sidebarToggleElement =
    document.getElementsByClassName("js-sidebar-toggle")[0];

  if (sidebarElement && sidebarToggleElement) {
    sidebarToggleElement.addEventListener("click", () => {
      sidebarElement?.classList?.toggle("collapsed");

      sidebarElement?.addEventListener("transitionend", () => {
        window.dispatchEvent(new Event("resize"));
      });
    });
  }
};

function Sidebar() {
  const location = useLocation();
  // let store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  let inputObj = {
    userToken: useSelector(isUserTokenValid),
    history: navigate,
  };

  const { username, userType, publisherCompany, publisherName } = useSelector(
    (state) => state?.login
  );
  let sideBarMenuItemsToRender = getSideBarMenuItemsToRender(
    inputObj,
    location,
    userType
  );

  useEffect(() => {
    initialize();
  }, []);

  const [openDropdown, setOpenDropDown] = useState(false);
  const customClickAreaRef = useRef(null);
  const logoutDropDownHandler = () => {
    setOpenDropDown(!openDropdown);
  };

  return (
    <nav id="sidebar" className="sidebar js-sidebar">
      <div className="sidebar-content js-simplebar">
        <a className="sidebar-brand" to="index.html">
          <span className="sidebar-brand-text align-middle">
            Xoptin Ads
            <sup>
              {/* <small className="badge bg-primary text-uppercase">Pro</small> */}
            </sup>
          </span>
          <svg
            className="sidebar-brand-icon align-middle"
            width="32px"
            height="32px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="miter"
            color="#FFFFFF"
            style={{ marginLeft: "-3px" }}
          >
            <path d="M12 4L20 8.00004L12 12L4 8.00004L12 4Z"></path>
            <path d="M20 12L12 16L4 12"></path>
            <path d="M20 16L12 20L4 16"></path>
          </svg>
        </a>

        <div className="sidebar-user position-relative">
          <div
            className="d-flex justify-content-center cursor-pointer"
            ref={customClickAreaRef}
            onClick={logoutDropDownHandler}
          >
            <div className="flex-shrink-0">
              {/* <img
                src="avatar.png"
                className="avatar img-fluid rounded me-1"
                alt="Charles Hall"
              /> */}
              <img
                src="/avatar.png"
                alt="Charles Hall"
                className="img-fluid rounded-circle"
                width="40"
                height="40"
              />
            </div>
            <div className="flex-grow-1 ps-2">
              <a
                className="sidebar-user-title dropdown-toggle"
                to="#"
                data-bs-toggle="dropdown"
              >
                {publisherName ? publisherName : username ? username : ""}
              </a>

              <div className="sidebar-user-subtitle">
                {userType === "admin"
                  ? "Admin"
                  : publisherCompany != null
                  ? publisherCompany
                  : ""}
              </div>
            </div>
          </div>
          <span style={{ position: "absolute", left: "60px" }}>
            <LogoutDropdown
              open={openDropdown}
              setOpen={setOpenDropDown}
              customClickAreaRef={customClickAreaRef}
            />
          </span>
        </div>

        <ul className="sidebar-nav">{sideBarMenuItemsToRender}</ul>
      </div>
    </nav>
  );
}

export default Sidebar;
