import React, { useRef, useState } from "react";
import LogoutDropdown from "./common/LogoutDropdown";
import { useDispatch, useSelector } from "react-redux";
import { setUserType } from "../view_logics/LoginSlice";
import { useNavigate } from "react-router-dom";
import ApiImplementationModal from "./apiImplementationModal";
// import RedCircle from "../../../public/images/circle-red.svg"

const Navbar = () => {
  const { username, userType, mainUser, publisherName } = useSelector(
    (state) => state?.login
  );
  const navigate = useNavigate();
  console.log("🚀 ~ Navbar ~ mainUser:", mainUser);
  const dispatch = useDispatch();

  const [openDropdown, setOpenDropDown] = useState(false);
  const customClickAreaRef = useRef(null);
  const logoutDropDownHandler = () => {
    setOpenDropDown(!openDropdown);
  };

  const changeUserType = () => {
   // let type = userType === "admin" ? "publisher" : "admin";
    dispatch(setUserType({ type: "admin", id: null, publisherCompany: null }));
    navigate("/dashboard");
  };
  const [openApiModal, setOpenApiModal] = useState(false)

  const closeApiModal = () =>{
    setOpenApiModal(false)
  }

  return (
    <>
    {openApiModal && <ApiImplementationModal isOpen={openApiModal} onClose={closeApiModal}/>}

    <nav className="navbar navbar-expand navbar-light navbar-bg">
      {/* <a className="sidebar-toggle js-sidebar-toggle">
        <i className="hamburger align-self-center"></i>
      </a> */}

      {/* <form className="d-none d-sm-inline-block">
        <div className="input-group input-group-navbar">
          <input
            type="text"
            className="form-control"
            placeholder="Search…"
            aria-label="Search"
          />
          <button className="btn" type="button">
            <i className="align-middle" data-feather="search"></i>
          </button>
        </div>
      </form> */}

      <ul className="navbar-nav d-none d-lg-flex">
        {/* <li className="nav-item px-2 dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="megaDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Mega Menu
          </a>
        </li>

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="resourcesDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Resources
          </a>
        </li> */}
      </ul>

      <div className="navbar-collapse collapse">
        <ul className="navbar-nav navbar-align">
          {/* <li className="nav-item dropdown">
            <a
              className="nav-icon dropdown-toggle"
              href="#"
              id="alertsDropdown"
              data-bs-toggle="dropdown"
            >
              <div className="position-relative">
                <Bell className="align-middle" />
                <span className="indicator">4</span>
              </div>
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-icon dropdown-toggle"
              href="#"
              id="messagesDropdown"
              data-bs-toggle="dropdown"
            >
              <div className="position-relative">
                <i className="align-middle" data-feather="message-square"></i>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
              aria-labelledby="messagesDropdown"
            >
              <div className="dropdown-menu-header">
                <div className="position-relative">4 New Messages</div>
              </div>
              <div className="list-group">
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <img
                        src="avatar.png"
                        className="avatar img-fluid rounded-circle"
                        alt="Vanessa Tucker"
                      />
                    </div>
                    <div className="col-10 ps-2">
                      <div className="text-dark">Vanessa Tucker</div>
                      <div className="text-muted small mt-1">
                        Nam pretium turpis et arcu. Duis arcu tortor.
                      </div>
                      <div className="text-muted small mt-1">15m ago</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </li> */}
          {/* <li className="nav-item dropdown">
            <a
              className="nav-flag dropdown-toggle"
              href="#"
              id="languageDropdown"
              data-bs-toggle="dropdown"
            >
              <img src="us.png" alt="English" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="languageDropdown"
            >
              <a className="dropdown-item" href="#">
                <img
                  src="img/flags/us.png"
                  alt="English"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">English</span>
              </a>
              <a className="dropdown-item" href="#">
                <img
                  src="img/flags/es.png"
                  alt="Spanish"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">Spanish</span>
              </a>
              <a className="dropdown-item" href="#">
                <img
                  src="img/flags/ru.png"
                  alt="Russian"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">Russian</span>
              </a>
              <a className="dropdown-item" href="#">
                <img
                  src="img/flags/de.png"
                  alt="German"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">German</span>
              </a>
            </div>
          </li> */}

          <li className="nav-item">
            <a className="nav-icon js-fullscreen d-none d-lg-block" href="#">
              <div className="position-relative">
                <i className="align-middle" data-feather="maximize"></i>
              </div>
            </a>
          </li>
          <li className="nav-item dropdown position-relative">
            <div className="d-flex gap-1 align-items-center">
              {mainUser === "admin" && userType === "publisher" && (
                <div
                  className="d-flex gap-2 pe-3 cursor-pointer"
                  onClick={changeUserType}
                >
                  <img
                    src="circle-red.svg"
                    alt="Charles Hall"
                    className="img-fluid rounded-circle"
                    width="14"
                    height="14"
                  />
                  <p className="mb-0 fw-bold">{`Stop Impersonating as ${
                    publisherName != null ? publisherName : ""
                  }`}</p>
                </div>
              )}

              {publisherName != null ? (
                ""
              ) : (
                <p className="mb-0 fw-bold"> {username ? username : ""}</p>
              )}
              <a
                className="nav-icon pe-md-0 dropdown-toggle"
                // href="#"
                // data-bs-toggle="dropdown"
                ref={customClickAreaRef}
                onClick={logoutDropDownHandler}
              >
                {/* <img
                src="avatar.png"
                className="avatar img-fluid rounded"
                alt="Charles Hall"
              /> */}
                <img
                  src="/avatar.png"
                  alt="Charles Hall"
                  className="img-fluid rounded-circle"
                  width="40"
                  height="40"
                />
              </a>
            </div>
            {openDropdown && (
              <span style={{ position: "absolute", right: "160px" }}>
                <LogoutDropdown
                  open={openDropdown}
                  setOpen={setOpenDropDown}
                  customClickAreaRef={customClickAreaRef}
                  setOpenApiModal={setOpenApiModal}
                />
              </span>
            )}
            {/* <div className="dropdown-menu dropdown-menu-end">
              <a className="dropdown-item" href="pages-profile.html">
                <i className="align-middle me-1" data-feather="user"></i>
                Profile
              </a>
              <a className="dropdown-item" href="#">
                <i className="align-middle me-1" data-feather="pie-chart"></i>
                Analytics
              </a>

              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="pages-settings.html">
                <i className="align-middle me-1" data-feather="settings"></i>
                Settings & Privacy
              </a>
              <a className="dropdown-item" href="#">
                <i className="align-middle me-1" data-feather="help-circle"></i>
                Help Center
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#">
                Log out
              </a>
            </div> */}
          </li>
        </ul>
      </div>
    </nav>
    </>
  );
};

export default Navbar;
