import Constants from "../../config/Constants";
import { sendGETRequestASync } from "../../utils/common_functions";

export const sendRequestToFetchApiImplementationDetails = async (inputObj) => {
    const url = Constants.APP_SERVER_OPTION_PUBLISHERS_LIST_URL + inputObj.id + '/api_keys/' 
    const headersToPost = {
      "Content-type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + inputObj.accessToken,
    };
    let data = {
      ...inputObj,
    };
    delete data["accessToken"];
    const result = await sendGETRequestASync(url, data, headersToPost);
    return result;
  };