import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import {
  sendRequestGetPublisherWebsites,
  sendRequestGetWebsites,
} from "../../view_logics/Websites/Website";
import { editPublisherLinkClick } from "../../view_logics/Publishers/EditPublisher";
import { isUserTokenValid, readUserToken } from "../../utils/common_functions";
import AppStore from "../../config/AppStore";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../components/common/PaginationComponent";
import { sendRequestGetPublishers } from "../../view_logics/Publishers";

const WebsitesList = (store) => {
  const { userType, publisherId } = useSelector((state) => state?.login);
  const [data, setData] = useState([]);
  const [websitesListLoading, setWebsitesListLoading] = useState(false);

  const [publishersList, setPublishersList] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState(undefined);
  const [websitesFilterLoading, setWebsitesFilterLoading] = useState(false);

  store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "editAdvertiser",
    history: navigate,
    loadingHandler: setWebsitesListLoading,
  };
  const getPublishersInputObj = {
    accessToken: store.getState().login.accessToken,
    url: "",
    dataHander: setPublishersList,
  };

  useEffect(() => {
    const fetchData = async () => {
      setWebsitesListLoading(true);
      try {
        inputObj.data = [];
        inputObj.dataHander = setData; // you setting data in this state you don't need to update setData again
        await sendRequestGetWebsites(inputObj);
        await sendRequestGetPublishers(getPublishersInputObj);

        // setData(jsonData);  //this is bad request is recursion
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    if (publisherId) {
      const fetchPublisherData = async () => {
        setWebsitesListLoading(true);

        try {
          inputObj.loadingHandler = setWebsitesListLoading;
          inputObj.data = [];
          inputObj.publisherId = publisherId;
          inputObj.dataHander = setData; // you setting data in this state you don't need to update setData again
          await sendRequestGetPublisherWebsites(inputObj);

          // setData(jsonData);  //this is bad request is recursion
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };
      fetchPublisherData();
    } else {
      fetchData();
    }
  }, [publisherId]);

  const publisherChangeHandler = (e) => {
    const selected = e.target.value;
    setSelectedPublisher(selected);
  };

  const editWebsiteHandler = (item) => {
    const url = "/websites/edit/" + item.id;
    navigate(url);
    console.log("🚀 ~ file: WebsitesList.js:40 ~ WebsitesList ~ item:", item);
  };

  const applyFilterHandler = () => {
    const fetchData = async () => {
      try {
        setWebsitesFilterLoading(true);
        inputObj.loadingHandler = setWebsitesFilterLoading;
        inputObj.data = [];
        inputObj.publisherId = selectedPublisher;
        inputObj.dataHander = setData; // you setting data in this state you don't need to update setData again
        await sendRequestGetPublisherWebsites(inputObj);

        // setData(jsonData);  //this is bad request is recursion
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  };

  return (
    <div className="col-12 col-xl-12">
      <div className="card">
        {userType == "admin" && (
          <>
            <div className="col-sm-6 d-flex align-items-center pt-3 px-2 gap-2">
              <div className="d-flex w-75 gap-1 align-items-center">
                <label className="form-label mb-0" htmlFor="advertiserId">
                  Publisher:
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectedPublisher}
                  onChange={publisherChangeHandler}
                >
                  <option value="">Select all</option>
                  {publishersList.length > 0
                    ? publishersList.map((publisher) => (
                        <option key={publisher.id} value={publisher.id}>
                          {publisher?.first_name + " " + publisher?.last_name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>

              {websitesFilterLoading ? (
                <button
                  className="btn btn-primary d-flex gap-2 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Apply
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={applyFilterHandler}
                  // disabled={!selectedPublisher}
                >
                  Apply
                </button>
              )}
            </div>
            <hr />
          </>
        )}
        <table className="table">
          <thead>
            <tr>
              <th>Sr #</th>
              <th>ID</th>
              <th>Name</th>
              <th>Domain</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data.map((advertiser, index) => (
                <tr
                  key={advertiser.id}
                  className={index % 2 === 0 ? "table-success" : ""}
                >
                  <td>{index + 1}</td>
                  <td>{advertiser.id}</td>
                  <td>{advertiser.name}</td>
                  <td>{advertiser.domain}</td>
                  <td className="table-action">
                    <a
                      href="#"
                      onClick={() => {
                        inputObj.AdvertiserId = ""; //advertiser.AdvertiserId;
                        // editPublisherLinkClick(inputObj);
                        editWebsiteHandler(advertiser);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit-2 align-middle"
                      >
                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                      </svg>
                    </a>
                    {/* <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash align-middle">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      </svg>
                    </a> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">
                  {websitesListLoading
                    ? "Loading Data ..."
                    : "No records found …"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <hr/>
        <PaginationComponent/> */}
      </div>
    </div>
  );
};
export default WebsitesList;
