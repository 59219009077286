import AppStore from "../config/AppStore";
import Constants from "../config/Constants";
import { logoutAction } from "../logics/Login";
import moment from "moment";

export const redirectToLoginPageIfTokenIsInvalid = (inputObj) => {
    console.log('Function redirectToLoginPageIfTokenIsInvalid.......');
    if(!inputObj.hasOwnProperty('history') || !inputObj.hasOwnProperty('userToken')){
        return;
    }
	if (inputObj.userToken === null) {
		inputObj.history.push(Constants.APP_URI_LOGIN);
	}
}
export const redirectToDashboardPageIfTokenIsValid = (inputObj) => {
    if(!inputObj.hasOwnProperty('history') || !inputObj.hasOwnProperty('userToken')){
        return;
    }
	if (inputObj.userToken !== null) {
		inputObj.history.push(Constants.APP_URI_DASHBOARD);
    }
    else if (inputObj.userToken == null) {
		inputObj.history.push(Constants.APP_URI_LOGIN);
    }
}
export const redirectToDashboardPageIfTokenIsValid1 = () => {

  // let userToken = getFromSessionStorage();
  console.log('redirectToDashboardPageIfTokenIsValid1');
  window.location = '/';

}

export const isUserTokenValid = (state) => {
	return state.userToken;
}
export const isStaffUser = (state) => {
	return state.isStaff;
}

export const readUserToken = (state) => {
  return state.userToken;
};

export const validateUserToken = (inputObj) => {
    
    if(inputObj.userToken === null){
        return;
    }
    const url = Constants.APP_SERVER_VALIDATE_USER_TOKEN_URL;
    const dataToPost = {};
    const headersToPost = {"Content-type": "application/json;charset=UTF-8", 'Authorization': 'Token ' + inputObj.userToken};

    sendGETRequest(url, dataToPost, headersToPost, validateUserTokenResponseSuccessHandler, validateUserTokenResponseErrorHandler);
}
function validateUserTokenResponseSuccessHandler(response){
    if(!response.session){
        const store = AppStore.getAppStoreInstance();	
        store.dispatch(logoutAction());
    }
}
function validateUserTokenResponseErrorHandler(error){
    console.log('Error', error);
}
export const addToSessionStorage = (keys, values) => {
    if(!Array.isArray(keys) || !Array.isArray(values) || keys.length !== values.length){
        return;
    }
    keys.forEach((key, index) => sessionStorage.setItem(key, values[index]));
}
export const removeFromSessionStorage = (key) => {
    if(key === null){
        return;
    }
    sessionStorage.removeItem(key);
}
export const getFromSessionStorage = (keys) => {
    if(!Array.isArray(keys) || keys.length === 0){
        return null;
    }
    let valuesToReturn = [];
    keys.forEach((key, index) => valuesToReturn.push(sessionStorage.getItem(key)));
    return valuesToReturn;
}
export const clearSessionStorage = () => {
  sessionStorage.clear();
}

export const sendPOSTRequest = (url, dataToPost, headersToPost, successHandler, errorHandler) => {
    console.log(url);
    console.log(dataToPost);
    fetch(url, {
      method: "post",
      mode: 'cors',
      headers: headersToPost,
      //body:  JSON.stringify(dataToPost),
      body:
        dataToPost instanceof FormData
          ? dataToPost
          : JSON.stringify(dataToPost),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (result) => {
            // console.log('SUCCESS!!!!');
          successHandler(result, dataToPost);
        },
        (error) => {
          console.log("Error", error);
          //errorHandler(JSON.stringify(error));
          if (typeof error.json === "function") {
            error.json().then((errorMessage) => {
              errorHandler(errorMessage);
            });
          } else {
            console.log(error);
          }
        }
      );
}

export const sendPUTRequest = (
  url,
  dataToPost,
  headersToPost,
  successHandler,
  errorHandler
) => {
  fetch(url, {
    method: "put",
    headers: headersToPost,
    body: JSON.stringify(dataToPost),
  })
    .then((res) => {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    })
    .then(
      (result) => {
        successHandler(dataToPost, result);
      },
      (error) => {
        console.log("Error", error);
        //errorHandler(JSON.stringify(error));
        if (typeof error.json === "function") {
          error.json().then((errorMessage) => {
            errorHandler(errorMessage);
          });
        } else {
          console.log(error);
        }
      }
    );
};

export const sendGETRequest = (
  url,
  dataToPost,
  headersToPost,
  successHandler,
  errorHandler
) => {
  var responseType = "json";
  if (dataToPost.responseType !== undefined && dataToPost.responseType === 'text') {
    responseType = "text";
  }
    const queryString = Object.keys(dataToPost)
      .map(
        (key) =>
          typeof dataToPost[key] !== "function" && `${key}=${dataToPost[key]}`
      )
      .join("&");
  // Object.keys(dataToPost).forEach((key) => console.log(dataToPost[key]));
  //   Object.keys(dataToPost).forEach(key => url.searchParams.append(key, dataToPost[key]))

   
  
    fetch(url.concat("?", queryString), {
      method: "get",
      headers: headersToPost,
    })
      .then((res) => responseType === 'text' ? res.text() :  res.json())
      .then(
        (result) => {
          successHandler(result, dataToPost);
        },
        (error) => {
          console.log("Error", error);
          errorHandler(error);
        }
      );
};

export const sendDeleteRequest = (
  url,
  dataToPost,
  headersToPost,
  successHandler,
  errorHandler
) => {
  fetch(url, {
    method: "delete",
    headers: headersToPost,
    body: JSON.stringify(dataToPost),
  })
    .then((res) => {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    })
    .then(
      (result) => {
        successHandler(dataToPost, result);
      },
      (error) => {
        console.log("Error", error);
        //errorHandler(JSON.stringify(error));
        if (typeof error.json === "function") {
          error.json().then((errorMessage) => {
            errorHandler(errorMessage);
          });
        } else {
          console.log(error);
        }
      }
    );
};
export const makeStartEndDatesFromStr = (str, format = "MM/DD/YYYY") => {
  //	var format = 'MM/DD/YYYY';
  var startDate = moment().format(format),
    endDate = moment().format(format);
  if (str === "") {
    startDate = "";
    endDate = "";
  }
  if (str === "today") {
    startDate = moment().format(format);
    endDate = moment().format(format);
  }
  if (str === "yesterday") {
    startDate = moment().subtract(1, "days").format(format);
    endDate = moment().subtract(1, "days").format(format);
  }
  if (str === "this_week") {
    startDate = moment().days("Monday").format(format);
    endDate = moment().format(format);
  }
  if (str === "last_week") {
    //
    startDate = moment()
      .subtract(1, "week")
      .startOf("week")
      .add(1, "day")
      .format(format);
    endDate = moment()
      .subtract(1, "week")
      .endOf("week")
      .add(1, "day")
      .format(format);
  }
  if (str === "this_month") {
    startDate = moment().startOf("month").startOf("month").format(format);
    endDate = moment().format(format);
  }
  if (str === "last_month") {
    //
    startDate = moment().subtract(1, "month").startOf("month").format(format);
    endDate = moment().subtract(1, "month").endOf("month").format(format);
  }
  if (str === "this_year") {
    startDate = moment().startOf("year").format(format);
    endDate = moment().format(format);
  }
  if (str === "last_thirty_days") {
    startDate = moment().subtract(30, "days").format(format);
  }
  if (str === "past_year") {
    startDate = moment().subtract(365, "days").format(format);
  }
    return { startDate: startDate, endDate: endDate };
};
export const getToolbarHeadingForCurrentPage = (pathname) => {
  var heading = "Dashboard";
  var currentPathName = pathname.substring(1);
  if (currentPathName === "") {
    return heading;
  } else if (currentPathName === "daily_profit_report") {
    heading = "Daily Profit Report";
  } else if (
    currentPathName === "newsletter" ||
    currentPathName === "create_newsletter"
  ) {
    heading = "NewsLetters";
  } else if (currentPathName === "ticker_data") {
    heading = "Ticker Data";
  } else if (currentPathName === "option_chart") {
    heading = "Option & Ticker Charts";
  } else if (currentPathName === "ticker_price_action_chart") {
    heading = "Price Action Chart";
  } else if (currentPathName === "ticker_support_resistance_chart") {
    heading = "Support & Resistance";
  } else if (currentPathName === "strategy_ticker") {
    heading = "Strategy Tickers";
  } else if (currentPathName === "strategy_performance") {
    heading = "Strategy Performances";
  }
  return heading;
};

export const isMobileView = () => {
  return window.innerWidth < 500;
}

export const getMonthName = (dateString) => {
  const date = new Date(dateString);
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return monthNames[date.getMonth()];
};

// Function to get year from YYYY-MM-DD format
export const getYear = (dateString) => {
  const date = new Date(dateString);
  return date?.getFullYear();
};

export function capitalizeFirstWord(str) {
  const words = str?.split('_'); // Split the string into an array of words
  const firstWord = words[0];    // Get the first word

  // Check if the first word is not empty
  if (firstWord?.length === 0) {
    return firstWord;
  }

  // Capitalize the first letter of the first word and concatenate with the rest of the word
  return firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
}

export function convertToTitleCase(str) {
  // Split the string by underscores
  const words = str.split('_');

  // Capitalize the first letter of each word
  const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back into a string with spaces between them
  const titleCaseString = titleCaseWords.join(' ');

  return titleCaseString;
}

export  function addLeadingZero(number) {
  // Convert number to string
  const numString = String(number);
  
  // Check if number is single digit
  if (numString.length === 1) {
    // Add leading zero for single-digit number
    return '0' + numString;
  } else {
    // Return number as string for 2 or more digits
    return numString;
  }
}


export function isEmptyArray(array){
  let condition = array.every(item => item === "");
  return condition
} 