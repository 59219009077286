import Constants from "../../config/Constants";
import {
  addToSessionStorage,
  clearSessionStorage,
  sendGETRequest,
  sendPOSTRequest,
  validateUserToken,
  redirectToPage,
  sendGETRequestASync,
  sendPUTRequest,
} from "../../utils/common_functions";

var handlersObj = {};
export const sendRequestGetWebsites = async (dataToPost) => {
  console.log("sendRequestGetWebsites");
  // console.log(dataToPost.advertisers);
  const url = Constants.APP_SERVER_OPTION_WEBSITES_LIST_URL+ '?_page=1';
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  let data = {
    ...dataToPost,
  };
  delete data["accessToken"];
  console.log(dataToPost);
  console.log(headersToPost);
  sendGETRequestASync(
    url,
    data,
    headersToPost,
    sendRequestAdvertisersSuccessHandler,
    sendRequestAdvertisersErrorHandler
  );
};

function sendRequestAdvertisersSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
  //    handlersObj.tickerTypes(response);
  // }
  request.dataHander(response);
  request.loadingHandler(false)
}
function sendRequestAdvertisersErrorHandler() {}

export const createAdvertiserBtnClick = (dataToPost) => {
  console.log("createAdvertiserBtnClick");
  console.log(dataToPost);
  // let store = AppStore.getAppStoreInstance();
  // const inputObj = {
  //   userToken: store.getState().login.accessToken,
  // };

  redirectToPage(dataToPost);
};

export const sendRequestToFetchWebsiteDetails = async (inputObj) => {
  console.log(
    "🚀 ~ file: Website.js:59 ~ sendRequestToFetchWebsiteDetails ~ inputObj:",
    inputObj
  );

  const url = Constants.APP_SERVER_OPTION_WEBSITES_CREATE_URL + inputObj.id;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let data = {
    ...inputObj,
  };
  delete data["accessToken"];
  delete data["id"];
  delete data["url"];
  delete data["advertiserInfo"];
  const result = await sendGETRequestASync(url, data, headersToPost);
  return result;
};

export const sendRequestToCreateWebsite = (inputObj) => {
  console.log("Sending Create ADvertiser Request!");
  console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_WEBSITES_CREATE_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  dataToPost.stateChangeHandler = inputObj.alreadyExistsHandler;
  dataToPost.history = inputObj.history;
  dataToPost.redirectUrl = inputObj.url;
  dataToPost.loadingHandler = inputObj?.loadingHandler;
  sendPOSTRequest(url, dataToPost, headersToPost, successHandler, errorHandler);

  function successHandler(response, request) {
    console.log(
      "🚀 ~ file: Website.js:94 ~ successHandler ~ request:",
      request
    );
    request?.loadingHandler && request?.loadingHandler(false);
    request.history(request.redirectUrl, { state: { from: "Website", type: 'create', message: response.message } });
  }
};

export const sendRequestToUpdateWebsiteDetail = (inputObj) => {
  console.log("Sending Create ADvertiser Request!");
  console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_WEBSITES_CREATE_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  let data = {
    ...dataToPost,
  };
  // delete data["userToken"];
  data.redirectUrl = inputObj.url;
  data.history = inputObj.history;
  dataToPost.loadingHandler = inputObj?.loadingHandler;
  // data.errorStateChangeHandler = inputObj.errorStateChangeHandler;
  sendPUTRequest(url, data, headersToPost, successHandler, errorHandler);

};

function successHandler(response, request) {
  console.log(
    "🚀 ~ file: Website.js:133 ~ successHandler ~ response:",
    response,
    request
  );
  request?.loadingHandler && request?.loadingHandler(false);
  request.history(request.redirectUrl, { state: { from: "Website", type: 'edit', message: response.message } });
}

function errorHandler(error, request) {
  console.log(
    "🚀 ~ file: Website.js:133 ~ successHandler ~ response:",
    error,
    request
  );
  request?.loadingHandler && request?.loadingHandler(false);
  request.history(request.redirectUrl, { state: { from: "Website", type: 'error', message: error.message } });
}



export const sendRequestGetPublisherWebsites = async (dataToPost) => {
  console.log("sendRequestGetWebsites");
  // console.log(dataToPost.advertisers);
  const url = Constants.APP_SERVER_OPTION_WEBSITES_LIST_URL+ `?publisher_id=${dataToPost?.publisherId}`;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + dataToPost.accessToken,
  };
  let data = {
    ...dataToPost,
  };
  delete data["accessToken"];
  console.log(dataToPost);
  console.log(headersToPost);
  sendGETRequestASync(
    url,
    data,
    headersToPost,
    sendRequestGetPublisherWebsitesSuccessHandler,
    sendRequestGetPublisherWebsitesErrorHandler
  );

  function sendRequestGetPublisherWebsitesSuccessHandler(response, request) {
    console.log(
      "🚀 ~ file: Website.js:133 ~ successHandler ~ response: publisher",
      response,
      request
    );
    if(request?.dataHander){
      request?.dataHander(response)
    }
    if(request?.loadingHandler){
      request.loadingHandler(false)

    }
    if(Array.isArray(response)){
      request?.loadingHandler && request?.loadingHandler(false)
      request?.dataHander && request?.dataHander(response)
    }
    // request.history(request.redirectUrl, { state: { from: "Website", type: 'edit', message: response.message } });
  }

};

function sendRequestGetPublisherWebsitesErrorHandler(error, request) {
  console.log(
    "🚀 ~ file: Website.js:133 ~ successHandler ~ response:",
    error,
    request
  );
  // request.history(request.redirectUrl, { state: { from: "Website", type: 'error', message: error.message } });
}