import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { sendRequestToFetchApiImplementationDetails } from "../view_logics/ApiImplementation";
import AppStore from "../config/AppStore";
Modal.setAppElement("#root"); // Set the app root element

const ApiImplementationModal = ({ isOpen, onClose, data }) => {
  let store = AppStore.getAppStoreInstance();
  let accessToken = store.getState().login.accessToken;
  let publisherId = store.getState().login?.publisherId;
  const [loadingKeys, setLoadingKeys] = useState(false);

  const [apiKeys, setApiKeys] = useState({
    key1: "",
    key2: "",
  });

  useEffect(() => {
    if (publisherId != null) {
      setLoadingKeys(true);
      const fetchData = async () => {
        let res = await sendRequestToFetchApiImplementationDetails({
          id: publisherId,
          accessToken: accessToken,
        });
        console.log("🚀 ~ fetchData ~ res:", res);
        if (res?.id) {
          setApiKeys({ key1: res?.api_key, key2: res?.api_key2 });
          setLoadingKeys(false);
        }
      };
      fetchData();
    }
  }, [publisherId]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Api integration"
      className="custom-modal"
    >
      <div className="d-flex flex-column gap-3 p-2">
        <h2>API IMPLEMENTATION</h2>

        <div className="border border-dark d-flex flex-column gap-3 p-4">
          <h4 className="fw-bold" style={{ lineHeight: "26px" }}>
            API implmentation can be used to access your reports, for
            integrating it with your CRM.
            <br />
            We provide 2 wroking API Keys for your convenience. You can reset
            them anytime.
          </h4>

          <h5 className="fw-bold" style={{ color: "#0dcaf0" }}>
            <u> Review our Developer Documentation </u>
          </h5>

          <div className="ps-4 d-flex flex-column gap-1">
            {loadingKeys && (
              <h5 class="card-title placeholder-glow d-flex flex-column gap-2">
                <span class="placeholder col-6"></span>
                <span class="placeholder col-6"></span>
              </h5>
            )}
            {!loadingKeys && !apiKeys?.key1 && !apiKeys?.key2 && <p>No Keys Provided.</p>}
            {apiKeys?.key1 !== "" && (
              <div className="d-flex gap-5">
                <h5 className="fw-bold">API Key : {apiKeys?.key1}</h5>
                <h5 className="fw-bold" style={{ color: "#0d6efd" }}>
                  <u> Reset Key </u>
                </h5>
              </div>
            )}
            {apiKeys?.key2 !== "" && (
              <div className="d-flex gap-5">
                <h5 className="fw-bold">API Key : {apiKeys?.key2}</h5>
                <h5 className="fw-bold" style={{ color: "#0d6efd" }}>
                  <u> Reset Key</u>
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApiImplementationModal;
