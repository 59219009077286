import React, { useEffect, useState } from "react";
import AppStore from "../../config/AppStore";
import PaginationComponent from "../../components/common/PaginationComponent";
import { sendRequestGetAdvertiserOffers } from "../../view_logics/AdvertiserOffers/AdvertiserOffer";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { sendRequestGetPublishers } from "../../view_logics/Publishers";
import ReactSelect from "react-select";
import { sendRequestGetOfferStatsReports } from "../../view_logics/Reports/Reports";
import DatePickerField from "../../components/common/DatePickerField";
// import DatePickerField from "../../components/common/DatePickerField";

const OfferStatReportsList = (store) => {
  const [apiData, setApiData] = useState([]);
  const [reportsList, setReportsList] = useState([]);
  const [displayStats, setDisplayStats] = useState([]);
  const [displayIndex, setDisplayIndex] = useState(null);

  const [sortOrder, setSortOrder] = useState("asc"); // or 'desc'
  const [searchQuery, setSearchQuery] = useState("");
  const [advertisersList, setAdvertisersList] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [publishersList, setPublishersList] = useState([]);
  const [dateFieldEmpty, setDateFieldEmpty] = useState(false);
  const [reportsFilterLoading, setReportsFilterLoading] = useState(false);
  const todayDate = new Date();
  console.log("🚀 ~ OfferStatReportsList ~ todayDate:", todayDate);

  const formatDate = (date) => format(date, "yyyy-MM-dd");

  store = AppStore.getAppStoreInstance();
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "reports",
    data: {
      from: formatDate(todayDate),
      to: formatDate(todayDate),
      offer_ids: [],
      publisher_id: "",
    },
    dataHander: setApiData,
    loadingHandler: setReportsFilterLoading,
  };

  const getAdvertiserOffersInputObj = {
    accessToken: store.getState().login.accessToken,
    url: "",
    dataHander: setAdvertisersList,
  };

  const getPublishersInputObj = {
    accessToken: store.getState().login.accessToken,
    url: "",
    dataHander: setPublishersList,
  };

  useEffect(() => {
    if (Array.isArray(apiData)) {
      setReportsList([...apiData]);
    }
  }, [apiData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await sendRequestGetOfferStatsReports(inputObj);
        await sendRequestGetAdvertiserOffers(getAdvertiserOffersInputObj);
        await sendRequestGetPublishers(getPublishersInputObj);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const sortBy = (key) => {
    if (key == "desc") {
      setReportsList((prevList) => {
        const sortedData = [...prevList];
        const firstItem = sortedData.shift();
        sortedData.push(firstItem);
        return sortedData;
      });
    } else {
      setReportsList((prevList) => {
        const sortedData = [...prevList];
        const lastItem = sortedData.pop();
        sortedData.unshift(lastItem);
        return sortedData;
      });
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.trim().toLowerCase(); // Trim whitespace
    setSearchQuery(query);

    const filteredData = reportsList.filter((row) => {
      return Object.values(row).some((value) =>
        String(value).toLowerCase().includes(query)
      );
    });

    setReportsList(filteredData);
  };

  useEffect(() => {
    if (searchQuery == "") {
      setReportsList([...apiData]);
    }
  }, [searchQuery]);

  const sumOfField = (item, name) => {
    const sumByKey = item.reduce((acc, obj) => {
      for (const key in obj) {
        if (
          key !== "advertiser_offer_id" ||
          key !== "offer_name" ||
          key !== "click_income" ||
          key !== "total_click_income"
        ) {
          acc[key] = Number(acc[key] || 0) + Number(obj[key]);
        }
      }
      return acc;
    }, {});
    let roundedNum = Math.round(sumByKey[name]);
    return roundedNum;
  };

  const [selectedAdvertiser, setSelectedAdvertiser] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState(null);

  const advertiserChangeHandler = (values) => {
    let ids = [];
    if (values?.length > 0) {
      values.forEach((obj) => {
        if (obj.id) {
          let findId = ids.find((id) => id == obj.id);
          if (findId) {
            return;
          } else {
            ids.push(obj.id);
          }
        }
      });
    }
    setSelectedAdvertiser([...ids]);
  };

  const publisherChangeHandler = (e) => {
    const selected = e.target.value;
    setSelectedPublisher(selected);
  };

  const handleDateRangeChange = (e) => {
    const selected = e.target.value;
    setSelectedDateRange(selected);

    const today = new Date();
    setDateFieldEmpty(true);

    switch (selected) {
      case "today":
        setFromDate(today);
        setToDate(today);
        break;
      case "yesterday":
        setFromDate(addDays(today, -1));
        setToDate(addDays(today, -1));
        break;
      case "thisWeek":
        setFromDate(startOfWeek(today));
        setToDate(endOfWeek(today));
        break;
      case "lastWeek":
        setFromDate(startOfWeek(addDays(today, -7)));
        setToDate(endOfWeek(addDays(today, -7)));
        break;
      case "thisMonth":
        setFromDate(startOfMonth(today));
        setToDate(endOfMonth(today));
        break;
      case "lastMonth":
        setFromDate(startOfMonth(addDays(today, -30)));
        setToDate(endOfMonth(addDays(today, -30)));
        break;
      case "thisYear":
        setFromDate(startOfYear(today));
        setToDate(endOfYear(today));
        break;
      default:
        break;
    }
  };

  const applyFilterHandler = () => {
    let postObject = {
      from: formatDate(fromDate),
      to: formatDate(toDate),
      offer_ids: selectedAdvertiser,
      publisher_id: "",
    };
    if (selectedPublisher != null) {
      postObject.publisher_id = selectedPublisher;
    }
    inputObj.data = postObject;

    const fetchData = async () => {
      try {
        setReportsFilterLoading(true);
        await sendRequestGetOfferStatsReports(inputObj);
      } catch (error) {
        console.log("Error fetching data:", error);
        setReportsFilterLoading(false);
      }
    };

    fetchData();
    setDisplayStats([]);
    setDisplayIndex(null);
  };

  useEffect(() => {
    const newDate = new Date();
    setDateFieldEmpty(true);
    if (newDate) {
      setFromDate(newDate);
      setToDate(newDate);
    }
  }, []);

  const calculateTotalNestedECPM = (dateArray) => {
    const totalNestedECPM = dateArray.reduce((acc, item) => {
      const individualECPM =
        ((item.net_income || 0) / (item.impression_count || 1)) * 1000;
      return acc + individualECPM;
    }, 0);

    return totalNestedECPM.toFixed(2);
  };

  return (
    <div className="col-12 col-xl-12">
      <div className="card">
        <div className="w-100 px-3 pt-3 d-flex">
          <div className="w-100 d-flex gap-1">
            <div className="w-50 d-flex gap-1 align-items-center">
              <div className="d-flex w-50 gap-1 align-items-center">
                <label className="form-label mb-0" htmlFor="advertiserId">
                  Offer:
                </label>

                <ReactSelect
                  className="flex-grow-1 flex-shrink-1 flex-basis-auto"
                  classNamePrefix="select"
                  name={"advertisers"}
                  options={advertisersList}
                  getOptionLabel={(option) => option.name || option.label}
                  isMulti
                  getOptionValue={(option) => option.id}
                  // value={value.length > 0 ? value : field.value}
                  onChange={advertiserChangeHandler}
                  isClearable={true}
                  isSearchable={true}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      display: "flex",
                      maxWidth: "200px",
                      maxHeight: "33px",
                    }),
                    menu: (styles) => ({ ...styles, zIndex: 999 }),
                    valueContainer: (styles) => ({
                      ...styles,
                      flexWrap: "nowrap",
                    }),
                  }}
                />
              </div>
              <div className="d-flex w-50 gap-1 align-items-center">
                <label className="form-label mb-0" htmlFor="advertiserId">
                  Publisher:
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectedPublisher != null ? selectedPublisher : ''}
                  onChange={publisherChangeHandler}
                >
                  <option value="">Select Publisher</option>
                  {publishersList.length > 0
                    ? publishersList.map((publisher) => (
                        <option key={publisher.id} value={publisher.id}>
                          {publisher?.first_name + " " + publisher?.last_name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </div>

            <div className="w-50 d-flex gap-1 align-items-center">
              <div className="d-flex w-25">
                <select
                  className="form-select"
                  aria-label="select example"
                  value={selectedDateRange ? selectedDateRange : "today"}
                  onChange={handleDateRangeChange}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="thisWeek">This Week</option>
                  <option value="lastWeek">Last Week</option>
                  <option value="thisMonth">This Month</option>
                  <option value="lastMonth">Last Month</option>
                  <option value="thisYear">This Year</option>
                </select>
              </div>
              <div className="w-75 d-flex gap-1 align-items-center">
                <div className="w-50 d-flex gap-1 align-items-center">
                  <label className="form-label mb-0" htmlFor="advertiserId">
                    From:
                  </label>
                  <DatePickerField value={fromDate} setValue={setFromDate} />
                </div>

                <div className="w-50 d-flex gap-1 align-items-center">
                  <label className="form-label mb-0" htmlFor="advertiserId">
                    To:
                  </label>
                  <DatePickerField value={toDate} setValue={setToDate} />
                </div>
              </div>

              <div>
                {reportsFilterLoading ? (
                  <button
                    className="btn btn-primary d-flex gap-2 align-items-center"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Apply
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={applyFilterHandler}
                  >
                    Apply
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* <div className="w-25 d-flex align-items-center">
            <p className="mb-0">Search:</p>
            <input
              type="text"
              className="form-control w-100"
              placeholder="Search by name ..."
              aria-describedby="basic-addon2"
              onChange={handleSearch}
            ></input>
          </div> */}
        </div>
        <hr />
        <table id="reports" className="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                Name{" "}
                {/* <i
                    className="position-absolute fa-solid fa-sort-up mt-1 ms-2"
                    onClick={() => sortBy("asc")}
                  ></i>
                  <i
                    className="position-absolute fa-solid fa-sort-down mt-2 ms-2"
                    onClick={() => sortBy("desc")}
                  ></i> */}
              </th>

              <th scope="col">Click Cost </th>
              <th scope="col">Click Count </th>
              <th scope="col">Dup Count </th>

              <th scope="col">Gross Income </th>
              <th scope="col">Net Income </th>

              <th scope="col">
                ECPM{" "}
                <i
                  className="fa-solid fa-circle-info"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={`( Net Income / Impressions Count ) * 1000`}
                ></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {reportsList.length > 0 ? (
              reportsList.map((item, index) => {
                return (
                  <>
                    <tr className="table-info" key={index}>
                      <td
                        title="Show Stats"
                        className="cursor-pointer"
                        onClick={() => {
                          if (displayStats.length > 0) {
                            setDisplayStats([]);
                            setDisplayIndex(null);
                          } else {
                            setDisplayStats(item.stats);
                            setDisplayIndex(index);
                          }
                        }}
                      >
                        {item.publisher_name}
                        <i
                          className={`${
                            item.stats?.length > 0 && displayIndex == index
                              ? "fa-solid fa-circle-minus"
                              : "fa-solid fa-circle-plus"
                          } ps-3`}
                        ></i>
                      </td>
                      <td>{sumOfField(item.stats, "click_cost")}</td>
                      <td>{sumOfField(item.stats, "click_count")}</td>
                      <td>{sumOfField(item.stats, "dup_count")}</td>
                      <td>{sumOfField(item.stats, "gross_income")}</td>
                      <td>{sumOfField(item.stats, "net_income")}</td>
                      {/* <td>{sumOfField(item.stats, "net_income")}</td> */}
                      <td>{calculateTotalNestedECPM(item.stats)}</td>
                    </tr>
                    {displayIndex == index && displayStats.length > 0 && (
                      <>
                        {displayStats.map((item1, index1) => {
                          return (
                            <tr key={index1}>
                              <td>{item1.offer_name}</td>
                              <td>{item1.click_cost?.toFixed(2)} </td>
                              <td>{item1.click_count}</td>
                              <td>{item1.dup_count}</td>
                              <td>{item1.gross_income?.toFixed(2)}</td>
                              <td>{item1.net_income?.toFixed(2)}</td>
                              <td>
                                {(
                                  ((item1.net_income || 0) /
                                    (item1.impression_count || 1)) *
                                  1000
                                ).toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan="12">No records found …</td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <hr />
        <PaginationComponent /> */}
      </div>
    </div>
  );
};
export default OfferStatReportsList;
