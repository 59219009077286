import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form, ListGroup, FormControl } from "react-bootstrap";

const MultiSelectWithAddRemove = ({
  name = "",
  placeholder = "",
  selectedValues,
  setselectedValues,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  console.log(
    "🚀 ~ MultiSelectWithAddRemove ~ selectedCountries:",
    selectedCountries
  );
  const [allCountries] = useState([
    "USA",
    "Canada",
    "Germany",
    "France",
    "Japan",
    "India",
  ]);

  const handleAdd = () => {
    if (inputValue) {
      const newCountries = inputValue
        .split(",")
        .map((value) => value.trim())
        .filter((value) => value !== "" && !selectedCountries.includes(value));

      setSelectedCountries([...selectedCountries, ...newCountries]);
      setselectedValues([...selectedCountries, ...newCountries]);
      setInputValue("");
    }
  };

  const handleRemove = (country) => {
    const updatedCountries = selectedCountries.filter((c) => c !== country);
    setSelectedCountries(updatedCountries);
    setselectedValues(updatedCountries);
  };

  useEffect(() => {
    if (selectedValues) {
      console.log("🚀 ~ useEffect ~ selectedValues:", selectedValues);
      setSelectedCountries(JSON.parse(JSON.stringify(selectedValues)));
    }
  }, [selectedValues]);

  return (
    <div className="d-flex flex-column gap-2 mt-2">
        <Form.Group
          className="d-flex gap-2 align-items-center"
        >
          <Field
          className="w-100 form-control"
            type="text"
            name={name}
            id={name}
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <i
            className="fa-xl fa-solid fa-square-plus"
            style={{ color: "#28a745" }}
            onClick={handleAdd}
          ></i>
          <i
            className="fa-xl fa-solid fa-square-minus"
            style={{ color: "#dc3545" }}
          ></i>
        </Form.Group>
        <ErrorMessage
          name={name}
          component="div"
          className="invalid-feedback"
          style={{ display: "block" }}
        />

      <ListGroup
        className="d-flex w-100"
        style={{
          minHeight: "160px",
          maxHeight: "160px",
          border: "1px solid #ced4da",
          overflow: "auto",
        }}
      >
        {selectedCountries.map((country) => (
          <ListGroup.Item
            key={country}
            className="d-flex justify-content-between border-0 px-3 py-2"
          >
            {country}
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleRemove(country)}
            >
              Remove
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default MultiSelectWithAddRemove;
