import { createSlice, createReducer, createAction } from "@reduxjs/toolkit";
import Constants from "../../config/Constants";
import {
  sendGETRequestASync,
  sendGETRequest,
  sendPOSTRequest,
  sendPUTRequest,
  redirectToPageIfTokenIsValid,
} from "../../utils/common_functions";
import axios from "axios";
import { uploadToS3 } from "../../utils/uploadToS3";

export const editAdvertiserOfferLinkClick = (inputObj) => {
  const url = "/offers/edit/" + inputObj.id;
  inputObj.history(url);
};

export const sendRequesttoFetchAdvertiseOfferDetails = async (inputObj) => {
  console.log("sendRequesttoFetchAdvertiseDetails");
  console.log(inputObj);

  const url =
    Constants.APP_SERVER_OPTION_EDIT_ADVERTISER_OFFER_URL +
    "/" +
    inputObj.offerId;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let data = {
    ...inputObj,
  };
  delete data["accessToken"];
  delete data["offerId"];
  delete data["url"];
  delete data["advertiserInfo"];
  const result = await sendGETRequestASync(
    url,
    data,
    headersToPost,
    sendRequesttoFetchAdvertiseDetailsSuccessHandler,
    sendRequesttoFetchAdvertiseDetailsErrorHandler
  );
  if(inputObj?.updateInfoHandler && result){
    inputObj?.updateInfoHandler(result)
  }
  return result;
};
function sendRequesttoFetchAdvertiseDetailsSuccessHandler(response, request) {
  console.log("sendRequesttoFetchAdvertiseDetailsSuccessHandler");
  // console.log(response)
  // request.dataHandler(response);
  // alert('Success handler setting tab number 2');
  // console.log(request);
  // request.setActiveTab(2);
}
function sendRequesttoFetchAdvertiseDetailsErrorHandler(error, request) {
  request.history("", {
    state: { from: "Advertiser Offer", type: "error", message: error.message },
  });
}

export const sendUpdateAdvertiserOfferFormSubmitRequest = (inputObj) => {
  console.log("sendUpdateAdvertiserOfferFormSubmitRequest", inputObj);
  // console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_UPDATE_ADVERTISER_OFFER_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  dataToPost.history = inputObj.history;
  dataToPost.setActiveTab = inputObj.setActiveTab
  dataToPost.loadingHandler = inputObj?.loadingHandler
  let data = {
    ...dataToPost,
  };
  delete data["userToken"];
  sendPUTRequest(
    url,
    data,
    headersToPost,
    sendUpdateAdvertiserOfferFormSubmitResponseSuccessHandler,
    sendUpdateAdvertiserOfferFormSubmitResponseErrorHandler
  );
  // if (inputObj.url){
  //   inputObj.history(inputObj.url);
  // }

  function sendUpdateAdvertiserOfferFormSubmitResponseSuccessHandler(
    response,
    request
  ) {
    console.log("🚀 ~ file: EditAdvertiserOffer.js:90 ~ response:", response, request)
    // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserOfferId")) {
    //    handlersObj.tickerTypes(response);
    // }
    request?.loadingHandler && request?.loadingHandler(false);
  
    if(response.status == 'success'){
      request.history("", {
        state: { from: "Advertiser Offer", type: "edit", message: response.message },
      });
      let newInputObj = {
        accessToken: inputObj?.accessToken,
        offerId: inputObj?.dataToPost?.offerId,
        updateInfoHandler: inputObj?.updateInfoById
      }
      sendRequesttoFetchAdvertiseOfferDetails(newInputObj)
      request.setActiveTab(2)
    }
    console.log("sendUpdateAdvertiserOfferFormSubmitResponseSuccessHandler");
    // console.log(response);
    // request.history(request.url);
    // request.dataHander(response);
  }
};


// function sendRequestAdvertiserOffersErrorHandler(error, request) {
//   request?.loadingHandler && request?.loadingHandler(false);

// }

export const sendUpdateAdvertiserOfferFormSubmitResponseErrorHandler = (
  error,
  request
) => {
  console.log("sendUpdateAdvertiserOfferFormSubmitResponseErrorHandler");
  request?.loadingHandler && request?.loadingHandler(false);
  request.history("", {
    state: { from: "Advertiser Offer", type: "error", message: error.message },
  });

  // console.log(error);
  // let inputObj = request;
  // inputObj.url = 'advertiser/create';
  // redirectToPage(request);
};

export const sendUpdateAdvertiserOfferTargetsFormSubmitRequest = (inputObj) => {
  console.log("sendUpdateAdvertiserOfferTargetsFormSubmitRequest");
  // console.log(inputObj);

  const url =
    Constants.APP_SERVER_OPTION_UPDATE_ADVERTISER_OFFER_TARGETTING_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  dataToPost.history = inputObj.history;
  dataToPost.redirectUrl = inputObj.url;
  dataToPost.loadingHandler = inputObj?.loadingHandler;
  let data = {
    ...dataToPost,
  };
  delete data["userToken"];
  sendPOSTRequest(
    url,
    data,
    headersToPost,
    sendUpdateAdvertiserOfferTargetsFormSubmitResponseSuccessHandler,
    sendUpdateAdvertiserOfferTargetsFormSubmitResponseErrorHandler
  );
  // inputObj.history(inputObj.url);
};

function sendUpdateAdvertiserOfferTargetsFormSubmitResponseSuccessHandler(
  response,
  request
) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserOfferId")) {
  //    handlersObj.tickerTypes(response);
  // }
  console.log(
    "sendUpdateAdvertiserOfferTargetsFormSubmitResponseSuccessHandler",response,
    request
  );
  request?.loadingHandler && request?.loadingHandler(false);
  request.history(request?.redirectUrl, {
    state: {
      from: "Advertiser Offer",
      type: "edit",
      message: response.message,
    },
  });

  // console.log(response);
  // request.history(request.url);
  // request.dataHander(response);
}
function sendUpdateAdvertiserOfferTargetsFormSubmitResponseErrorHandler(
  error,
  request
) {
  request?.loadingHandler && request?.loadingHandler(false);
  request.history("", {
    state: { from: "Advertiser Offer", type: "error", message: error.message },
  });
}

export const sendUpdateAdvertiserOfferLeadSettingFormSubmitRequest = (
  inputObj
) => {
  console.log(inputObj);

  const url =
    Constants.APP_SERVER_OPTION_UPDATE_ADVERTISER_OFFER_LEAD_SETTING_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
 
  let dataToPost = inputObj.dataToPost;
  dataToPost.history = inputObj.history;
  dataToPost.setActiveTab = inputObj.setActiveTab
  dataToPost.loadingHandler = inputObj?.loadingHandler

  let data = {
    ...dataToPost,
  };
  delete data["userToken"];
  sendPUTRequest(
    url,
    data,
    headersToPost,
    sendUpdateAdvertiserOfferLeadSettingFormSubmitResponseSuccessHandler,
    sendUpdateAdvertiserOfferLeadSettingFormSubmitResponseErrorHandler
  );
};

function sendUpdateAdvertiserOfferLeadSettingFormSubmitResponseSuccessHandler(
  response,
  request
) {
  request?.loadingHandler && request?.loadingHandler(false);

  if(response?.status == 'success'){
    request.history("", {
      state: { from: "Advertiser Offer", type: "edit", message: response.message },
    });
    request.setActiveTab(4)
  }
  if(response?.status == 'error'){
    request.history("", {
      state: { from: "Advertiser Offer", type: "edit", message: response.message },
    });
  }

}

function sendUpdateAdvertiserOfferLeadSettingFormSubmitResponseErrorHandler(
  error,
  request
) {
  console.log("🚀 ~ file: EditAdvertiserOffer.js:213 ~ error:", error)
  request?.loadingHandler && request?.loadingHandler(false);
  request.history("", {
    state: { from: "Advertiser Offer", type: "error", message: error.message },
  });
  }


  export const sendOfferImageRequestToGetS3UploadData = (file, accessToken) => {
    console.log("🚀 ~ file: EditAdvertiserOffer.js:235 ~ sendOfferImageRequestToGetS3UploadData ~ file:", file)
    
    const url = Constants.APP_SERVER_OPTION_GENERATE_PRESIGNED_POST_URL_ADVERTISER_OFFER;
    const dataToPost = {
      fileName: file.value.name
    }
    return new Promise((resolve, reject)=>{
      axios.post(url, dataToPost, {
        headers: {
          'Content-Type': "application/json;charset=UTF-8",
          Authorization: "Bearer " + accessToken,
        },
      })
        .then(async(res) => {
          if (res.status == 200) {
            console.log("response", res);
            // logic of uploading the file is implemented in the uploadToS3
            // uploadToS3 expects the presigned url and file object to upload the file.
            let s3Response = await uploadToS3(res.data, file.value);
            resolve({presignedData :res.data, postData: file})
          }
        })
        .catch((error) => {
          reject(error)
        });
    })
    
  };



  export const sendUpdateAdvertiserOfferImagesFormSubmitRequest = (inputObj) => {
    // console.log(inputObj);
  
    const url =
      Constants.APP_SERVER_OPTION_UPDATE_ADVERTISER_OFFER_IMAGES_URL;
    const headersToPost = {
      "Content-type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + inputObj.accessToken,
    };
    let dataToPost = inputObj.dataToPost;
    dataToPost.history = inputObj.history;
    dataToPost.setActiveTab = inputObj.setActiveTab
  dataToPost.loadingHandler = inputObj?.loadingHandler
    let data = {
      ...dataToPost,
    };
    delete data["userToken"];
    sendPOSTRequest(
      url,
      data,
      headersToPost,
      sendUpdateAdvertiserOfferImagesFormSubmitResponseSuccessHandler,
      sendUpdateAdvertiserOfferImagesFormSubmitResponseErrorHandler
    );
    // inputObj.history(inputObj.url);
  };

  function sendUpdateAdvertiserOfferImagesFormSubmitResponseSuccessHandler(
    response,
    request
  ) {
    console.log("🚀 ~ file: EditAdvertiserOffer.js:295 ~ response:", request, response)
    request?.loadingHandler && request?.loadingHandler(false);
    if(response?.status == 'success'){
      request.history("", {
        state: { from: "Advertiser Offer", type: "edit", message: response.message },
      });
      request.setActiveTab(3)
    }
    if(response?.status == 'error'){
      request.history("", {
        state: { from: "Advertiser Offer", type: "error", message: response.message },
      });
      // request.setActiveTab(3)
    }
  
  }
  
  function sendUpdateAdvertiserOfferImagesFormSubmitResponseErrorHandler(
    error,
    request
  ) {
    console.log("🚀 ~ file: EditAdvertiserOffer.js:213 ~ error:", error)
    request?.loadingHandler && request?.loadingHandler(false);
    request.history("", {
      state: { from: "Advertiser Offer", type: "error", message: error.message },
    });
    }

  