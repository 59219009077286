import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Formik,
  useFormik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
} from "formik";
import { isUserTokenValid, readUserToken } from "../../utils/common_functions";
import AppStore from "../../config/AppStore";
import * as Yup from "yup";

import {
  sendUpdateAdvertiserOfferFormSubmitRequest,
  sendUpdateAdvertiserOfferLeadSettingFormSubmitRequest,
} from "../../view_logics/AdvertiserOffers/EditAdvertiserOffer";
import Spinner from "../../components/common/Spinner";
import LoadingButton from "../../components/common/LoadingButton";

const LeadSettingsTab = (advertiserOfferInfo) => {
  const [leadSettingsFormLoading, setLeadSettingsFormLoading] = useState(false);

  const store = AppStore.getAppStoreInstance();
  const { id } = useParams();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    // url: 'editAdvertiser',
    history: navigate,
  };

  const formik = useFormik({
    initialValues: {
      offerId: "",
      httpPingAuthFlag: false,
      pingHttpBasicAuthUsername: "",
      pingHttpBasicAuthPassword: "",
      pingPostingMethod: "http_get",
      pingEvaluationCode: "",
      pingPostingUrl: "",
      pingResponseType: "text",
      pingResponsePropertyToMatch: "",
      pingResponseSuccessStringToMatch: "",
      pingResponseFailureStringToMatch: "",
      leadHttpBasicAuthFlag: false,
      leadHttpBasicAuthUsername: "",
      leadHttpBasicAuthPassword: "",
      leadPostingMethod: "http_get",
      leadEvaluationCode: "",
      leadPostingUrl: "",
      leadResponseType: "text",
      leadResponsePropertyToMatch: "",
      leadResponseSuccessStringToMatch: "",
      leadResponseFailureStringToMatch: "",
      pingPostingData: [{}],
      pingHeaderPostingData: [{}],
    },
    onSubmit: (values) => {
      // Handle form submission
      // ...
    },
  });

  const myadvertiserOfferInfo = advertiserOfferInfo.advertiserOfferInfo;
  const [configInfoObject, setConfigObject] = useState({
    offerId: myadvertiserOfferInfo.id,
    httpPingAuthFlag: myadvertiserOfferInfo.ping_http_basic_auth_flag
      ? true
      : false,
    pingHttpBasicAuthUsername:
      myadvertiserOfferInfo.ping_http_basic_auth_username,
    pingHttpBasicAuthPassword:
      myadvertiserOfferInfo.ping_http_basic_auth_password,
    pingPostingMethod: myadvertiserOfferInfo.ping_posting_method,
    pingEvaluationCode: myadvertiserOfferInfo.ping_evaluation_code,
    pingPostingUrl: myadvertiserOfferInfo.ping_posting_url,
    pingResponseType: myadvertiserOfferInfo.ping_response_type,
    pingResponsePropertyToMatch:
      myadvertiserOfferInfo.ping_response_property_to_match,
    pingResponseSuccessStringToMatch:
      myadvertiserOfferInfo.ping_response_success_string_to_match,
    pingResponseFailureStringToMatch:
      myadvertiserOfferInfo.ping_response_failure_string_to_match,
    leadHttpBasicAuthFlag: myadvertiserOfferInfo.lead_http_basic_auth_flag,
    leadHttpBasicAuthUsername:
      myadvertiserOfferInfo.lead_http_basic_auth_username,
    leadHttpBasicAuthPassword:
      myadvertiserOfferInfo.lead_http_basic_auth_password,
    leadPostingMethod: myadvertiserOfferInfo.lead_posting_method,
    leadEvaluationCode: myadvertiserOfferInfo.lead_evaluation_code,
    leadPostingUrl: myadvertiserOfferInfo.lead_posting_url,
    leadResponseType: myadvertiserOfferInfo.lead_response_type,
    leadResponsePropertyToMatch:
      myadvertiserOfferInfo.lead_response_property_to_match,
    leadResponseSuccessStringToMatch:
      myadvertiserOfferInfo.lead_response_success_string_to_match,
    leadResponseFailureStringToMatch:
      myadvertiserOfferInfo.lead_response_failure_string_to_match,
    pingPostingData: myadvertiserOfferInfo?.ping_posting_data == "" ? [{}] : [],
    pingHeaderPostingData:
      myadvertiserOfferInfo?.ping_posting_header_data == "" ? [{}] : [],
  });
  console.log("🚀 ~ LeadSettingsTab ~ configInfoObject:", configInfoObject)

  const updateLeadSettingsInfo = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const serializedData = {};
    let pingPostingDataKeys = [];
    let pingPostingDataValues = [];
    let pingHeaderPostingDataKeys = [];
    let pingHeaderPostingDataValues = [];

    serializedData["pingPostingData"] = [{}];
    serializedData["pingPostingHeaderData"] = [{}];

    for (let [name, value] of formData.entries()) {
      if (name && value) {
        if (name.includes("pingPostingData")) {
          if (name.includes("key")) {
            pingPostingDataKeys.push({ name, value });
          }
          if (name.includes("value")) {
            pingPostingDataValues.push({ name, value });
          }
        } else if (name.includes("pingHeaderPostingData")) {
          if (name.includes("key")) {
            pingHeaderPostingDataKeys.push({ name, value });
          }
          if (name.includes("value")) {
            pingHeaderPostingDataValues.push({ name, value });
          }
        } else if (name.includes("httpPingAuthFlag")) {
          let newValue = value == "true" ? 1 : 0;
          serializedData[name] = newValue;
        } else {
          serializedData[name] = value;
        }
      }
    }

    console.log("🚀 ~ updateLeadSettingsInfo ~ pingPostingDataKeys:", pingPostingDataKeys, pingPostingDataValues)

    const pingPostingDataPostArr = pingPostingDataKeys.map(
      (keyItem, index) => ({
        [keyItem.value]: pingPostingDataValues[index].value,
      })
    );

    const pingPostingDataPostObj = pingPostingDataPostArr.reduce((acc, obj) => {
      const [key, value] = Object.entries(obj)[0];
      acc[key] = value;
      return acc;
    }, {});

    const pingPostingHeaderDataPostArr = pingHeaderPostingDataKeys.map(
      (keyItem, index) => ({
        [keyItem.value]: pingHeaderPostingDataValues[index].value,
      })
    );

    const pingPostingHeaderDataPostObj = pingPostingHeaderDataPostArr.reduce((acc, obj) => {
      const [key, value] = Object.entries(obj)[0];
      acc[key] = value;
      return acc;
    }, {});
    if (pingPostingDataPostObj) {
      serializedData["pingPostingData"] = pingPostingDataPostObj;
    }
    if (pingPostingHeaderDataPostArr.length > 0) {
      serializedData["pingPostingHeaderData"] = pingPostingHeaderDataPostObj;
    }

    if (advertiserOfferInfo?.advertiserOfferInfo?.id) {
      serializedData["offerId"] = advertiserOfferInfo.advertiserOfferInfo.id;
    }
    delete serializedData["_token"];
    console.log(serializedData);
    const inputObj = {
      accessToken: store.getState().login.accessToken,
      //   url: '/advertiser',
      history: navigate,
      dataToPost: serializedData,
      setActiveTab: advertiserOfferInfo.setActiveTab,
      loadingHandler: setLeadSettingsFormLoading,

    };
    setLeadSettingsFormLoading(true);

    sendUpdateAdvertiserOfferLeadSettingFormSubmitRequest(inputObj);
  };

  const pingSettingSchema = Yup.object().shape({
    pingHttpBasicAuthUsername: Yup.string().required("Username is Required"),
    pingHttpBasicAuthPassword: Yup.string().required("Password is Required"),
    pingPostingMethod: Yup.string().required("Posting method is Required"),
    // pingEvaluationCode: Yup.string().required("Evaluation code is Required"),
    pingPostingUrl: Yup.string()
      .matches(
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
        "Invalid domain format. Example: domain.com or https://domain.com"
      )
      .required("Url is required"),
    pingResponseType: Yup.string().required("Response type is Required"),
    pingResponsePropertyToMatch: Yup.string().required(
      "Response property is Required"
    ),
    pingResponseSuccessStringToMatch: Yup.string().required(
      "Response success string is Required"
    ),
    pingResponseFailureStringToMatch: Yup.string().required(
      "Response failure string is Required"
    ),
    // pingPostingData: Yup.string().required("Ping posting is Required"),
    // pingPostingHeaderData: Yup.string().required(
    //   "Ping posting header is Required"
    // ),
  });

  useEffect(() => {
    if (advertiserOfferInfo?.advertiserOfferInfo?.id) {
      if (
        advertiserOfferInfo?.advertiserOfferInfo?.ping_posting_data &&
        configInfoObject.pingPostingData?.length === 0
      ) {
        let pingPostingDataArr = {};
        try {
          pingPostingDataArr = JSON.parse(
            advertiserOfferInfo?.advertiserOfferInfo?.ping_posting_data
          );
        } catch(e) {
          console.log(e);
        }
        if (Object.keys(pingPostingDataArr).length !== 0) {
          const arrayOfObjects = Object.entries(pingPostingDataArr).map(([key, value]) => ({
            key,
            value
          }));
          setConfigObject((prevConfigObject) => ({
            ...prevConfigObject,
            pingPostingData: arrayOfObjects
          }));
        }
      }
  
      if (
        advertiserOfferInfo?.advertiserOfferInfo?.ping_posting_header_data &&
        configInfoObject?.pingHeaderPostingData?.length === 0
      ) {
        let pingPostingHeaderDataArr = '';
        try {
          JSON.parse(
            advertiserOfferInfo?.advertiserOfferInfo?.ping_posting_header_data
          );
        } catch(e) {
          console.log(e);
        }
        if (Object.keys(pingPostingHeaderDataArr).length !== 0) {
          const arrayOfObjects = Object.entries(pingPostingHeaderDataArr).map(([key, value]) => ({
            key,
            value
          }));
          setConfigObject((prevConfigObject) => ({
            ...prevConfigObject,
            pingHeaderPostingData: arrayOfObjects
          }));
        }
      }
    }
  }, [advertiserOfferInfo?.advertiserOfferInfo?.id, configInfoObject]);

  return (
    <div className="position-relative">
      <div className="tab-pane active" id="tab-1" role="tabpanel">
        <Formik
          initialValues={(id && configInfoObject) || formik.initialValues}
          onSubmit={formik.handleSubmit}
          validationSchema={pingSettingSchema}
          enableReinitialize
        >
          {({ handleSubmit, errors, isValid, values, setFieldValue }) => {
            let shouldValid = true;
            shouldValid = !(configInfoObject.offerId && isValid);
            return (
              <form onSubmit={updateLeadSettingsInfo}>
                <input
                  type="hidden"
                  name="_token"
                  value="C5Oe8oLVgFK6wo5dqcWEYVrjvHXO1yrmMsiMvqnf"
                />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Configs</h5>
                    {!configInfoObject.offerId ? (
                      <div
                        className="alert alert-danger alert-outline alert-dismissible"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                        <div className="alert-icon">
                          <i className="far fa-fw fa-bell"></i>
                        </div>
                        <div className="alert-message">
                          <strong>Warning!</strong> Please fill out and save the
                          basic information first!
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="card-body">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">Ping Settings</h5>
                      </div>
                      <div className="card-body">
                        <div className="mb-3">
                          <label className="form-check form-check-inline">
                            <Field
                              type="checkbox"
                              id="httpPingAuthFlag"
                              name="httpPingAuthFlag"
                              className="form-check-input"
                            />
                            <span className="form-check-label ml-10">
                              HTTP Auth
                            </span>
                          </label>
                        </div>
                        {values?.httpPingAuthFlag && (
                          <div className="row">
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="pingHttpBasicAuthUsername"
                              >
                                Username
                              </label>
                              <Field
                                type="text"
                                id="pingHttpBasicAuthUsername"
                                name="pingHttpBasicAuthUsername"
                                className="form-control"
                                required
                              />
                              <ErrorMessage
                                name="pingHttpBasicAuthUsername"
                                component="div"
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="pingHttpBasicAuthPassword"
                              >
                                Password
                              </label>
                              <Field
                                type="text"
                                id="pingHttpBasicAuthPassword"
                                name="pingHttpBasicAuthPassword"
                                className="form-control"
                                required
                              />
                              <ErrorMessage
                                name="pingHttpBasicAuthPassword"
                                component="div"
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              />
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="mb-3 col-md-6">
                            <label
                              className="form-label"
                              htmlFor="pingPostingMethod"
                            >
                              Posting Method
                            </label>
                            <Field
                              as="select"
                              name="pingPostingMethod"
                              id="pingPostingMethod"
                              className="form-control"
                              required
                            >
                              <option value="http_get">HTTP GET</option>
                              <option value="http_post">HTTP POST</option>
                              <option value="http_json_post">
                                HTTP JSON POST
                              </option>
                            </Field>
                            <ErrorMessage
                              name="pingPostingMethod"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>

                          <div className="mb-3 col-md-6">
                            <label
                              className="form-label"
                              htmlFor="pingPostingUrl"
                            >
                              Posting URL
                            </label>
                            <Field
                              type="text"
                              id="pingPostingUrl"
                              name="pingPostingUrl"
                              className="form-control"
                              required
                            />
                            <ErrorMessage
                              name="pingPostingUrl"
                              component="div"
                              className="invalid-feedback "
                              style={{ display: "block" }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-3 col-md-6">
                            <label
                              className="form-label"
                              htmlFor="pingResponseType"
                            >
                              Response Type
                            </label>
                            <Field
                              as="select"
                              name="pingResponseType"
                              id="pingResponseType"
                              className="form-control"
                              required
                            >
                              <option value="text">Text</option>
                              <option value="json">Json</option>
                              <option value="html">HTML</option>
                            </Field>
                            <ErrorMessage
                              name="pingResponseType"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label
                              className="form-label"
                              htmlFor="pingResponsePropertyToMatch"
                            >
                              Ping Response Property
                            </label>
                            <Field
                              type="text"
                              id="pingResponsePropertyToMatch"
                              name="pingResponsePropertyToMatch"
                              className="form-control"
                              required
                            />
                            <ErrorMessage
                              name="pingResponsePropertyToMatch"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-3 col-md-6">
                            <label
                              className="form-label"
                              htmlFor="pingResponseSuccessStringToMatch"
                            >
                              Response Success String
                            </label>
                            <Field
                              type="text"
                              id="pingResponseSuccessStringToMatch"
                              name="pingResponseSuccessStringToMatch"
                              className="form-control"
                              required
                            />
                            <ErrorMessage
                              name="pingResponseSuccessStringToMatch"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label
                              className="form-label"
                              htmlFor="pingResponseFailureStringToMatch"
                            >
                              Response Failure String
                            </label>
                            <Field
                              type="text"
                              id="pingResponseFailureStringToMatch"
                              name="pingResponseFailureStringToMatch"
                              className="form-control"
                              required
                            />
                            <ErrorMessage
                              name="pingResponseFailureStringToMatch"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>
                        </div>

                        <div className="mb-3 col-md-12">
                          <label
                            className="form-label"
                            htmlFor="pingEvaluationCode"
                          >
                            Ping Evaluation Code
                          </label>
                          <Field
                            type="textarea"
                            component="textarea"
                            id="pingEvaluationCode"
                            name="pingEvaluationCode"
                            className="form-control"
                            // required
                            rows={4}
                          />
                          {/* <ErrorMessage
                              name="pingEvaluationCode"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            /> */}
                        </div>

                        <div className="d-flex">
                          <label className="w-25 form-label">
                            Ping Posting Data
                          </label>
                          <div className="w-75">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">Variable</th>
                                  <th scope="col">Place Holder</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name="pingPostingData">
                                  {({ insert, remove, push }) => (
                                    <>
                                      {values?.pingPostingData?.length > 0 &&
                                        values.pingPostingData.map(
                                          (friend, index) => {
                                            let condition =
                                              Object.keys(
                                                values?.pingPostingData[index]
                                              )?.length > 0;

                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <Field
                                                    type="text"
                                                    id={`pingPostingData[${index}].key`}
                                                    name={`pingPostingData[${index}].key`}
                                                    value={
                                                      condition
                                                        ? values
                                                            ?.pingPostingData[
                                                            index
                                                          ]?.key
                                                        : ""
                                                    }
                                                    className="form-control"
                                                  />
                                                </td>
                                                <td>
                                                  <Field
                                                    type="text"
                                                    id={`pingPostingData[${index}].value`}
                                                    name={`pingPostingData[${index}].value`}
                                                    value={
                                                      condition
                                                        ? values
                                                            ?.pingPostingData[
                                                            index
                                                          ]?.value
                                                        : ""
                                                    }
                                                    className="form-control"
                                                  />
                                                </td>
                                                <td>
                                                  {values?.pingPostingData
                                                    ?.length ==
                                                  index + 1 ? (
                                                    <i
                                                      onClick={() => push({})}
                                                      className={`fa-solid fa-circle-plus ${
                                                        Object.keys(
                                                          values
                                                            ?.pingPostingData[
                                                            index
                                                          ]
                                                        )?.length == 2
                                                          ? ""
                                                          : "pe-none"
                                                      }`}
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      onClick={() =>
                                                        remove(index)
                                                      }
                                                      className="fa-solid fa-circle-minus"
                                                    ></i>
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </>
                                  )}
                                </FieldArray>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <hr/>

                        <div className="d-flex">
                          <label className="w-25 form-label">
                            Ping Header Posting Data
                          </label>
                          <div className="w-75">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">Variable</th>
                                  <th scope="col">Place Holder</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name="pingHeaderPostingData">
                                  {({ insert, remove, push }) => (
                                    <>
                                      {values?.pingHeaderPostingData?.length >
                                        0 &&
                                        values.pingHeaderPostingData.map(
                                          (friend, index) => {
                                            let condition =
                                              Object.keys(
                                                values?.pingHeaderPostingData[
                                                  index
                                                ]
                                              )?.length > 0 ;
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <Field
                                                    type="text"
                                                    id={`pingHeaderPostingData[${index}].key`}
                                                    name={`pingHeaderPostingData[${index}].key`}
                                                    value={
                                                      condition
                                                        ? values
                                                            ?.pingHeaderPostingData[
                                                            index
                                                          ]?.key
                                                        : ""
                                                    }
                                                    className="form-control"
                                                  />
                                                </td>
                                                <td>
                                                  <Field
                                                    type="text"
                                                    id={`pingHeaderPostingData[${index}].value`}
                                                    name={`pingHeaderPostingData[${index}].value`}
                                                    value={
                                                      condition
                                                        ? values
                                                            ?.pingHeaderPostingData[
                                                            index
                                                          ]?.value
                                                        : ""
                                                    }
                                                    className="form-control"
                                                  />
                                                </td>
                                                <td>
                                                  {values?.pingHeaderPostingData
                                                    ?.length ==
                                                  index + 1 ? (
                                                    <i
                                                      onClick={() => push({})}
                                                      className={`fa-solid fa-circle-plus ${
                                                        Object.keys(
                                                          values
                                                            ?.pingHeaderPostingData[
                                                            index
                                                          ]
                                                        )?.length == 2
                                                          ? ""
                                                          : "pe-none"
                                                      }`}
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      onClick={() =>
                                                        remove(index)
                                                      }
                                                      className="fa-solid fa-circle-minus"
                                                    ></i>
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </>
                                  )}
                                </FieldArray>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <hr /> */}
                    {/* <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Lead Settings</h5>
                                </div>
                                <div className="card-body">


                                    <div className="mb-3">
                                        <label className="form-check form-check-inline">
                                            <Field
                                                type="checkbox"
                                                id="leadHttpBasicAuthFlag"
                                                name="leadHttpBasicAuthFlag"
                                                className="form-check-input"
                                            // checked={advertiserInfo ? advertiserInfo.lead_http_basic_auth_flag ? advertiserInfo.lead_http_basic_auth_flag : '' : 'checked'}
                                            />
                                            <span className="form-check-label ml-10">HTTP Auth</span>
                                        </label>
                                    </div>

                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="leadHttpBasicAuthUsername">Username</label>
                                            <Field
                                                type="text"
                                                id="leadHttpBasicAuthUsername"
                                                name="leadHttpBasicAuthUsername"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="leadHttpBasicAuthUsername" component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="leadHttpBasicAuthPassword">Password</label>
                                            <Field
                                                type="text"
                                                id="leadHttpBasicAuthPassword"
                                                name="leadHttpBasicAuthPassword"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="leadHttpBasicAuthPassword" component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="leadPostingMethod">Lead Posting Method</label>
                                            <Field as="select" name="leadPostingMethod" id="leadPostingMethod" className="form-control">
                                                <option value="http_get">HTTP GET</option>
                                                <option value="http_post">HTTP POST</option>
                                                <option value="http_json_post">HTTP JSON POST</option>
                                            </Field>
                                            <ErrorMessage name="leadPostingMethod" component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="leadEvaluationCode">Lead Evaluation Code</label>
                                            <Field
                                                type="text"
                                                id="leadEvaluationCode"
                                                name="leadEvaluationCode"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="leadEvaluationCode" component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label className="form-label" htmlFor="leadPostingUrl">Lead Posting URL<span className="text-danger">&nbsp;<strong>*</strong></span></label>
                                        <Field
                                            type="text"
                                            id="leadPostingUrl"
                                            name="leadPostingUrl"
                                            className="form-control mb-3"
                                        />
                                        <ErrorMessage name="leadPostingUrl" component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                    </div>


                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="leadResponseType">Lead Response Type</label>
                                            <Field as="select" name="leadResponseType" id="leadResponseType" className="form-control">
                                                <option value="text">Text</option>
                                                <option value="json">Json</option>
                                                <option value="html">HTML</option>
                                            </Field>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="leadResponsePropertyToMatch">Lead Response Property</label>
                                            <Field
                                                type="text"
                                                id="leadResponsePropertyToMatch"
                                                name="leadResponsePropertyToMatch"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="leadResponsePropertyToMatch" component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="leadResponseSuccessStringToMatch">Lead Response Success String<span className="text-danger">&nbsp;<strong>*</strong></span></label>
                                            <Field
                                                type="text"
                                                id="leadResponseSuccessStringToMatch"
                                                name="leadResponseSuccessStringToMatch"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="leadResponseSuccessStringToMatch" component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label className="form-label" htmlFor="leadResponseFailureStringToMatch">Lead Response Failure String<span className="text-danger">&nbsp;<strong>*</strong></span></label>
                                            <Field
                                                type="text"
                                                id="leadResponseFailureStringToMatch"
                                                name="leadResponseFailureStringToMatch"
                                                className="form-control"
                                            />
                                            <ErrorMessage name="leadResponseFailureStringToMatch" component="div" className="invalid-feedback" style={{ display: 'block' }} />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                  </div>
                  <hr></hr>
                  <div className="form-group row mb-0">
                    <div className="d-flex justify-content-end pe-4 pb-3">
                     
                      {leadSettingsFormLoading ? (
                        <LoadingButton name="Save" />
                      ) : (
                        <button
                        type="submit"
                        className="btn btn-primary"
                        // disabled={shouldValid}
                      >
                        Save
                      </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      {id && !advertiserOfferInfo.advertiserOfferInfo?.id && <Spinner />}
    </div>
  );
};

export default LeadSettingsTab;
