import React from 'react'
import {PublisherOfferStatReportsList} from "../reportsView";


const PublisherOfferStatReports = () => {
  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Day wise report</h1>
          <hr />
          <PublisherOfferStatReportsList />
        </div>
      </main>
    </div>
  )
}

export default PublisherOfferStatReports