import Constants from "../config/Constants";
import {
  addToSessionStorage,
  clearSessionStorage,
  sendGETRequest,
  sendPOSTRequest,
  validateUserToken,
  redirectToPage
} from "../utils/common_functions";

  var handlersObj = {};
  export const sendRequestGetAdvertisers = async(dataToPost) => {
    console.log('sendRequestGetAdvertisers');
    // console.log(dataToPost.advertisers);
    const url = Constants.APP_SERVER_OPTION_ADVERTISERS_LIST_URL;
    const headersToPost = {
      "Content-type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + dataToPost.accessToken,
    };
    let data = {
      ...dataToPost,
    };
    delete data["accessToken"];
    console.log(dataToPost);
    console.log(headersToPost);
    sendGETRequest(
      url,
      data,
      headersToPost,
      sendRequestAdvertisersSuccessHandler,
      sendRequestAdvertisersErrorHandler
    );
  };

function sendRequestAdvertisersSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
  //    handlersObj.tickerTypes(response);
  // }
  request.dataHander(response);
}
function sendRequestAdvertisersErrorHandler() {}


export const createAdvertiserBtnClick = (dataToPost) => {
  console.log('createAdvertiserBtnClick');
  console.log(dataToPost);
  // let store = AppStore.getAppStoreInstance();
  // const inputObj = {
  //   userToken: store.getState().login.accessToken,
  // };

  redirectToPage(dataToPost);

};
