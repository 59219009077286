import React, { useRef } from "react";

const ModalComponent = ({ children, id, saveHandler, modalHeading }) => {
  const modalRef = useRef(null);

  const handleSave = async () => {
    if (saveHandler) {
      await saveHandler(modalRef);
    }
  };

  return (
    <div
      className="modal fade"
      id={id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {modalHeading ? modalHeading : "Modal title"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body" style={{ height: "300px !important" }}>
            {children}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {saveHandler && (
              <button
                onClick={handleSave}
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-primary"
              >
                Save changes
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
