import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";

import { isUserTokenValid, readUserToken } from "../../utils/common_functions";
import AppStore from "../../config/AppStore";

import {
  sendRequestGetAdvertisersList,
  sendRequestGetTagsList,
} from "../../view_logics/AdvertiserOffers/AdvertiserOffer";
import { sendCreateAdvertiserOfferFormSubmitRequest } from "../../view_logics/AdvertiserOffers/CreateAdvertiserOffer";
import { sendUpdateAdvertiserOfferFormSubmitRequest } from "../../view_logics/AdvertiserOffers/EditAdvertiserOffer";
import MultiSelectField from "../../components/common/MultiSelectField";
import Spinner from "../../components/common/Spinner";
import LoadingButton from "../../components/common/LoadingButton";

const BasicInfoTab = ({
  advertiserOfferInfo,
  setAdvertiserOfferInfo,
  setActiveTab,
  advertisersList,
  tagsList,
}) => {
  // const [advertisersList, setAdvertisersList] = useState([]);
  const [basicInfoFormLoading, setBasicInfoFormLoading] = useState(false);

  const [offerCreateSuccessfully, setOfferCreateSuccessfully] = useState(false);
  const [offerUpdatedSuccessfully, setOfferUpdatedSuccessfully] =
    useState(false);
  const currentDate = new Date().toISOString().split("T")[0];

  // const [tagsList, setTagsList] = useState([]);
  const store = AppStore.getAppStoreInstance();
  const { id } = useParams();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    // url: 'editAdvertiser',
    history: navigate,
  };
  const selectRef = useRef(null);
  const [savedValues, setSavedValues] = useState(null);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [selectedCapUnit, setSelectedCapUnit] = useState("");

  const formik = useFormik({
    initialValues: {
      // Initial values for form fields
      // ...
      offerName: advertiserOfferInfo?.name ? advertiserOfferInfo?.name : "",
      type: "",
      offerId: "",
      offerURL: "",
      cap: "",
      capUnit: "un_capped",
      daily_cap_flag: false,
      daily_cap: "",
      advertiserId: "",
      advertiserPayout: "",
      delayInMinutes: "",
      offerDescription: "",
      isDisabledFlag: 0,
      autoDisableAtFlag: false,
      disableMethod: "manual",
      disableAt: "",
      enableSendLeadsFlag: 0,
      destinationURL: "",
      heading: "",
    },
    onSubmit: (values) => {
      // Handle form submission
      // ...
    },
  });

  useEffect(() => {
    if (selectRef.current) {
      new Choices(selectRef.current);
    }
  }, [tagsList]);

  var basicInfoObject = formik.initialValues;
  useEffect(() => {
    const myadvertiserOfferInfo = advertiserOfferInfo
      ? { ...advertiserOfferInfo }
      : {};

    if (myadvertiserOfferInfo?.name !== undefined) {
      const datetimeString = myadvertiserOfferInfo?.disable_at;
      const [datePart] = datetimeString.split(" ");
      basicInfoObject = {
        offerName: myadvertiserOfferInfo.name,
        // tagIds: myadvertiserOfferInfo.attached_tags,
        type: "cpc_offer", //myadvertiserOfferInfo.type,
        offerId: myadvertiserOfferInfo.id,
        offerURL: myadvertiserOfferInfo.offer_url,
        cap: myadvertiserOfferInfo.cap,
        capUnit: myadvertiserOfferInfo.cap_unit,
        daily_cap: myadvertiserOfferInfo.daily_cap,
        daily_cap_flag: myadvertiserOfferInfo.daily_cap_flag ? true : false,

        advertiserId: myadvertiserOfferInfo.advertiser_id,
        advertiserPayout: myadvertiserOfferInfo.advertiser_payout,
        delayInMinutes: myadvertiserOfferInfo.delay_in_mins,
        offerDescription: myadvertiserOfferInfo.description,
        isDisabledFlag:
          myadvertiserOfferInfo.is_disabled == "0"
            ? myadvertiserOfferInfo.is_disabled.toString()
            : myadvertiserOfferInfo.disable_method == "manual"
            ? "1"
            : myadvertiserOfferInfo.disable_method == "auto"
            ? "3"
            : "",
        disableMethod:
          myadvertiserOfferInfo.disable_method === "manual" ? true : false,
        disableAt: myadvertiserOfferInfo.disable_at ? datePart : "",
        enableSendLeadsFlag: myadvertiserOfferInfo.enable_send_leads_flag
          ? true
          : false,
        destinationURL: myadvertiserOfferInfo.offer_destination_url
          ? myadvertiserOfferInfo.offer_destination_url
          : "",
        heading: myadvertiserOfferInfo?.heading
          ? myadvertiserOfferInfo?.heading
          : "",
      };

      if (myadvertiserOfferInfo?.attached_tags?.length > 0) {
        let newArr = myadvertiserOfferInfo?.attached_tags.map((item, ind) => {
          return { id: item.tag_id, name: item.name };
        });
        if (newArr.length > 0) {
          basicInfoObject.tagIds = newArr;
        }
      }
      if (savedValues == null) {
        setSavedValues(basicInfoObject);
      }
    }
  }, [advertiserOfferInfo]);

  const createUpdateBasiInfo = (event) => {
    event.preventDefault();
    console.log("here", event.target.name, event.target.value);
    const formData = new FormData(event.target);
    const serializedData = {};
    var tags = [];

    serializedData["isDisabledFlag"] = "1";
    serializedData["enableSendLeadsFlag"] = "0";
    serializedData["disableMethod"] = "";
    serializedData["dailyCapFlag"] = 0;
    serializedData["dailyCap"] = 0;

    for (let [name, value] of formData.entries()) {
      if (name.includes("disableMethod")) {
        serializedData["disableMethod"] = "manual";
      } else if (name.includes("autoDisableAtFlag")) {
        serializedData["disableMethod"] = "auto";
      } else if (name === "isDisabledFlag") {
        serializedData["isDisabledFlag"] = Number(value);
      } else if (name === "enableSendLeadsFlag") {
        serializedData["enableSendLeadsFlag"] = "1";
      } else if (name === "daily_cap") {
        serializedData["dailyCap"] = Number(value);
      } else if (name === "daily_cap_flag" && value == "true") {
        serializedData["dailyCapFlag"] = 1;
      } else if (name.includes("tagIds")) {
        if (tags.includes(value)) {
          return;
        } else {
          tags.push(value);
        }
      } else {
        serializedData[name] = value;
      }
    }
    if (!serializedData?.cap) {
      serializedData["cap"] = advertiserOfferInfo?.cap
        ? advertiserOfferInfo?.cap
        : 0;
    }

    delete serializedData["_token"];
    delete serializedData["autoDisableAtFlag"];
    serializedData["tagIds"] = tags;

    setBasicInfoFormLoading(true);
    if (advertiserOfferInfo.id) {
      serializedData["offerId"] = advertiserOfferInfo.id;
      const inputObj = {
        accessToken: store.getState().login.accessToken,
        url: "",
        history: navigate,
        dataToPost: serializedData,
        errorStateChangeHandler: setOfferUpdatedSuccessfully,
        setActiveTab: setActiveTab,
        loadingHandler: setBasicInfoFormLoading,
        updateInfoById: setAdvertiserOfferInfo,
      };
      sendUpdateAdvertiserOfferFormSubmitRequest(inputObj);
    } else {
      const inputObj = {
        accessToken: store.getState().login.accessToken,
        url: "/offers/edit",
        history: navigate,
        dataToPost: serializedData,
        stateChangeHandler: setOfferCreateSuccessfully,
        setActiveTab: setActiveTab,
        loadingHandler: setBasicInfoFormLoading,
      };
      sendCreateAdvertiserOfferFormSubmitRequest(inputObj);
    }
  };

  const handleCapUnitChange = (event) => {
    if (event.hasOwnProperty("target")) {
      setSelectedCapUnit(event.target.value);
      // if (event.target.value == "un_capped") {
      //   document.getElementById("cap").disabled = "disabled";
      // } else {
      //   document.getElementById("cap").disabled = "";
      // }
    }
  };

  const AdvertiserOfferSchema = Yup.object().shape({
    // offerId: Yup.string()
    //   .min(2, "Too Short!")
    //   .max(50, "Too Long!")
    //   .required("Offer Id Required"),
    offerName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name Required"),
    delayInMinutes: Yup.number().min(0, "Please enter a positive number"),
    capUnit: Yup.string().required("Cap Unit Required"),
    advertiserId: Yup.string().required("Advertiser Id Required"),
    daily_cap: Yup.number().test("number", "Number required", function (value) {
      console.log("test value", this.parent.daily_cap_flag, value);
      if (this.parent.daily_cap_flag) {
        return Number(value) > 0 ? true : false;
      } else {
        return true;
      }
    }),

    offerURL: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Required"),

    heading: Yup.string().required("Heading is Required"),
    // tagIds: Yup.string().required("Required"),
  });

  return (
    <div className="position-relative">
      <div className="tab-pane active" id="tab-1" role="tabpanel">
        <Formik
          initialValues={savedValues || formik.initialValues}
          onSubmit={createUpdateBasiInfo}
          validationSchema={AdvertiserOfferSchema}
          enableReinitialize
        >
          {({ errors, touched, handleChange, isValid, values }) => {
            return (
              <Form onSubmit={createUpdateBasiInfo}>
                <input
                  type="hidden"
                  name="_token"
                  value="C5Oe8oLVgFK6wo5dqcWEYVrjvHXO1yrmMsiMvqnf"
                />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Basic Info</h5>
                  </div>

                  {offerCreateSuccessfully ? (
                    <div
                      className="alert alert-success alert-dismissible"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <div className="alert-message">
                        <strong>Success!</strong> Advertiser offer has been
                        created successfully!
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {offerUpdatedSuccessfully ? (
                    <div
                      className="alert alert-success alert-dismissible"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <div className="alert-message">
                        <strong>Success!</strong> Advertiser offer has been
                        updated successfully!
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="card-body">
                    {/* <div className="row">
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="offerId">
                      Offer ID
                    </label>
                    <Field
                      type="text"
                      id="offerId"
                      name="offerId"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="offerId"
                      component="div"
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    />
                  </div>
                </div> */}

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="offerName">
                          Name
                        </label>
                        <Field
                          type="text"
                          id="offerName"
                          name="offerName"
                          className="form-control"
                          required
                        />
                        <ErrorMessage
                          name="offerName"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="type">
                          Type
                        </label>
                        <Field
                          as="select"
                          name="type"
                          id="type"
                          className="form-control"
                        >
                          <option value="cpc_offer">CPC OFFER</option>
                          <option value="cpa_offer">CPA OFFER</option>
                        </Field>
                        <ErrorMessage
                          name="type"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="capUnit">
                          CAP Unit
                        </label>
                        <Field
                          as="select"
                          name="capUnit"
                          id="capUnit"
                          className="form-control"
                          onClick={handleCapUnitChange}
                        >
                          <option value="un_capped">Uncapped</option>
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                          <option value="fixed">Fixed</option>
                        </Field>
                        <ErrorMessage
                          name="capUnit"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="cap">
                          CAP
                        </label>
                        <Field
                          type="number"
                          id="cap"
                          name="cap"
                          className="form-control"
                          disabled={
                            values.capUnit?.includes("un_capped") ? true : false
                          }
                        />
                        <ErrorMessage
                          name="cap"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    {values.capUnit?.includes("daily") ||
                    values.capUnit?.includes("un_capped") ? (
                      ""
                    ) : (
                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <label className="form-label">Daily Throttle</label>
                          <div className="input-group">
                            <div className="input-group-text">
                              <Field
                                type="checkbox"
                                id="daily_cap_flag"
                                name="daily_cap_flag"
                                className="form-check-input"
                              />
                            </div>
                            <Field
                              type="text"
                              name="daily_cap"
                              id="daily_cap"
                              className="form-control"
                              disabled={!values?.daily_cap_flag}
                              required
                            ></Field>
                          </div>
                          <ErrorMessage
                            name="daily_cap"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="advertiserId">
                          Advertiser ID
                        </label>
                        <Field
                          as="select"
                          id="advertiserId"
                          name="advertiserId"
                          className="form-control"
                        >
                          <option value="">Please select advertiser</option>
                          {advertisersList
                            ? advertisersList.map((advertiser) => (
                                <option
                                  key={advertiser.id}
                                  value={advertiser.id}
                                >
                                  {advertiser.id + ": " + advertiser.name}
                                </option>
                              ))
                            : "a"}
                        </Field>
                        <ErrorMessage
                          name="advertiserId"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="advertiserPayout"
                        >
                          Advertiser Payout
                        </label>
                        <Field
                          type="number"
                          id="advertiserPayout"
                          name="advertiserPayout"
                          className="form-control"
                        />
                        <ErrorMessage name="advertiserPayout" component="div" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="offerURL">
                          Offer URL
                        </label>
                        <Field
                          type="text"
                          id="offerURL"
                          name="offerURL"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="offerURL"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="delayInMinutes">
                          Delay In Minutes
                        </label>
                        <Field
                          type="number"
                          id="delayInMinutes"
                          name="delayInMinutes"
                          className="form-control"
                          required
                        />
                        <ErrorMessage
                          name="delayInMinutes"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-12">
                        <label className="form-label" htmlFor="tagIds">
                          Tags
                        </label>

                        {/* <Field
                        name="tagIds"
                        // label="Select Options"
                        component={MultiSelectField}
                        options={tagsList}
                      /> */}

                        <MultiSelectField
                          errors={errors}
                          name="tagIds"
                          options={tagsList}
                          showLabel={"name"}
                          isMulti={true}
                          // ref={(ref) => (selectRefs.current[1] = ref)}
                          values={values}
                        />
                        {/* <MultiSelectField
                          errors={errors}
                          name="tagIds"
                          options={tagsList}
                          showLabel={"name"}
                          isMulti={true}
                          handleChange={handleChange}
                          selectedValue={selectedTagIds}
                        /> */}

                        <ErrorMessage
                          name="tagIds"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="destinationURL">
                          Destination Domain
                        </label>
                        <Field
                          type="text"
                          id="destinationURL"
                          name="destinationURL"
                          className="form-control"
                          disabled={values?.customDomainVerificationFlag}
                        />
                        <ErrorMessage
                          name="destinationURL"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="heading">
                          Heading
                        </label>
                        <Field
                          type="text"
                          id="heading"
                          name="heading"
                          className="form-control"
                          // disabled={values?.customDomainVerificationFlag}
                          required
                        />
                        <ErrorMessage
                          name="heading"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-3 col-md-12">
                        <label
                          className="form-label"
                          htmlFor="offerDescription"
                        >
                          Description
                        </label>
                        <Field
                          as="textarea"
                          id="offerDescription"
                          name="offerDescription"
                          rows={4}
                          cols={50}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="offerDescription"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="d-flex gap-5 py-2">
                      <div className="d-flex gap-3 justify-content-start align-items-center">
                        <label className="d-flex gap-3 align-items-center">
                          Enable
                          <Field
                            type="radio"
                            name="isDisabledFlag"
                            value="0"
                            // checked={true}
                          />
                        </label>
                      </div>

                      <div className="d-flex gap-3 py-2">
                        <label className="d-flex gap-3 align-items-center">
                          Manual Expire
                          <Field type="radio" name="isDisabledFlag" value="1" />
                        </label>
                      </div>
                      <div className="d-flex gap-3 py-2">
                        <label className="d-flex gap-3 align-items-center">
                          Expire On
                          <Field type="radio" name="isDisabledFlag" value="3" />
                        </label>
                      </div>
                      {values.isDisabledFlag == "3" && (
                        <div className="d-flex">
                          <Field
                            type="date"
                            id="disableAt"
                            name="disableAt"
                            className="form-control"
                            min={currentDate}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className="card-body">
                    <div className="row">
                      <div className="mb-3 d-flex gap-3">
                        <label className=" pt-sm-0">
                          Enabled
                        </label>
                        <div className="">
                          <label className="form-check m-0">
                            <Field
                              type="checkbox"
                              name="isDisabledFlag"
                              id="isDisabledFlag"
                              className="form-check-input"
                            ></Field>
                            <span className="form-check-label">Yes</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 d-flex gap-3">
                        <label className="pt-sm-0">
                          Manual Expire
                        </label>
                        <div className="col-sm-10">
                          <label className="form-check m-0">
                            <Field
                              type="checkbox"
                              name="disableMethod"
                              id="disableMethod"
                              className="form-check-input"
                            ></Field>
                            <span className="form-check-label">Yes</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 d-flex gap-3">
                        <label
                          className="pt-sm-0"
                          style={{ paddingTop: "5px !important" }}
                        >
                          Disable at
                        </label>
                        <div className="col-sm-2">
                          <div className="input-group">
                            <div className="input-group-text">
                              <Field
                                type="checkbox"
                                id="autoDisableAtFlag"
                                name="autoDisableAtFlag"
                                className="form-check-input"
                              />
                            </div>
                            <Field
                              type="date"
                              id="disableAt"
                              name="disableAt"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="disableAt"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <hr /> */}
                  {/* <div className="row">
                    <div className="mb-3 col-md-12">
                      <div className="card-header">
                        <h5 className="card-title">
                          Manually Send Past Coreg Leads
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6" style={{ display: "flex" }}>
                      <div className="mb-4 col-md-6">
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-6 text-sm-end pt-sm-0">
                            Enable Send Leads
                          </label>
                          <div className="col-sm-4">
                            <label
                              className="form-check m-0"
                              htmlFor="enableSendLeadsFlag"
                            >
                              <Field
                                type="checkbox"
                                name="enableSendLeadsFlag"
                                id="enableSendLeadsFlag"
                                className="form-check-input"
                              ></Field>
                              <span className="form-check-label">Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <hr />

                <div className="form-group row mb-0">
                  <div className="d-flex justify-content-end">
                    {basicInfoFormLoading ? (
                      <LoadingButton name="Save" />
                    ) : (
                      <button
                        disabled={!isValid}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      {id && !advertiserOfferInfo.id && <Spinner />}
    </div>
  );
};

export default BasicInfoTab;
