import React, { useState } from "react";
import AppStore from "../../config/AppStore";
import { useNavigate } from "react-router-dom";
import {OfferStatReportsList} from "../reportsView";

const OfferStatReports = () => {
  let store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "create",
    history: navigate,
  };
  return (
    <div className="">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>Offers Stats Reports</h1>
          <hr />
          <OfferStatReportsList />
        </div>
      </main>
    </div>
  );
};

export default OfferStatReports;
