import React, { useEffect, useState } from "react";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import AppStore from "../../config/AppStore";
import * as Yup from "yup";
import {
  sendCreatePublisherFormSubmitRequest,
  sendRequestToFetchPublisherDetails,
  sendRequestToUpdatePublisherDetail,
} from "../../view_logics/Publishers/CreatePublisher";
import Spinner from "../../components/common/Spinner";
import LoadingButton from "../../components/common/LoadingButton";
import { useSelector } from "react-redux";

const CreatePublisher = () => {
  const [publisherFormLoading, setPublisherFormLoading] = useState(false);

  const {userId, username, userType } = useSelector((state) => state?.login);

  let store = AppStore.getAppStoreInstance();
  let accessToken = store.getState().login.accessToken;
  const { id } = useParams();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      company_name: "",
      xoptin_commission: "",
      revenue_share: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      is_active: 1
    },
    onSubmit: async (values, { setSubmitting }, event) => {
      console.log("here");
      event.preventDefault();
    },
  });

  const [publisherById, setPublisherById] = useState(null);
  console.log(
    "🚀 ~ file: CreatePublisher.js:40 ~ CreatePublisher ~ publisherById:",
    publisherById
  );

  useEffect(() => {
    if (id != undefined) {
      const fetchData = async () => {
        let res = await sendRequestToFetchPublisherDetails({
          id: id,
          accessToken: accessToken,
        });
        if (res) {
          setPublisherById({ ...res });
        }
      };
      fetchData();
    }
  }, [id]);

  const saveAdvertiser = (event) => {
    event.preventDefault();
    console.log("saveAdvertiser");
    // console.log(event.target.name.value);
    const formData = new FormData(event.target);
    const serializedData = {};

    for (let [name, value] of formData.entries()) {
      if (name && value) {
        if(name == 'is_active'){
          serializedData[name] = Number(value)
        }else{
          serializedData[name] = value;
        }
      }
    }
    if (id != undefined) {
      serializedData["publisher_id"] = id;
    }
    console.log("🚀 ~ saveAdvertiser ~ serializedData:", serializedData)
    const jsonData = JSON.stringify(serializedData);
    console.log("jsondata", jsonData);
    const inputObj = {
      accessToken: accessToken,
      url: "/publishers",
      history: navigate,
      dataToPost: serializedData,
      loadingHandler: setPublisherFormLoading,
    };
    setPublisherFormLoading(true);
    if (id != undefined) {
      sendRequestToUpdatePublisherDetail(inputObj);
    } else {
      sendCreatePublisherFormSubmitRequest(inputObj);
    }
  };

  const publisherValidation = Yup.object().shape({
    first_name: Yup.string().required("First name is Required"),
    last_name: Yup.string().required("Last name is Required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email format.Example: example@mail.com"
      )
      .required("This field is required"),
    password: Yup.string().required("Password is required"),
    company_name: Yup.string().required("Company name is Required"),
    xoptin_commission: Yup.number().required("Xoptin commission is required"),
    revenue_share: Yup.string().required("Revenue share is Required"),

    address: Yup.string().required("Address is Required"),
    city: Yup.string().required("City Id Required"),
    state: Yup.string().required("State Id Required"),
    zip_code: Yup.string().required("Zip code Id Required"),
    country: Yup.string().required("Country Id Required"),
  });

  return (
    <div className="position-relative">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>{id ? "Edit Publisher" : "Create New Publisher"}</h1>
          <Formik
            initialValues={publisherById || formik.initialValues}
            validationSchema={publisherValidation}
            enableReinitialize
          >
            {({ handleSubmit, errors, isValid, values }) => {
              console.log("🚀 ~ CreatePublisher ~ values:", values);

              return (
                <form onSubmit={saveAdvertiser}>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Basic Info</h5>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Name">
                            First Name
                          </label>
                          <Field
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Email">
                            Last Name
                          </label>
                          <Field
                            type="text"
                            id="last_name"
                            name="last_name"
                            className={
                              errors.name ? "form-control" : "form-control"
                            }
                            required
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Email">
                            Email
                          </label>
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            className={
                              errors.name ? "form-control" : "form-control"
                            }
                            required
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Name">
                            Password
                          </label>
                          <Field
                            type="password"
                            id="password"
                            name="password"
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Company">
                            Company
                          </label>
                          <Field
                            type="text"
                            id="company_name"
                            name="company_name"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="company_name"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <div className="d-flex justify-content-between align-items-center">
                            <label
                              className="form-label"
                              htmlFor="Xoptin Commission"
                            >
                              Xoptin Commission
                            </label>

                            <i
                              className="fa-solid fa-circle-info"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={`This commission is in percentage`}
                            ></i>
                          </div>
                          <Field
                            type="number"
                            id="xoptin_commission"
                            name="xoptin_commission"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="xoptin_commission"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <div className="d-flex justify-content-between align-items-center">
                            <label
                              className="form-label"
                              htmlFor="Revenue Share"
                            >
                              Revenue Share
                            </label>

                            <i
                              className="fa-solid fa-circle-info"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={`This commission is in percentage`}
                            ></i>
                          </div>
                          <Field
                            type="number"
                            id="revenue_share"
                            name="revenue_share"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="revenue_share"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>

                        {userType == "admin" &&
                        <div className="mb-3 col-md-6">
                          <label className=""></label>
                          <div className="d-flex gap-4 align-items-center pt-3">
                          <label
                              className=""
                              htmlFor="enable_publisher"
                            >
                              Enable Publisher:
                            </label>
                            <div className="d-flex gap-3">
                              <label className="d-flex gap-2 align-items-center">
                                Yes
                                <Field
                                  type="radio"
                                  name="is_active"
                                  value="1"
                                  checked={values?.is_active == 1}
                                />
                              </label>
                            </div>
                            <div className="d-flex gap-3">
                              <label className="d-flex gap-2 align-items-center">
                                No
                                <Field
                                  type="radio"
                                  name="is_active"
                                  value="0"
                                  checked={values?.is_active == 0}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Address Details</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-md-6 mb-3">
                          <label
                            className="form-label"
                            htmlFor="BillingAddress"
                          >
                            Billing Address
                          </label>
                          <Field
                            type="text"
                            id="address"
                            name="address"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        <div className="form-group col-md-6 mb-3">
                          <label className="form-label" htmlFor="Country">
                            Country
                          </label>
                          <Field
                            type="text"
                            id="country"
                            name="country"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="city">
                            City
                          </label>
                          <Field
                            type="text"
                            id="city"
                            name="city"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="state">
                            State
                          </label>
                          <Field
                            type="text"
                            id="state"
                            name="state"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        <div className="mb-3 col-md-2">
                          <label className="form-label" htmlFor="zip_code">
                            Zip
                          </label>
                          <Field
                            type="text"
                            id="zip_code"
                            name="zip_code"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="zip_code"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="form-group row mb-0">
                    <div className="d-flex justify-content-end">
                      {/* <button
                        disabled={isValid ? false : true}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Save
                      </button> */}

                      {publisherFormLoading ? (
                        <LoadingButton name="Save" />
                      ) : (
                        <button
                          disabled={isValid ? false : true}
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </main>

      {id && !publisherById && <Spinner />}
    </div>
  );
};

export default CreatePublisher;
