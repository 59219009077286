import Constants from "../../config/Constants";
import {
  addToSessionStorage,
  clearSessionStorage,
  sendGETRequest,
  sendPOSTRequest,
  validateUserToken,
  redirectToPage
} from "../../utils/common_functions";

  var handlersObj = {};
  export const sendRequestGetTags = async(dataToPost) => {
    console.log('sendRequestGetTags');
    // console.log(dataToPost.advertisers);
    const url = Constants.APP_SERVER_OPTION_TAGS_LIST_URL;
    const headersToPost = {
      "Content-type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + dataToPost.accessToken,
    };
    let data = {
      ...dataToPost,
    };
    delete data["accessToken"];
    console.log(dataToPost);
    console.log(headersToPost);
    sendGETRequest(
      url,
      data,
      headersToPost,
      sendRequestTagsSuccessHandler,
      sendRequestTagsErrorHandler
    );
  };

function sendRequestTagsSuccessHandler(response, request) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("TagId")) {
  //    handlersObj.tickerTypes(response);
  // }
  request.dataHander(response);
}
function sendRequestTagsErrorHandler(error, request) {
  request.history(request.redirectUrl, { state: { from: "Tag", type: 'error', message: error.message } });
}


export const createTagBtnClick = (dataToPost) => {
  console.log('createTagBtnClick');
  console.log(dataToPost);
  // let store = AppStore.getAppStoreInstance();
  // const inputObj = {
  //   userToken: store.getState().login.accessToken,
  // };

  redirectToPage(dataToPost);

};
