
import React, { useState } from "react";


// import DashboardContent from './DashboardContent';

// import Advertisers from "../Advertiser/Advertiser";
// import CreateAdvertiser from "../Advertiser/CreateAdvertiser";


function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

function MyDashboard() {
  const [activeContent, setActiveContent] = useState('default');

  const handleMenuItemClick = (menuItem) => {
    setActiveContent(menuItem);
  };
  
  return (
      <div className="wrapper">
        <div className="main">
          Dashboard Content          
        </div>
      </div>
  );
};
export default MyDashboard;
