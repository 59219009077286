import React from "react";
import { useNavigate } from 'react-router-dom';
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";

import { isUserTokenValid, readUserToken } from '../../utils/common_functions';
import AppStore from '../../config/AppStore';

import  { sendCreateAdUnitFormSubmitRequest }  from "../../view_logics/AddUnits";

const Step4 = (props) => {

    const store = AppStore.getAppStoreInstance();
    const navigate = useNavigate(); // we can pass navigation to clickhandler
    const formik = useFormik({
        initialValues: {
            // Initial values for form fields
            // ...
        },
        onSubmit: (event) => {
            event.preventDefault();
            // Handle form submission
            // ...
            // sendRequestToCreateAdUnit(event, props);
        },
    });

    const sendRequestToCreateAdUnit = (props) => {
        const inputObj = {
            accessToken: store.getState().login.accessToken,
            url: '/adunits',
            dataToPost: props.formData,
            history: navigate
        };
        sendCreateAdUnitFormSubmitRequest(inputObj);
    };

    return (
        <div className="">
            <main className="content">
                <div className="container-fluid p-0">
                    <h1>Create New Add Unit</h1>
                    <div className="tab-content">

                        <div className="tab-pane active" id="tab-1" role="tabpanel">
                            <Formik
                                initialValues={formik.initialValues}
                                onSubmit={formik.handleSubmit}
                            >
                                <form name="form3Data" onSubmit={formik.handleSubmit}>
                                    <input type="hidden" name="_token" value="C5Oe8oLVgFK6wo5dqcWEYVrjvHXO1yrmMsiMvqnf" />
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title">STEP 4 - Code Format</h5>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="ad_unit_name">Ad Unit Name</label>
                                                    <Field
                                                        type="text"
                                                        id="ad_unit_name"
                                                        name="ad_unit_name"
                                                        className="form-control"
                                                        onChange={props.setFormData}
                                                        value={props.formData.ad_unit_name}
                                                    />
                                                    <ErrorMessage name="ad_unit_name" component="div" />
                                                </div>
                                            </div>

                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className=" mb-0">Embed Script</h5>
                                                </div>
                                                <div className="card-body">
                                                    <textarea className="form-control" rows="2" placeholder="" readOnly></textarea>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <button
                                        className="btn btn-warning btn-lg"
                                        onClick={() => props.navigation.previous()}>
                                        Back
                                    </button>
                                    <button onClick={() => sendRequestToCreateAdUnit(props)} className="btn btn-primary btn-lg ml-5" >Finish</button>
                                </form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Step4;