import React from "react";

const LoadingButton = ({name='', size=''}) => {
  return (
    <button
      className={`${size} btn btn-primary d-flex gap-2 align-items-center`}
      type="button"
      disabled
    >
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      {name ? name : 'Loading...'}
    </button>
  );
};

export default LoadingButton;
