import Constants from "../../config/Constants";
import {
  addToSessionStorage,
  clearSessionStorage,
  sendGETRequest,
  sendPOSTRequest,
  validateUserToken,
  redirectToPage,
  sendPUTRequest,
  sendGETRequestASync,
} from "../../utils/common_functions";

export const sendCreateAdvertiserFormSubmitRequest = (inputObj) => {
  console.log("Sending Create ADvertiser Request!");
  console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_CREATE_ADVERTISER_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  let data = {
    ...dataToPost,
  };
  delete data["userToken"];
  sendPOSTRequest(
    url,
    data,
    headersToPost,
    sendCreateAdvertiserFormSubmitResponseSuccessHandler,
    sendCreateAdvertiserFormSubmitResponseErrorHandler
  );
  inputObj.history(inputObj.url);
};

function sendCreateAdvertiserFormSubmitResponseSuccessHandler(
  response,
  request
) {
  // if (handlersObj != undefined && handlersObj.hasOwnProperty("AdvertiserId")) {
  //    handlersObj.tickerTypes(response);
  // }
  console.log("sendCreateAdvertiserFormSubmitResponseSuccessHandler");
  console.log(response);
  request.history(request.url);
  // request.dataHander(response);
}
function sendRequestAdvertisersErrorHandler() {}

export const sendCreateAdvertiserFormSubmitResponseErrorHandler = (
  error,
  request
) => {
  console.log("sendCreateAdvertiserFormSubmitResponseErrorHandler");
  console.log(error);

  // let inputObj = request;
  // inputObj.url = 'advertiser/create';
  // redirectToPage(request);
};

export const sendCreatePublisherFormSubmitRequest = (inputObj) => {
  const url = Constants.APP_SERVER_OPTION_PUBLISHERS_LIST_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  dataToPost.redirectUrl = inputObj.url;
  dataToPost.history = inputObj.history;
  dataToPost.loadingHandler = inputObj?.loadingHandler;
  let data = {
    ...dataToPost,
  };
  delete data["userToken"];
  sendPOSTRequest(
    url,
    data,
    headersToPost,
    sendCreatePublisherFormSubmitResponseSuccessHandler,
    sendCreatePublisherFormSubmitResponseErrorHandler
  );
};

function sendCreatePublisherFormSubmitResponseSuccessHandler(
  response,
  request
) {
  console.log(
    "🚀 ~ file: CreatePublisher.js:85 ~ sendCreatePublisherFormSubmitResponseSuccessHandler ~ response:",
    response,
    request
  );
  request?.loadingHandler && request?.loadingHandler(false);
  request.history(request.redirectUrl, {
    state: { from: "Publisher", type: "create", message: response.message },
  });
}

function sendCreatePublisherFormSubmitResponseErrorHandler(error, request) {
  request?.loadingHandler && request?.loadingHandler(false);
  request.history("", {
    state: { from: "Publisher", type: "error", message: error.message },
  });
}

export const sendRequestToFetchPublisherDetails = async (inputObj) => {
  const url = Constants.APP_SERVER_OPTION_PUBLISHERS_LIST_URL + inputObj.id;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let data = {
    ...inputObj,
  };
  delete data["accessToken"];
  const result = await sendGETRequestASync(url, data, headersToPost);
  return result;
};

export const sendRequestToUpdatePublisherDetail = (inputObj) => {
  console.log("Sending Create ADvertiser Request!");
  console.log(inputObj);

  const url = Constants.APP_SERVER_OPTION_PUBLISHERS_LIST_URL;
  const headersToPost = {
    "Content-type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + inputObj.accessToken,
  };
  let dataToPost = inputObj.dataToPost;
  dataToPost.redirectUrl = inputObj.url;
  dataToPost.history = inputObj.history;
  dataToPost.loadingHandler = inputObj?.loadingHandler;
  let data = {
    ...dataToPost,
  };
  sendPUTRequest(
    url,
    data,
    headersToPost,
    successPublisherRequestHandler,
    errorPublisherRequestHandler
  );
};

function successPublisherRequestHandler(response, request) {
  console.log(
    "🚀 ~ file: Website.js:133 ~ successHandler ~ response:",
    response,
    request
  );
  request?.loadingHandler && request?.loadingHandler(false);

  request.history(request.redirectUrl, {
    state: { from: "Publisher", type: "edit", message: response.message },
  });
}

function errorPublisherRequestHandler(error, request) {
  console.log(
    "🚀 ~ file: Website.js:133 ~ successHandler ~ response:",
    error,
    request
  );
  request?.loadingHandler && request?.loadingHandler(false);

  request.history("", {
    state: { from: "Publisher", type: "error", message: error.message },
  });
}
