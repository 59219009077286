import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import Constants from "../../config/Constants";

const TinyMceEditor = ({ props }) => {
  const editorRef = useRef(null);

  const handleHtmlChange = (newHtmlCode) => {
    props.setFormData((formData) => ({
      ...formData,
      custom_html: newHtmlCode,
    }));
    const editor = editorRef.current;
    if (editor) {
      editor.setContent(newHtmlCode);
    }
  };

  const handleCustomBtnAdd = () => {
    // Get the current editor instance
    const editor = editorRef.current;

    // Insert the specified HTML code at the cursor position
    if (editor) {
      const htmlToInsert = `<button offer_id="[ADD_NUMBER]" clicktrk="[OFFER_URL]" onclick="handleAddClick(this)">YES</button>`;
      editor.insertContent(htmlToInsert);
    }
  };

  const handleCustomBtnNo = () => {
    const editor = editorRef.current;

    if (editor) {
      const htmlToInsert = `<input type="button" name="answer" value="No">`;
      editor.insertContent(htmlToInsert);
    }
  };

  const handleCustomAddImage = () => {
    const editor = editorRef.current;

    if (editor) {
      const htmlToInsert = `[IMAGE_URL]`;
      editor.insertContent(htmlToInsert);
    }
  };

  const handleOfferDetailBtn = () => {
    const editor = editorRef.current;

    if (editor) {
      const htmlToInsert = `[DETAIL_HTML]`;
      editor.insertContent(htmlToInsert);
    }
  };

  const handleOfferUrlBtn = () => {
    const editor = editorRef.current;

    if (editor) {
      const htmlToInsert = `[OFFER_URL]`;
      editor.insertContent(htmlToInsert);
    }
  };

  const handleOfferIdBtn = () => {
    const editor = editorRef.current;

    if (editor) {
      const htmlToInsert = `[ADD_NUMBER]`;
      editor.insertContent(htmlToInsert);
    }
  };

  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      const head = editor.getDoc().getElementsByTagName("head")[0];
      const style = editor.getDoc().createElement("style");
      style.appendChild(
        editor.getDoc().createTextNode(props?.formData?.custom_css)
      );
      head.appendChild(style);
    }
  }, [props?.formData?.custom_css]);

  useEffect(() => {
    // Execute JavaScript when the component mounts
    const scriptElement = document.createElement("script");
    scriptElement.innerHTML = props?.formData?.custom_js;
    if(typeof document !== 'undefined'){
      document.body.appendChild(scriptElement);
    }

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(scriptElement);
    };
  }, [props?.formData?.custom_js]);
  return (
    <div>
      <Editor
        apiKey={Constants.TINYMCE_EDITOR_APIKEY}
        id="editor" // Provide an ID for the editor
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        value={props?.formData?.custom_html}
        init={{
          selector: "textarea",
          // height: 500,
          plugins:
            "code save anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
          toolbar: `undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | customBtnAdd customBtnNo customAdImage offerDetails offerUrl offerId | checklist numlist bullist indent outdent | emoticons charmap | removeformat`,
          // tinycomments_mode: "embedded",
          // tinycomments_author: "Author name",
          setup: function (editor) {
            editor.ui.registry.addButton("customBtnAdd", {
              text: "Custom Btn Add",
              onAction: handleCustomBtnAdd,
            });
            editor.ui.registry.addButton("customBtnNo", {
              text: "Custom Btn No",
              onAction: handleCustomBtnNo,
            });
            editor.ui.registry.addButton("customAdImage", {
              text: "Custom Ad Image",
              onAction: handleCustomAddImage,
            });

            editor.ui.registry.addButton("customBtnNo", {
              text: "Custom Btn No",
              onAction: handleCustomBtnNo,
            });
            editor.ui.registry.addButton("offerDetails", {
              text: "Offer Details",
              onAction: handleOfferDetailBtn,
            });
            editor.ui.registry.addButton("offerUrl", {
              text: "Offer URL",
              onAction: handleOfferUrlBtn,
            });
            editor.ui.registry.addButton("offerId", {
              text: "Offer ID",
              onAction: handleOfferIdBtn,
            });
          },

          extended_valid_elements:
            "a[href|target|onclick|class|offer_id|clicktrk|style],span[class|onclick|style],div[class|onclick|question_id|style],p[class|onclick|style],button[class|onclick|clicktrk|offer_id|style],input[type|name|value|class|answer_id|style]", // Add any other elements or attributes you need
          content_style: props?.formData?.custom_css,
        }}
        onEditorChange={handleHtmlChange}
      />

      <div className="d-flex flex-column gap-1">
        <label style={{ fontWeight: "bold" }}>Css</label>
        <textarea
          type="textarea"
          component="textarea"
          id="custom_css"
          name="custom_css"
          className="form-control"
          // required
          rows="5"
          value={props?.formData?.custom_css}
          onChange={(e) => {
            props.setFormData((formData) => ({
              ...formData,
              custom_css: e.target.value,
            }));
          }}
        />
      </div>

      <div className="d-flex flex-column gap-1">
        <label style={{ fontWeight: "bold" }}>Js</label>
        <textarea
          type="textarea"
          component="textarea"
          id="custom_js"
          name="custom_js"
          className="form-control"
          // required
          rows="5"
          value={props?.formData?.custom_js}
          onChange={(e) => {
            props.setFormData((formData) => ({
              ...formData,
              custom_js: e.target.value,
            }));
          }}
        />
      </div>
    </div>
  );
};

export default TinyMceEditor;
